import React from "react";

import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Navigate } from 'react-router-dom'

import Page from "structure/Page";

import Loading from "components/loading/Loading";
import LoadingError from "components/loading/LoadingError";
import ClubCard from "components/club/ClubCard";

import { GET_CLUBS_OWNED } from "graphql/query/club";
import { getRoute } from "utils/route";

const PageAdminHome = () => {
  const { t } = useTranslation(["admin"]);
  const { loading, error, data } = useQuery(GET_CLUBS_OWNED, {
    fetchPolicy: "no-cache",
  });

  const [params] = useSearchParams();
  const persist = params.get('persist')

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <LoadingError error={error} />;
  }

  if (!!persist !== true && data.ClubsOwned && data.ClubsOwned.length == 1) {
    const clubLink = getRoute("admin_club_home", {club_id: data.ClubsOwned[0].id})
    return (<Navigate to={clubLink} />)
  }

  return (
    <>
      <Page title={t("admin:home.title")}>
        <Link
          to={getRoute("admin_club_create")}
          className={"button is-primary mb-3"}
        >
          {t("admin:home.create")}
        </Link>
        <div className="is-clearfix"></div>
        {data.ClubsOwned.map((club, index) => (
          <ClubCard key={index} club={club} adminMode={true} />
        ))}
        <div className="is-clearfix"></div>
      </Page>
    </>
  );
};

export default PageAdminHome;
