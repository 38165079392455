import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NewMemberModalCreate = ({
  userFirstname,
  setUserFirstname,
  userLastname,
  setUserLastname,
  userEmail,
  setUserEmail,
  memberCreate,
  memberCreateError,
  memberCreateLoading
}) => {

  const { t } = useTranslation(["admin"]);

  return (
    <>
      <div className="notification is-info has-text-centered">
        {t("admin:member.modal.create_user.message")}
      </div>

      {memberCreateError && <p className="has-text-danger">{memberCreateError}</p>}

      <div className="field">
        <div className="control">
          <input
            autoFocus={true}
            value={userFirstname}
            className="input"
            type="text"
            placeholder={t("admin:member.modal.create_user.firstname")}
            onChange={(e) => setUserFirstname(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <input
            value={userLastname}
            className="input"
            type="text"
            placeholder={t("admin:member.modal.create_user.lastname")}
            onChange={(e) => setUserLastname(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <input
            value={userEmail}
            className="input"
            type="email"
            placeholder={t("admin:member.modal.create_user.email")}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <button
          type="button"
          className={"button is-primary is-wide" + (memberCreateLoading ? ' is-loading' : '')}
          onClick={() => memberCreate()}
        >
          {t("admin:member.modal.create_user.button")}
        </button>
      </div>
    </>
  );
};

NewMemberModalCreate.propTypes = {
  userFirstname: PropTypes.string,
  setUserFirstname: PropTypes.func,
  userLastname: PropTypes.string,
  setUserLastname: PropTypes.func,
  userEmail: PropTypes.string,
  setUserEmail: PropTypes.func,
  memberCreate: PropTypes.func,
  memberCreateError: PropTypes.string,
  memberCreateLoading: PropTypes.bool
};

export default NewMemberModalCreate;
