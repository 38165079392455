import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { MessagePopinContext } from "context/MessagePopinContext";

import moment from "moment";
import Table, { DEFAULT_PAGE_SIZE } from "components/Table";
import { processData } from "utils/table";

import { GET_ABSENCES } from "graphql/query/absences";
import { REGISTRATION_PRESENT_FLAG } from "graphql/mutation/absences";

import Switch from "components/form/Switch";

const PageAdminAbsencesTable = ({ clubId }) => {
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const { t } = useTranslation(["translation", "admin", "errors"]);

    const [showFlagged, setShowFlagged] = useState(false);

    const tableRef = useRef();
    const messagePopinContext = useContext(MessagePopinContext);

    const setMessagePopin = (type, path) => {
        messagePopinContext.dispatch({ type: type, message: path });
    };

    const [getAbsences] = useLazyQuery(GET_ABSENCES, {
        fetchPolicy: "network-only",
    });

    const handleRegistrationPresentFlag = (
        registrationId,
        presentFlag,
        tableRef
    ) => {
        setRegistrationPresentFlag({
            variables: {
                registrationId,
                presentFlag,
            },
        }).then(() => {
            tableRef.current.onQueryChange();
        });
    };

    const [setRegistrationPresentFlag] = useMutation(
        REGISTRATION_PRESENT_FLAG,
        {
            onError(response) {
                if (response.graphQLErrors) {
                    response.graphQLErrors.map((item) => {
                        setMessagePopin(
                            "danger",
                            t(`errors:messages.${item.message}`)
                        );
                    });
                }
            },
        }
    );

    const onChangeSwitchShowFlagged = () => {
        setShowFlagged((v) => !v);
        if (tableRef?.current) {
            tableRef.current.onQueryChange();
        }
    };

    return (
        <>
            <div className="mb-2">
                <div className="is-pulled-right">
                    <Switch
                        onChange={onChangeSwitchShowFlagged}
                        name="show_flagged"
                        size="medium"
                        checked={showFlagged}
                        label={t("admin:absences.show_flagged")}
                        labelPosition="left"
                    />
                </div>
                <div className="is-clearfix"></div>
            </div>


            <Table
                tableRef={tableRef}
                handleChangeRowsPerPage={(rowsPerPage) => {
                    setPageSize(rowsPerPage);
                }}
                options={{
                    search: true,
                    toolbar: true,
                    sorting: false,
                    debounceInterval: 1000,
                    pageSize: pageSize,
                }}
                columns={[
                    {
                        title: t("admin:absences.table.columns.firstname"),
                        field: "user_firstname",
                    },
                    {
                        title: t("admin:absences.table.columns.lastname"),
                        field: "user_lastname",
                    },
                    {
                        title: t("admin:absences.table.columns.session_type"),
                        field: "session_type",
                    },
                    {
                        title: t("admin:absences.table.columns.session_date"),
                        render: (rowData) => (
                            <>
                                <span>
                                    {moment(
                                        rowData.session_start_datetime
                                    ).format("YYYY-MM-DD")}
                                </span>
                                <span>
                                    &nbsp;-&nbsp;
                                    {t(
                                        "translation:day_of_week_short:" +
                                            moment(
                                                rowData.session_start_datetime
                                            ).day()
                                    )}
                                </span>
                            </>
                        ),
                    },
                    {
                        title: t("admin:absences.table.columns.session_time"),
                        render: (rowData) => (
                            <>
                                {moment(rowData.session_start_datetime).format(
                                    "HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(rowData.session_end_datetime).format(
                                    "HH:mm"
                                )}
                            </>
                        ),
                    },
                    {
                        title: t("admin:absences.table.columns.action"),
                        headerStyle: {
                            textAlign: "center",
                        },
                        cellStyle: {
                            textAlign: "center",
                        },
                        render: (rowData) => (
                            <>
                                {rowData.present_flag === true && (
                                    <button
                                        type="button"
                                        className="button is-small is-light is-info"
                                        onClick={() =>
                                            handleRegistrationPresentFlag(
                                                rowData.registration_id,
                                                false,
                                                tableRef
                                            )
                                        }
                                    >
                                        {t(
                                            "admin:absences:table.columns.action_present_flag_true"
                                        )}
                                    </button>
                                )}
                                {(rowData.present_flag === null ||
                                    rowData.present_flag === false) && (
                                    <button
                                        type="button"
                                        className="button is-small"
                                        onClick={() =>
                                            handleRegistrationPresentFlag(
                                                rowData.registration_id,
                                                true,
                                                tableRef
                                            )
                                        }
                                    >
                                        {t(
                                            "admin:absences:table.columns.action_present_flag_false"
                                        )}
                                    </button>
                                )}
                            </>
                        ),
                    },
                ]}
                data={(query) =>
                    new Promise((resolve) => {
                        getAbsences({
                            variables: {
                                clubId,
                                offset: query.page,
                                limit: query.pageSize,
                                query: query.search,
                                showFlagged,
                            },
                        }).then((response) => {
                            resolve({
                                data: processData(
                                    response.data.Absences.absences
                                ),
                                page: query.page,
                                totalCount: response.data.Absences.count,
                            });
                        });
                    })
                }
            />
        </>
    );
};

PageAdminAbsencesTable.propTypes = {
    clubId: PropTypes.number,
};

export default PageAdminAbsencesTable;
