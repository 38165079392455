import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { MessagePopinContext } from 'context/MessagePopinContext';
import { getBtnClass, getBtnLabelAction, getBtnLabelInfo, computeAction } from './utils'
import { REGISTER_SESSION, REGISTER_CANCEL_SESSION, REGISTER_QUEUE_SESSION } from 'graphql/mutation/register'
import {
    REGISTRATION_STATUS_NONE,
    ACTION_REGISTER,
    ACTION_PENDING,
    ACTION_CANCEL,
    REGISTRATION_STATUS_PENDING
} from './const'
import { isJson } from 'utils/str';

const SessionRegisterButton = ({
    uid,
    registrationStatus,
    counterRegistrations,
    maxRegistrations,
    callback,
    className
}) => {
    const { t } = useTranslation()

    const messagePopinContext = useContext(MessagePopinContext)

    const [isLoading, setIsLoading] = useState(false)
    const [isComplete, setIsComplete] = useState(counterRegistrations >= maxRegistrations)
    const [btnClass, setBtnClass] = useState('')
    const [btnLabelInfo, setBtnLabelInfo] = useState('')
    const [btnLabelAction, setBtnLabelAction] = useState('')
    const [currentRegistrationStatus, setCurrentRegistrationStatus] = useState(registrationStatus ?? REGISTRATION_STATUS_NONE)

    // update button class & labels is status or iscomplete changes
    useEffect(() => {
        const infos = {
            "registrationStatus": currentRegistrationStatus,
            isComplete,
            counterRegistrations,
            maxRegistrations
        }
        setBtnClass(getBtnClass(infos))
        setBtnLabelInfo(getBtnLabelInfo(t, infos))
        setBtnLabelAction(getBtnLabelAction(t, infos))
    }, [isComplete, currentRegistrationStatus])

    const setMessagePopin = (type, path) => {
        messagePopinContext.dispatch({ 'type': type, 'message': t(path) })
    }

    const onCompleted = (sessionRegistration, messageTranslationPath) => {
        setIsLoading(false);

        setCurrentRegistrationStatus(sessionRegistration?.Registration?.status ?? REGISTRATION_STATUS_NONE)
        setIsComplete(sessionRegistration.counter_registrations >= sessionRegistration.max_registrations)
        setMessagePopin('info', messageTranslationPath)

        callback({ 
            response: sessionRegistration, 
            hasBeenUpdated: true
        })

    }

    const onError = (response, status) => {
        setIsLoading(false);
        if (status) {
            setCurrentRegistrationStatus(status)
        }
        if (response.graphQLErrors) {
            response.graphQLErrors.map(item => {
                if (isJson(item.message)) {
                    const error = JSON.parse(item.message);
                    setIsComplete(error.counter_registrations >= maxRegistrations)
                    setMessagePopin('danger', `registration.errors.${error.message}`)
                    callback({
                        response: error, 
                        hasBeenUpdated: false
                    });
                } else {
                    setMessagePopin('danger', `registration.errors.${item.message}`)
                }
            });
        }
    }

    const [registerSession] = useMutation(REGISTER_SESSION, {
        onCompleted({ RegisterSession }) {
            onCompleted(RegisterSession, 'registration.registered')
        },
        onError(error) {
            onError(error, REGISTRATION_STATUS_NONE)
        }
    })

    const [registerQueueSession] = useMutation(REGISTER_QUEUE_SESSION, {
        onCompleted({ RegisterQueueSession }) {
            onCompleted(RegisterQueueSession, 'registration.pending')
        },
        onError(error) {
            onError(error)
        }
    })

    const [registerCancelSession] = useMutation(REGISTER_CANCEL_SESSION, {
        onCompleted({ RegisterCancelSession }) {
            onCompleted(RegisterCancelSession, 'registration.canceled')
        },
        onError(error) {
            onError(error)
        }
    })

    const onClick = (action) => {
        const params = {
            variables: {
                uid
            }
        }

        switch (action) {
            case ACTION_REGISTER:
                setIsLoading(true)
                return registerSession(params);

            case ACTION_PENDING:
                setIsLoading(true)
                return registerQueueSession(params)

            case ACTION_CANCEL:
                if (!confirm(t("registration.cancel_confirmation")))
                    break
                setIsLoading(true)
                return registerCancelSession(params)
        }
    }

    return (
        <div className="columns is-variable is-1">
            <div className="column">
                <button
                    onClick={() => onClick(computeAction(currentRegistrationStatus, isComplete))}
                    className={`button session-card-button ${className} ${btnClass} ${(isLoading ? ' is-loading' : '')}`}
                >
                    <span className="label-info is-size-6">{btnLabelInfo}</span>
                    <span className="label-action is-size-6">{btnLabelAction}</span>
                </button>
            </div>
            {(!isComplete && currentRegistrationStatus == REGISTRATION_STATUS_PENDING) && (
                <div className="column">
                    <button
                        onClick={() => onClick(ACTION_REGISTER)}
                        className="button session-card-button is-info"
                    >
                        <span className="label-info is-size-6">{t("session_card_button.label_info.available")}</span>
                        <span className="label-action is-size-6">{t("session_card_button.label_action.register_available")}</span>
                    </button>
                </div>)
            }
        </div>
    )
}

SessionRegisterButton.propTypes = {
    uid: PropTypes.string,
    counterRegistrations: PropTypes.number,
    callback: PropTypes.func,
    maxRegistrations: PropTypes.number,
    registrationStatus: PropTypes.any,
    className: PropTypes.string
}

export default SessionRegisterButton;
