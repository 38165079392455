import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import { MEMBER_JOIN } from 'graphql/mutation/member';
import { useTranslation } from 'react-i18next';
import { GET_CLUB_MEMBER } from 'graphql/query/club';

const MEMBER_STATUS_NONE = 'none'
const MEMBER_STATUS_ACCEPTED = 'accepted'

const ClubJoinButton = ({ clubId, onAccept, onWait }) => {

    const { t } = useTranslation()

    const [memberStatus, setMemberStatus] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        getClubMember({
            variables: {
                id: clubId
            }
        })
    }, [clubId])

    const [getClubMember] = useLazyQuery(GET_CLUB_MEMBER, {
        onCompleted({ Club }) {
            setIsLoading(false)
            setMemberStatus(Club?.Member?.status)
        }
    })

    const [memberJoin] = useMutation(MEMBER_JOIN, {
        onCompleted({ MemberJoin }) {
            setIsLoading(false)
            setMemberStatus(MemberJoin)
            if (MemberJoin == MEMBER_STATUS_ACCEPTED) {
                onAccept && onAccept(MemberJoin)
            } else {
                onWait && onWait(MemberJoin)
            }
        },
        onError() {
            setIsLoading(false)
            setMemberStatus('')
        }
    })

    const onClick = () => {
        setIsLoading(true)
        memberJoin({
            variables: {
                clubId
            }
        })
    }

    if (memberStatus === undefined || memberStatus == MEMBER_STATUS_NONE) {
        return (
            <button
                onClick={onClick}
                className={`button is-info " + ${(isLoading ? ' is-loading' : '')}`}>
                {t('member_status.' + MEMBER_STATUS_NONE)}
            </button>
        )
    }

    return null
}

ClubJoinButton.propTypes = {
    clubId: PropTypes.number,
    onAccept: PropTypes.func,
    onWait: PropTypes.func
}

export default ClubJoinButton