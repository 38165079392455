import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PackageFormTabLimitations = ({ 
    clubData, 
    currentLimitationTypeIds, setCurrentLimitationTypeIds, 
    currentLimitationRoomIds, setCurrentLimitationRoomIds, 
    currentLimitationClubIds, setCurrentLimitationClubIds, 
}) => {

    const { t } = useTranslation(["translation", "errors", "forms"])
    
    const clubCount = clubData.length;
    const roomCount = clubData.reduce((acc, club) => acc + club.Rooms.length, 0)

    const updateLimitationIds = (e, id, currentStateValue, currentStateFn) => {
        let ids = currentStateValue == '' ? [] : currentStateValue.split(',');

        if (e.currentTarget.checked) {
            if (!ids.includes(id)) {
                ids.push(id)
            }
        } else {
            ids = ids.filter((item) => item != id)
        }

        const str = (ids.length == 1) ? String(ids[0]) : ids.join(',')

        currentStateFn(str);
    }

    return (
        <>
            <div className="columns">
                <div className="column">
                    <strong className="is-block mb-3">{t("forms:package.fields.limitations.types.label")}</strong>
                    {clubData.map((club) =>
                        club.Types.map((type) =>
                            <label className="checkbox is-block mb-2" key={"label-type-" + type.id}>
                                <input
                                    key={"checkbox-type-" + type.id}
                                    checked={currentLimitationTypeIds.includes(type.id)}
                                    onChange={(e) => updateLimitationIds(e, type.id, currentLimitationTypeIds, setCurrentLimitationTypeIds)}
                                    className="mr-1"
                                    type="checkbox"
                                />
                                {clubCount > 1 && (club.name + " - ")}{type.name}
                            </label>
                        )
                    )}
                </div>

                {roomCount > 1 && <div className="column">
                    <strong className="is-block mb-3">{t("forms:package.fields.limitations.rooms.label")}</strong>
                    {clubData.map((club) =>
                        club.Rooms.map((room) =>
                            <label className="checkbox is-block mb-2" key={"label-room-" + room.id}>
                                <input
                                    key={"checkbox-room-" + room.id}
                                    checked={currentLimitationRoomIds.includes(room.id)}
                                    onChange={(e) => updateLimitationIds(e, room.id, currentLimitationRoomIds, setCurrentLimitationRoomIds)}
                                    className="mr-1"
                                    type="checkbox"
                                />
                                {clubCount > 1 && (club.name + " - ")}{room.name}
                            </label>
                        )
                    )}
                </div>}

                {clubCount > 1 && <div className="column">
                    <strong className="is-block mb-3">{t("forms:package.fields.limitations.clubs.label")}</strong>
                    {clubData.map((club) =>
                        <label className="checkbox is-block mb-2" key={"label-club-" + club.id}>
                            <input
                                key={'checkbox-club-' + club.id}
                                checked={currentLimitationClubIds.includes(club.id)}
                                onChange={(e) => updateLimitationIds(e, club.id, currentLimitationClubIds, setCurrentLimitationClubIds)}
                                className="mr-1"
                                type="checkbox"
                            />
                            {club.name}
                        </label>
                    )}
                </div>}
            </div>
        </>
    )
}

PackageFormTabLimitations.propTypes = {
    packageData: PropTypes.any,
    clubData: PropTypes.any,
    currentLimitationTypeIds: PropTypes.any,
    setCurrentLimitationTypeIds: PropTypes.func,
    currentLimitationRoomIds: PropTypes.any,
    setCurrentLimitationRoomIds: PropTypes.func,
    currentLimitationClubIds: PropTypes.any,
    setCurrentLimitationClubIds: PropTypes.func,
}

export default PackageFormTabLimitations