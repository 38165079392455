import { gql } from "@apollo/client";

export const GET_COMPANY = gql`
    query Club($clubId: Int) {
        Club(id: $clubId) {
            name
            Company {
                id
                name
                address
                zip_code
                city
                siret
                legal_form
                capital
                rcs
                tva_intra
                city_registry
            }
        }
    }  
`