import { gql } from '@apollo/client';

export const ME_UPDATE_PICTURE = gql`
  mutation MeUpdatePicture(
    $picture: TypeUpload
  ) {
    MeUpdatePicture(
      picture: $picture
    ) 
  }
`;

export const ME_REMOVE_PICTURE = gql`
  mutation MeRemovePicture {
    MeRemovePicture 
  }
`;