import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import moment from "moment";

import FormSubmit from "components/form/FormSubmit";

import { setSubmitButtonState } from "../../utils/form";
import { nullIfEmpty, datetimeOrNull } from "../../utils/str";

import {
    SAVE_SESSION,
    CREATE_SESSION,
    DELETE_SESSION,
} from "graphql/mutation/session";

import { catchFormErrors } from "utils/error";
import getSchema from "./SessionForm.schema";
import SessionFormFields from "./SessionFormFields";
import SessionFormMembers from "./SessionFormMembers";

const SessionForm = ({ sessionUid, data, formData, config, onClose }) => {
    const { t } = useTranslation(["translation", "errors", "forms"]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");
    const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
    const [activeTab, setActiveTab] = useState("infos");

    const [currentSessionUid, setCurrentSessionUid] = useState(sessionUid);

    const schema = getSchema(t);

    useEffect(() => {
        setCurrentSessionUid(sessionUid);
    }, [sessionUid]);

    const catchError = (error) => {
        setIsLoading(false);
        setSubmitButtonState(setHasSubmitError);
        catchFormErrors({
            t,
            errorPath: "session",
            defaultMessagePath: "errors:messages.form_has_errors",
            error,
            setError,
            setMessage,
        });
    };
    // Create session mutation
    const [createSession] = useMutation(CREATE_SESSION, {
        onCompleted({ CreateSession }) {
            setCurrentSessionUid(CreateSession.uid);
            setIsLoading(false);
            setHasBeenUpdated(true);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error);
        },
    });

    // Save session mutation
    const [saveSession] = useMutation(SAVE_SESSION, {
        onCompleted() {
            setIsLoading(false);
            setHasBeenUpdated(true);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error);
        },
    });

    // Delete session mutation
    const [deleteSession] = useMutation(DELETE_SESSION, {
        onCompleted: () => {
            setCurrentSessionUid(null);
            onClose(true);
        },
        onError(error) {
            catchError(error);
        },
    });

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError,
        register,
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = ({
        room_id,
        type_id,
        user_id,
        message,
        max_registrations,
        session_date,
        session_start_time,
        session_end_time,
    }) => {
        setIsLoading(true);
        setMessage("");
        clearErrors();

        let sessionDate = moment(session_date).format("YYYY-MM-DD");
        let sessionStartDatetime = sessionDate + " " + session_start_time;
        let sessionEndDatetime = sessionDate + " " + session_end_time;

        if (currentSessionUid) {
            saveSession({
                variables: {
                    uid: currentSessionUid,
                    roomId: room_id,
                    status: "enabled",
                    typeId: type_id,
                    userId: nullIfEmpty(user_id),
                    message: message,
                    maxRegistrations: max_registrations,
                    startDatetime: datetimeOrNull(sessionStartDatetime),
                    endDatetime: datetimeOrNull(sessionEndDatetime),
                },
            });
        } else {
            createSession({
                variables: {
                    roomId: room_id,
                    status: "enabled",
                    typeId: type_id,
                    userId: nullIfEmpty(user_id),
                    message: message,
                    maxRegistrations: max_registrations,
                    startDatetime: datetimeOrNull(sessionStartDatetime),
                    endDatetime: datetimeOrNull(sessionEndDatetime),
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    const onDelete = () => {
        if (confirm(t("forms:session.messages.confirm_delete"))) {
            deleteSession({
                variables: { uid: currentSessionUid },
            });
        }
    };

    const getRegistrationsCount = (registrations) =>
        registrations ? " (" + registrations.length + ")" : null;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="tabs is-boxed is-medium">
                    <ul>
                        <li
                            onClick={() => setActiveTab("infos")}
                            className={activeTab == "infos" ? "is-active" : ""}
                        >
                            <a>Infos</a>
                        </li>
                        <li
                            onClick={() => setActiveTab("members")}
                            className={
                                activeTab == "limitations" ? "is-active" : ""
                            }
                        >
                            <a>
                                Membres
                                {getRegistrationsCount(data?.Registrations)}
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={activeTab == "infos" ? "" : "is-hidden"}>
                    <SessionFormFields
                        data={data}
                        config={config}
                        formData={formData}
                        message={message}
                        errors={errors}
                        control={control}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        t={t}
                    />
                </div>
                <div className={activeTab == "members" ? "" : "is-hidden"}>
                    <SessionFormMembers registrations={data?.Registrations} />
                </div>

                <footer className="modal-footer">
                    <div className="columns">
                        <div className="column">
                            <FormSubmit
                                isLoading={isLoading}
                                isSubmitted={isSubmitted}
                                hasSubmitError={hasSubmitError}
                                classSize={"is-normal"}
                            >
                                {t("forms:global.btn_save")}
                            </FormSubmit>
                        </div>
                        <div className="column">
                            <button
                                onClick={() => {
                                    onClose(hasBeenUpdated);
                                    setHasBeenUpdated(false);
                                }}
                                type="button"
                                className="button is-info is-wide"
                            >
                                Fermer
                            </button>
                        </div>

                        <div className="column">
                            {currentSessionUid && (
                                <button
                                    onClick={() => onDelete()}
                                    type="button"
                                    className="button is-danger is-wide"
                                >
                                    {t("forms:global.btn_delete")}
                                </button>
                            )}
                        </div>
                    </div>
                </footer>
            </form>
        </>
    );
};

SessionForm.propTypes = {
    sessionUid: PropTypes.string,
    data: PropTypes.object,
    formData: PropTypes.object,
    config: PropTypes.object,
    onClose: PropTypes.func,
};

export default SessionForm;
