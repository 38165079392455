import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ControlColorPicker = ({ defaultValue, onChange, error, label, help, placeholder }) => {

    const [value, setValue] = useState(defaultValue)
    const [isActive, setIsActive] = useState(false)

    const hasError = error && error.length > 0;

    const colors = [
        {label: placeholder, value: ''},
        {label: 'Rouge 1', value: '#e63946'},
        {label: 'Rouge 2', value: '#f94144'},
        {label: 'Vert 1', value: '#2a9d8f'},
        {label: 'Vert 2', value: '#2ec4b6'},
        {label: 'Bleu 1', value: '#0077b6'},
        {label: 'Bleu 2', value: '#48cae4'},
        {label: 'Violet 1', value: '#757bc8'},
        {label: 'Violet 2', value: '#bbadff'},        
        {label: 'Jaune 1', value: '#fee440'},
        {label: 'Jaune 2', value: '#f9c74f'},        
        {label: 'Orange 1', value: '#f77f00'},
        {label: 'Orange 2', value: '#ffb703'},        
        {label: 'Rose 1', value: '#ffafcc'},
        {label: 'Rose 2', value: '#f1c0e8'}
        
    ]

    const handleOnChange = (value) => {
        onChange(value)
        setValue(value)
        setIsActive(false)
    }

    const valueToLabel = (value) => colors.filter(color => color.value == value)[0]?.label

    return (
        <>
            <div className="field field-color-picker">
                {label && <div className="label">{label}</div>}
                <div className={'dropdown is-wide' + (isActive ? ' is-active' : '')}>

                    <div className="dropdown-trigger is-control is-clickable" onClick={() => setIsActive(!isActive)}>
                        <div className="columns">
                            <div className="column">
                                <div className="color-square" style={{backgroundColor: value}}></div>
                                <div className="color-title">{valueToLabel(value)}</div>
                            </div>
                            <div className="column is-narrow">
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                            </div>
                        </div>                            
                    </div>

                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            {colors.map((color, index) => (
                                <a key={index} onClick={() => handleOnChange(color.value)} href="#" className="dropdown-item" style={{backgroundColor: color.value}}>
                                    {color.label}
                                </a>                                      
                            )
                        )}
                        </div>
                    </div>
                </div>
                {hasError && <p className="help is-danger">{error}</p>}
                {help && <p className="help has-text-grey-light">{help}</p>}
            </div>
        </>
    )
}


ControlColorPicker.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    help: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string
}

export default ControlColorPicker;