import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Timeline } from './Timeline'
import PlanningSessions from './PlanningSessions'

import { PlanningContext } from "context/PlanningContext";

import { setSwipeEffect } from './utils';

const PlanningColumn = ({ isToday, sessions, onClick }) => {

    const [swipeClass, setSwipeClass] = useState('');
    const { setIsSwipedLeft, isSwipedLeft, setIsSwipedRight, isSwipedRight } = useContext(PlanningContext)

    useEffect(() => {
        if (isSwipedLeft) {
            setSwipeEffect(setSwipeClass, setIsSwipedLeft, 'left')
        }
    }, [isSwipedLeft])

    useEffect(() => {
        if (isSwipedRight) {
            setSwipeEffect(setSwipeClass, setIsSwipedRight, 'right')
        }
    }, [isSwipedRight])
    
    const isTodayClass = isToday ? 'is-today' : '';
    
    return (
        <div className={`planning-column column has-grid ${swipeClass} ${isTodayClass}`}>
            <div className="planning-column-body">
                {isToday && <Timeline />}

                {sessions && sessions.map((sessions, key) =>
                    <PlanningSessions
                        key={key}
                        sessions={sessions}
                        onClick={onClick}
                    />
                )}
            </div>
        </div>
    )
}

PlanningColumn.propTypes = {
    isToday: PropTypes.bool,
    sessions: PropTypes.array,
    onClick: PropTypes.func
}

export default PlanningColumn;