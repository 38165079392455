import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SessionRegisterButton from "components/session/SessionRegisterButton";
import Nl2br from 'components/Nl2br'
import { getSessionDate, getSessionTime } from "./utils";

import SessionModalAttendees from "./SessionModalAttendees";
import { intOrZero } from "utils/str";
import SessionModalContentRegistrations from "./SessionModalContentRegistrations";
import SessionModalContentCoach from "./SessionModalContentCoach";

const SessionModalContent = ({ session, onClose }) => {
    const { t } = useTranslation();

    const [showRegistrations, setShowRegistrations] = useState(false);

    const [currentCounterRegistrations, setCurrentCounterRegistrations] = useState(intOrZero(session.counter_registrations));
    const [currentCounterPending, setCurrentCounterPending] = useState(intOrZero(session.counter_pending));
    const [currentRegistrations, setCurrentRegistrations] = useState(session.Registrations)

    const [hasBeenUpdated, setHasBeenUpdated] = useState(false);

    const sessionDate = getSessionDate(t, session.start_datetime);
    const sessionTime = getSessionTime(session.start_datetime, session.end_datetime);

    const registrationCallback = ({ response, hasBeenUpdated }) => {
        setCurrentCounterRegistrations(intOrZero(response.counter_registrations));
        setCurrentCounterPending(intOrZero(response.counter_pending));
        setCurrentRegistrations(response?.Registrations)

        setHasBeenUpdated(hasBeenUpdated);
    }

    const config = session?.Room?.Club?.Config?.config;
    let showCoach = true;
    if (config) {
        const configJson = JSON.parse(config);
        if (configJson) {
            showCoach = configJson?.show_coach ?? true;
        }
    }

    return (
        <>
            <div
                style={{ backgroundColor: session.type_color }}
                className="session-card-line"
            ></div>

            <div className="modal-card-body">
                <p className="is-size-4">{session.type_name}</p>

                <p className="is-size-6">{session.Room.name}</p>

                <p className="is-uppercase is-size-4">{sessionDate} | {sessionTime}</p>


                <SessionModalContentCoach
                    show={showCoach}
                    firstname={session.user_firstname}
                    lastname={session.user_lastname}
                />


                <div className="mt-2">
                    <div onClick={() => setShowRegistrations(current => !current)}>
                        <SessionModalContentRegistrations
                            counterRegistrations={currentCounterRegistrations}
                            counterPending={currentCounterPending}
                            maxRegistrations={session.max_registrations}
                        />
                    </div>

                    <SessionModalAttendees
                        isOwner={session.is_owner}
                        show={showRegistrations}
                        registrations={currentRegistrations}
                    />
                </div>

                {session.message && (
                    <div
                        className="block"
                        style={{ height: "45px", overflow: "hidden" }}
                    >
                        <div className="has-text-weight-light is-size-6">
                            <Nl2br>{session.message}</Nl2br>
                        </div>
                    </div>
                )}
            </div>


            <footer className="modal-card-foot">
                <div className="columns is-wide is-variable is-1">
                    <div className="column is-three-quarters">
                        <SessionRegisterButton
                            className="is-wide"
                            uid={session.uid}
                            registrationStatus={session?.Registration?.status}
                            counterRegistrations={session.counter_registrations}
                            maxRegistrations={session.max_registrations}
                            callback={registrationCallback}
                        />
                    </div>
                    <div className="column">
                        {onClose &&
                            <button className="button is-wide" onClick={() => onClose(hasBeenUpdated)}>
                                {t("global.close")}
                            </button>}
                    </div>
                </div>
            </footer>
        </>
    );
};

SessionModalContent.propTypes = {
    session: PropTypes.object,
    onClose: PropTypes.func,
};

export default SessionModalContent;
