import { gql } from "@apollo/client";

export const MEMBER_ADMIN = gql`
    query MemberAdmin($memberId: Int!, $clubId: Int!) {
        Club(id: $clubId) {
            id
            Packages {
                id
                title
            }
        }
        Member(id: $memberId) {
            id
            user_id
            package_user_id
            status
            created_at
            roles
            User {
                id
                firstname
                lastname
                email
                picture
            }
            PackageUserCurrent {
                id
                title
                status
                payment_frequency
                amount
                tax_percentage
                taxed_amount
                date_start
                date_end
            }
            PackagesUser {
                id
                title
                status
                payment_frequency
                amount
                tax_percentage
                taxed_amount
                date_start
                date_end
            }
        }
    }
`;
export const GET_MEMBERS = gql`
    query Members($clubId: Int!) {
        Club(id: $clubId) {
            id
            name
        }
        Members(club_id: $clubId) {
            id
            status
            roles
            created_at
            User {
                id
                firstname
                lastname    
            }
            PackageUserCurrent {
                id
                title
                status
                date_start
                date_end
                sessions_counter
                rules {
                    unlimited
                    sessions
                }

            }
        }
    }
`;

export const MEMBER_FIND = gql`
    query MemberFind($email: String!, $clubId: Int!) {
        MemberFind(email: $email, club_id: $clubId) {
            id
            email
            firstname
            lastname
            picture
        }
    }
`;
