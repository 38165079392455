import React from 'react';
import PropTypes from 'prop-types';
import ProfilForm from 'forms/Profil/ProfilForm'

const PageProfilContentInfos = ({ me, timezones }) => {

    return (
        <>
            <ProfilForm
                email={me.email}
                firstname={me.firstname}
                lastname={me.lastname}
                timezone={me.timezone}
                picture={me.picture}
                timezones={timezones}
            />
        </>
    )
}

PageProfilContentInfos.propTypes = {
    me: PropTypes.object,
    timezones: PropTypes.array
}

export default PageProfilContentInfos;