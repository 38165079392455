import React from 'react'
import { useQuery } from '@apollo/client'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import PageClubPlanning from './PageClubPlanning'

import { GET_CLUB } from 'graphql/query/club'

import { useParams } from 'react-router';
import { isHosted } from 'utils/app'

const PageClub = () => {

    const { slug } = useParams()
    var params = {
        variables: {
            slug
        }
    }
    // retrieve hostname and check if url is different from default
    // if true enable get club by url instead of by slug
    if (isHosted()) {
        params = {
            variables: {
                id: null,
                slug: null,
                url: location.hostname,
            }
        }
    }

    const { loading, error, data } = useQuery(GET_CLUB, params);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Club === null) {
        return (
            <LoadingError error={{ message: "Club not found" }} />
        )
    }

    return <PageClubPlanning club={data.Club} />
}

export default PageClub;