import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Nl2br from 'components/Nl2br'
import { getPicture } from 'utils/picture'
import { getRoute } from 'utils/route'

function Club({ club, adminMode }) {

    let link = adminMode ? getRoute("admin_club_home", {club_id: club.id}) : getRoute("club", {slug: club.slug})

    return (
        <div className="card club-card mr-3 mb-3">
            <div className="card-content">
                <Link to={link}>
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-64x64">
                                <img src={getPicture(club.picture)} />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">{club.name}</p>
                            <Nl2br>{club.address}</Nl2br>
                        </div>
                    </div>
                </Link>
            </div>
        </div >
    )
}

Club.defaultProps = {
    adminMode: false
}

Club.propTypes = {
    club: PropTypes.any,
    adminMode: PropTypes.bool
}
export default Club
