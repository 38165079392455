import { gql } from "@apollo/client";

export const GET_MY_SESSIONS = gql`
    query getMySessions {
        MySessions {
            id
            uid
            room_id
            room_name
            club_id
            club_name
            type_id
            type_name
            type_color
            type_picture
            type_description
            status
            message
            start_datetime
            end_datetime
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                id
                status
            }
        }
    }
`;

export const GET_SESSION_FORM = gql`
    query Session_Form($clubId: Int!) {
        Rooms(club_id: $clubId) {
            id
            name
            description
        }
        Types(club_id: $clubId) {
            id
            club_id
            name
            description
            color
        }
        Coaches(club_id: $clubId) {
            id
            firstname
            lastname
        }
        Config(club_id: $clubId) {
            id
            club_id
            config
        }
    }
`;

export const GET_SESSIONS = gql`
    query getSessions(
        $clubId: Int!
        $dateStart: DatetimeType!
        $dateEnd: DatetimeType!
    ) {
        Sessions(club_id: $clubId, date_start: $dateStart, date_end: $dateEnd) {
            id
            uid
            planning_id
            room_id
            type_id
            type_name
            type_color
            type_picture
            type_description
            type_position
            user_id
            user_firstname
            user_lastname
            status
            message
            start_datetime
            end_datetime
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                id
                status
            }
        }
        Club(id: $clubId) {
            id
            name
        }
        Types(club_id: $clubId) {
            id
            club_id
            name
            description
            color
        }
        Rooms(club_id: $clubId) {
            id
            name
            description
        }
        PackageUserClub(club_id: $clubId) {
            id
            workspace_id
            user_id
            status
            title
            description
            rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                type_ids
                room_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            date_start
            date_end
            sessions_counter                
        }
    }
`;


export const GET_SESSIONS_ADMIN = gql`
    query getSessions(
        $clubId: Int!
        $dateStart: DatetimeType!
        $dateEnd: DatetimeType!
    ) {
        SessionsAdmin(club_id: $clubId, date_start: $dateStart, date_end: $dateEnd) {
            id
            uid
            planning_id
            room_id
            type_id
            type_name
            type_color
            type_picture
            type_description
            type_position
            user_id
            user_firstname
            user_lastname
            status
            message
            start_datetime
            end_datetime
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                status
            }
        }
        Club(id: $clubId) {
            id
            name
        }
        Types(club_id: $clubId) {
            id
            club_id
            name
            description
            color
        }
        Rooms(club_id: $clubId) {
            id
            name
            description
        }
        PackageUserClub(club_id: $clubId) {
            id
            workspace_id
            user_id
            status
            title
            description
            rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                type_ids
                room_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            date_start
            date_end
            sessions_counter                
        }
    }
`;

export const GET_SESSION = gql`
    query getSession($uid: String!) {
        Session(uid: $uid) {
            id
            uid
            room_id
            type_id
            type_name
            type_color
            type_picture
            type_description
            user_id
            user_firstname
            user_lastname
            status
            message
            start_datetime
            end_datetime
            max_registrations
            counter_registrations
            counter_pending
            is_owner
            Registration {
                id
                status
            }
            Room {
                id
                name
                Club {
                    id
                    Config {
                        config
                    }
                }
            }
            Registrations {
                id
                firstname
                lastname
                status
                present
            }
        }
    }
`;
