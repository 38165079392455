import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPDATE_CLUB_PICTURE, REMOVE_CLUB_PICTURE } from 'graphql/mutation/club-picture'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import config from 'config/app'
import { getClubDefaultPicture } from 'utils/picture';
import FormAlert from 'components/form/FormAlert'

const ClubPicture = ({ club, picture }) => {

    const [currentPicture, setCurrentPicture] = useState(picture ?? getClubDefaultPicture())
    const [dropdownActive, setDropdownActive] = useState(false)
    const [message, setMessage] = useState('')

    const fileInputRef = React.createRef()

    const { t } = useTranslation(['forms']);

    const handlePictureRemove = () => {
        setMessage('')
        setDropdownActive(false)
        removeClubPicture({
            variables: {
                id: club.id
            }
        })
    }

    const handlePictureChange = (e) => {
        setMessage('')
        setDropdownActive(false)
        updateClubPicture({
            variables: {
                id: club.id,
                picture: e.target.files[0]
            }
        })
    }

    // remove mutation
    const [removeClubPicture] = useMutation(REMOVE_CLUB_PICTURE, {
        onCompleted({ RemoveClubPicture }) {
            setCurrentPicture(RemoveClubPicture);
        }
    })

    // update mutation
    const [updateClubPicture] = useMutation(UPDATE_CLUB_PICTURE, {
        context: {
            hasUpload: true
        },
        onCompleted({ UpdateClubPicture }) {
            setCurrentPicture(UpdateClubPicture);
        }
    });

    return (
        <Fragment>
            <div className="block ">
                <div className="field">
                    <figure id="club-profil-picture" className="image is-128x128">

                        <input ref={fileInputRef} className="file-input" type="file" name="resume" onChange={(e) => { handlePictureChange(e) }} />

                        {currentPicture &&
                            <img
                                className="club-picture"
                                src={config.api_url + '/' + currentPicture}
                            />}

                        <div id="dropdown-club-profil-picture" className={'dropdown' + (dropdownActive ? ' is-active' : '')}>
                            <div className="dropdown-trigger" onClick={() => setDropdownActive(!dropdownActive)}>
                                <span className="tag is-info">
                                    <FontAwesomeIcon size="xs" icon={faPen} />
                                </span>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a className="dropdown-item" onClick={() => fileInputRef.current.click()} >
                                        {t('forms:global.picture.menu.edit')}
                                    </a>
                                    <a className="dropdown-item" onClick={() => handlePictureRemove()} >
                                        {t('forms:global.picture.menu.remove')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>

                <FormAlert>{message}</FormAlert>
            </div>
        </Fragment>

    )
}

ClubPicture.propTypes = {
    club: PropTypes.object,
    picture: PropTypes.string
}

export default ClubPicture