import * as yup from "yup";

const getSchema = (t) => {
  return yup.object().shape({
    room_id: yup.string().nullable().required(t("errors:forms.planning.fields.room_id.-100")),
    type_id: yup.string().nullable().required(t("errors:forms.planning.fields.type_id.-100")),

    max_registrations: yup.string().required(t("errors:forms.planning.fields.max_registrations.-100")),
    start_date: yup.string().required(t("errors:forms.planning.fields.start_date.-100")).nullable(),

    start_time: yup.string().required(t("errors:forms.planning.fields.start_time.-100")),
    end_time: yup.string().required(t("errors:forms.planning.fields.end_time.-100")),

    repeat_mode: yup.string().required(t("errors:forms.planning.fields.repeat_mode.-100"))
  });
};

export default getSchema;
