import { isValidPassword } from 'utils/password'
import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        email:
            yup.string()
                .required(t('errors:forms.profil.fields.email.-100'))
                .email(t('errors:forms.profil.fields.email.-200')),

        firstname:
            yup.string()
                .required(t('errors:forms.profil.fields.firstname.-100')),

        lastname:
            yup.string()
                .required(t('errors:forms.profil.fields.lastname.-100')),

        password:
            yup.string().when('change-password', {
                is: true,
                then: yup.string()
                    .required(t('errors:forms.profil.fields.password.-100'))
                    .test('isValidPassword', t('errors:forms.profil.fields.password.-3000'), value => isValidPassword(value)),
            }),

        password_confirmation:
            yup.string().when('change-password', {
                is: true,
                then: yup.string()
                    .required(t('errors:forms.profil.fields.password.-100'))
                    .oneOf([yup.ref('password'), null], t('errors:forms.profil.fields.password.-3001')),
            }),
    });    
}

export default getSchema;