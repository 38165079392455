import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import {
  faEnvelope,
  faLock,
  faUser,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import FormSubmit from "components/form/FormSubmit";
import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlSelect from "components/form/ControlSelect";
import UserPicture from "components/user/UserPicture";
import PasswordChecker from "components/PasswordChecker";

import { setAccessToken } from "utils/auth";

import { yupResolver } from "@hookform/resolvers/yup";
import { ME as UPDATE_ME } from "graphql/mutation/me";
import { catchFormErrors } from "utils/error";

import { setSubmitButtonState } from "utils/form";
import getSchema from "./ProfilForm.schema";
import { UserContext } from "context/UserContext";

const ProfilForm = ({
  email,
  firstname,
  lastname,
  timezone,
  picture,
  timezones,
}) => {
  const { t } = useTranslation(["translation", "errors", "forms"]);

  const [changePassword, setChangePassword] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  const { setFirstname } = useContext(UserContext)

  const schema = getSchema(t);

  const [updateMe] = useMutation(UPDATE_ME, {
    onCompleted({ Me }) {
      // reset password fields
      setValue("password", "");
      setValue("password_confirmation", "");
      setChangePassword(false);
      // update firstname and access token
      setFirstname(watchFirstname);
      setAccessToken(Me.token);

      setIsLoading(false);
      setSubmitButtonState(setIsSubmitted);
    },
    onError(error) {
      setSubmitButtonState(setHasSubmitError);
      setIsLoading(false);
      catchFormErrors({
        t,
        errorPath: "profil",
        defaultMessagePath: "errors:messages.form_has_errors",
        error,
        setError,
        setMessage,
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // watch fields
  const watchFirstname = watch("firstname");
  const watchPassword = watch("password");
  const watchPasswordConfirmation = watch("password_confirmation");

  const onSubmit = ({ email, firstname, lastname, timezone, password }) => {
    setIsLoading(true);
    setMessage("");
    clearErrors();

    updateMe({
      variables: {
        email,
        firstname,
        lastname,
        timezone,
        password,
      },
    });
  };
  const onError = () => {
    setMessage(t("errors:messages.form_has_errors"));
    setSubmitButtonState(setHasSubmitError);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormAlert>{message}</FormAlert>

        <UserPicture picture={picture} />

        <Controller
          control={control}
          name="email"
          defaultValue={email}
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faEnvelope}
              error={errors.email?.message}
              type="email"
              value={value}
              placeholder={t("forms:profil.fields.email.placeholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="firstname"
          defaultValue={firstname}
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faUser}
              error={errors.firstname?.message}
              type="text"
              value={value}
              placeholder={t("forms:profil.fields.firstname.placeholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="lastname"
          defaultValue={lastname}
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faUser}
              error={errors.lastname?.message}
              type="text"
              value={value}
              placeholder={t("forms:profil.fields.lastname.placeholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="timezone"
          defaultValue={timezone}
          render={({ onChange, value }) => (
            <ControlSelect
              onChange={onChange}
              icon={faClock}
              error={errors.timezone?.message}
              value={value}
              values={timezones}
              placeholder={t("forms:profil.fields.timezone.placeholder")}
            />
          )}
        />

        <div className="field">
          <label className="checkbox">
            <input
              ref={register}
              className="mr-2"
              name="change-password"
              type="checkbox"
              checked={!!changePassword}
              onChange={() => setChangePassword(!changePassword)}
            />
            {t("forms:profil.fields.modify_password.label")}
          </label>
        </div>

        <div
          className="field"
          style={{ display: changePassword ? "" : "none" }}
        >
          <div className="field">
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ onChange, value }) => (
                <ControlText
                  onChange={onChange}
                  icon={faLock}
                  error={errors.password?.message}
                  type="password"
                  value={value}
                  placeholder={t("forms:profil.fields.password.placeholder")}
                />
              )}
            />

            <PasswordChecker password={watchPassword ?? ""} />

            <Controller
              control={control}
              name="password_confirmation"
              defaultValue=""
              render={({ onChange, value }) => (
                <ControlText
                  onChange={onChange}
                  icon={faLock}
                  error={errors.password_confirmation?.message}
                  type="password"
                  value={value}
                  placeholder={t(
                    "forms:profil.fields.password_confirmation.placeholder"
                  )}
                />
              )}
            />

            <PasswordChecker password={watchPasswordConfirmation ?? ""} />
          </div>
        </div>

        <FormSubmit
          isLoading={isLoading}
          isSubmitted={isSubmitted}
          hasSubmitError={hasSubmitError}
        >
          {t("forms:global.btn_save")}
        </FormSubmit>
      </form>
  );
};

ProfilForm.propTypes = {
  email: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  timezone: PropTypes.string,
  picture: PropTypes.string,
  timezones: PropTypes.arrayOf(PropTypes.string),
};

export default ProfilForm;
