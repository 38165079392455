import { isValidPassword } from 'utils/password'
import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        password:
            yup.string()
                .required(t('errors:forms.password_reset.fields.password.-100'))
                .test('isValidPassword', t('errors:forms.password_reset.fields.password.-3000'), value => isValidPassword(value)),

        password_confirmation:
            yup.string()
                .required(t('errors:forms.password_reset.fields.password.-100'))
                .oneOf([yup.ref('password'), null], t('errors:forms.password_reset.fields.password.-3001')),
    });
}

export default getSchema;