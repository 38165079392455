import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        planning_allowed_visibility: yup
            .number()
            .typeError(
                t("errors:forms.config.fields.planning_allowed_visibility.-200")
            )
            .required(
                t("errors:forms.config.fields.planning_allowed_visibility.-100")
            ),

        planning_allowed_registration: yup
            .number()
            .typeError(
                t("errors:forms.config.fields.planning_allowed_registration.-200")
            )
            .required(
                t("errors:forms.config.fields.planning_allowed_registration.-100")
            ),

        cancel_registration_hours_delay: yup
            .number()
            .typeError(
                t("errors:forms.config.fields.cancel_registration_hours_delay.-200")
            )
            .required(
                t("errors:forms.config.fields.cancel_registration_hours_delay.-100")
            ),

        member_join_policy: yup
            .string()
            .oneOf(
                ["auto", "moderate"],
                t("errors:forms.config.fields.member_join_policy.-200")
            )
            .required(t("errors:forms.config.fields.member_join_policy.-100")),

        default_max_registrations: yup
            .number()
            .typeError(
                t("errors:forms.config.fields.default_max_registrations.-200")
            )
            .required(
                t("errors:forms.config.fields.default_max_registrations.-100")
            )
    });
};

export default getSchema;
