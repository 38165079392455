import React from "react"
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom'
import { getRoute } from 'utils/route'

import { GET_PACKAGES } from "graphql/query/package"

import Table from 'components/Table';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { processData } from 'utils/table'

const PageAdminPackagesTable = ({ workspaceId }) => {

    const { t } = useTranslation(["admin"]);
    const { club_id } = useParams()

    const { loading, error, data } = useQuery(GET_PACKAGES, {
        variables: {
            workspaceId
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Packages === null) {
        return (
            <LoadingError error={{ message: "Packages not found" }} />
        )
    }

    return (
        <Table
            options={{
                search: true,
                toolbar: true,
                rowStyle: rowData => {
                    if (rowData.status == 'disabled') {
                        return { backgroundColor: "#eee" }
                    }
                }
            }}
            columns={[
                {
                    title: t("admin:package.table.columns.title"),
                    render: rowData =>
                        <>
                            <Link to={getRoute("admin_package_edit", { club_id, package_id: rowData.id })}>
                                {rowData.title}
                                {rowData.is_default_on_join == 1 && <span className="tag is-primary is-small is-rounded ml-2">{t("admin:package.table.columns.is_default_on_join")}</span>}
                            </Link>
                        </>,
                    customFilterAndSearch: (term, rowData) => (rowData.title.toLowerCase()).indexOf(term.toLowerCase()) != -1
                },
                {
                    title: t("admin:package.table.columns.payment_frequency"),
                    render: rowData =>
                        <>{t("admin:package.table.payment_frequency." + rowData.payment_frequency)}</>,
                    customFilterAndSearch: (term, rowData) => (t("admin:package.table.payment_frequency." + rowData.payment_frequency).toLowerCase()).indexOf(term.toLowerCase()) != -1
                    

                },
                {
                    title: t("admin:package.table.columns.duration_days"),
                    field: "duration_days"
                },
                {
                    title: t("admin:package.table.columns.amount"),
                    render: rowData =>
                        <>{rowData.amount}€</>
                },
                {
                    title: t("admin:package.table.columns.tax_percentage"),
                    render: rowData =>
                        <>{rowData.tax_percentage}%</>
                },
                {
                    title: t("admin:package.table.columns.taxed_amount"),
                    render: rowData =>
                        <>{rowData.taxed_amount}€</>

                },
            ]}
            data={processData(data.Packages)}
        />
    )
}

PageAdminPackagesTable.propTypes = {
    workspaceId: PropTypes.number,
}

export default PageAdminPackagesTable;