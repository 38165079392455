import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/client";

import PlanningForm from "forms/Planning/PlanningForm";
import { GET_PLANNING, GET_PLANNING_FORM } from "graphql/query/planning";
import Loading from "components/loading/Loading";

const PlanningFormModal = ({ planningId, onClose }) => {

    const { club_id: clubId } = useParams();

    const [planningData, setPlanningData] = useState(null)

    const [getPlanningData] = useLazyQuery(
        GET_PLANNING,
        {
            fetchPolicy: "network-only",
            onCompleted: ({ Planning }) => {
                setPlanningData(Planning)
            }
        }
    );

    const { loading, data: formData } = useQuery(GET_PLANNING_FORM, {
        fetchPolicy: "network-only",
        variables: {
            clubId
        }
    });

    useEffect(() => {
        if (planningId) {
            getPlanningData({
                variables: { id: parseInt(planningId) }
            });
        } else {
            setPlanningData(null)
        }
    }, [planningId]);


    if (loading || (planningId && !planningData)) {
        return <Loading />
    }

    const config = formData?.Config?.config ? JSON.parse(formData.Config.config) : {}

    return (
        <PlanningForm
            planningId={planningId}
            data={planningData}
            formData={formData}
            config={config}
            onClose={onClose}
        />
    )
};

PlanningFormModal.propTypes = {
    planningId: PropTypes.number,
    onClose: PropTypes.func
};

export default PlanningFormModal;
