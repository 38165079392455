import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { MessagePopinContext } from "context/MessagePopinContext";
import PropTypes from "prop-types";
import {
    REGISTRATION_STATUS_VALIDATED,
    REGISTRATION_STATUS_CANCELLED,
} from "../../components/session/const";

import {
    ADMIN_REGISTER_CANCEL_SESSION,
    ADMIN_REGISTER_VALID_SESSION,
} from "../../graphql/mutation/register";

const SessionFormMembers = ({ registrations }) => {
    const { t } = useTranslation(["translation", "forms", "errors"]);
    const messagePopinContext = useContext(MessagePopinContext);
    console.log({messagePopinContext})

    const setMessagePopin = (type, path) => {
        messagePopinContext.dispatch({ type: type, message: path });
    };

    const [adminRegisterCancelSession] = useMutation(
        ADMIN_REGISTER_CANCEL_SESSION,
        {
            onError(response) {
                if (response.graphQLErrors) {
                    response.graphQLErrors.map((item) => {
                        setMessagePopin(
                            "danger",
                            t(`errors:messages.${item.message}`)
                        );
                    });
                }
            },
        }
    );

    const [adminRegisterValidSession] = useMutation(
        ADMIN_REGISTER_VALID_SESSION,
        {
            onError(response) {
                if (response.graphQLErrors) {
                    response.graphQLErrors.map((item) => {
                        setMessagePopin(
                            "danger",
                            t(`errors:messages.${item.message}`)
                        );
                    });
                }
            },
        }
    );

    const validRegistration = (registrationId) => {
        if (confirm(t("forms:session.messages.confirm_validation"))) {
            adminRegisterValidSession({
                variables: {
                    registrationId,
                },
            });
        }
    };

    const cancelRegistration = (registrationId) => {
        if (confirm(t("forms:session.messages.confirm_cancellation"))) {
            adminRegisterCancelSession({
                variables: {
                    registrationId,
                },
            });
        }
    };

    return (
        <>
            <table className="table is-striped is-bordered is-fullwidth is-hoverable mb-2 mt-2">
                <thead>
                    <tr>
                        <th>
                            {t("forms:session.members_table.header.firstname")}
                        </th>
                        <th>
                            {t("forms:session.members_table.header.lastname")}
                        </th>
                        <th>
                            {t("forms:session.members_table.header.status")}
                        </th>
                        <th className="has-text-centered">
                            {t("forms:session.members_table.header.action")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {registrations &&
                        registrations.map((registration, index) => {
                            return (
                                <tr key={index}>
                                    <td>{registration.firstname}</td>
                                    <td>{registration.lastname}</td>
                                    <td>
                                        {t(
                                            "translation:registration.status." +
                                                registration.status
                                        )}
                                    </td>
                                    <td className="has-text-centered">
                                        {registration.status ==
                                            REGISTRATION_STATUS_VALIDATED && (
                                            <button
                                                type="button"
                                                className="button is-small is-light is-danger"
                                                onClick={() =>
                                                    cancelRegistration(
                                                        registration.id
                                                    )
                                                }
                                            >
                                                {t("forms:global.btn_cancel")}
                                            </button>
                                        )}

                                        {registration.status ===
                                            REGISTRATION_STATUS_CANCELLED && (
                                            <button
                                                type="button"
                                                className="button is-small is-light is-info"
                                                onClick={() =>
                                                    validRegistration(
                                                        registration.id
                                                    )
                                                }
                                            >
                                                {t("forms:global.btn_valid")}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </>
    );
};

SessionFormMembers.propTypes = {
    registrations: PropTypes.array,
};

export default SessionFormMembers;
