import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { setLang } from 'utils/lang'
import { setCssTheme } from 'utils/theme'

import { ME_UPDATE_LANG } from 'graphql/mutation/me-lang'
import { ME_UPDATE_THEME } from 'graphql/mutation/me-theme'

import { ThemeContext } from 'context/ThemeContext'

const LANG_FR = 'fr';
const LANG_EN = 'en';

const THEME_AUTO = 'auto';
const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';

const PageProfilContentApp = ({ lang, theme }) => {

    const { t } = useTranslation(["pages"]);

    const [currentLang, setCurrentLang] = useState(lang);
    const [currentTheme, setCurrentTheme] = useState(theme);
    const { setTheme } = useContext(ThemeContext)

    const [updateLang] = useMutation(ME_UPDATE_LANG)
    const [updateTheme] = useMutation(ME_UPDATE_THEME)

    const onChangeLang = (e) => {
        const lang = e.target.value;
        setCurrentLang(lang);
        setLang(lang);
        updateLang({ variables: { lang } });
    }

    const onChangeTheme = (e) => {
        const theme = e.target.value;
        setCurrentTheme(theme);
        setCssTheme(theme);
        setTheme(theme)
        updateTheme({ variables: { theme } });
    }

    return (
        <>
            <div className="box">
                <h4 className="is-size-4">{t("pages:profil.theme.title")}</h4>
                <div className="control mt-3">
                <label className="radio mr-4">
                        <input
                            className="mr-1"
                            type="radio"
                            name="theme"
                            value={THEME_AUTO}
                            checked={currentTheme === THEME_AUTO}
                            onChange={onChangeTheme}
                        /> {t("pages:profil.theme.mode.auto")}
                    </label>

                    <label className="radio mr-4">
                        <input
                            className="mr-1"
                            type="radio"
                            name="theme"
                            value={THEME_LIGHT}
                            checked={currentTheme === THEME_LIGHT}
                            onChange={onChangeTheme}
                        /> {t("pages:profil.theme.mode.light")}
                    </label>

                    <label className="radio">
                        <input
                            className="mr-1"
                            type="radio"
                            name="theme"
                            value={THEME_DARK}
                            checked={currentTheme === THEME_DARK}
                            onChange={onChangeTheme}
                        /> {t("pages:profil.theme.mode.dark")}
                    </label>
                </div>
            </div>

            <div className="box">
                <h4 className="is-size-4">{t("pages:profil.lang.title")}</h4>
                <div className="control mt-3">
                    <label className="radio mr-4">
                        <input
                            className="mr-1"
                            type="radio"
                            name="lang"
                            value={LANG_FR}
                            checked={currentLang === LANG_FR}
                            onChange={onChangeLang}
                        /> {t("pages:profil.lang.mode.fr")}
                    </label>

                    <label className="radio">
                        <input
                            className="mr-1"
                            type="radio"
                            name="lang"
                            value={LANG_EN}
                            checked={currentLang === LANG_EN}
                            onChange={onChangeLang}
                        /> {t("pages:profil.lang.mode.en")}
                    </label>
                </div>
            </div>
        </>
    )
}

PageProfilContentApp.propTypes = {
    lang: PropTypes.string,
    theme: PropTypes.string
}

export default PageProfilContentApp;