export const getDayOfWeekShort = (t, day) => {
    return t('day_of_week_short.' + day)
}

export const getMonthOfYear = (t, month) => {
    return t('month_of_year.' + month)
}

export const getDayOfWeek = (dayNumber) => {
    const daysOfWeek = [
        'sun',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat'
    ]
    return daysOfWeek[dayNumber] ? daysOfWeek[dayNumber] : false
}