import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PlanningFilter from "./PlanningFilter";

function PlanningFilters({
    types,
    rooms,
    roomFilters,
    setRoomFilters,
    typeFilters,
    setTypeFilters,
}) {
    const { t } = useTranslation(["pages"]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const isHidden = filtersVisible ? '' : 'is-hidden'

    return (
        <>
            <div className={`tags mb-0 ${isHidden}`}>
                <PlanningFilter
                    items={types}
                    filters={typeFilters}
                    stateFunction={setTypeFilters}
                />

                <PlanningFilter
                    items={rooms}
                    filters={roomFilters}
                    stateFunction={setRoomFilters}
                />
            </div>
            <span className="tag is-info is-clickable" onClick={() => setFiltersVisible(prevState => !prevState)}>
              {filtersVisible ? t("pages:club.planning.filters.hide") : t("pages:club.planning.filters.show")}
            </span>
        </>
    );
}

PlanningFilters.propTypes = {
    types: PropTypes.arrayOf(PropTypes.any),
    rooms: PropTypes.arrayOf(PropTypes.any),
    roomFilters: PropTypes.arrayOf(PropTypes.number),
    setRoomFilters: PropTypes.func,
    typeFilters: PropTypes.arrayOf(PropTypes.number),
    setTypeFilters: PropTypes.func,
};

export default PlanningFilters;
