import { gql } from '@apollo/client';

export const UPDATE_CLUB_PICTURE = gql`
  mutation UpdateClubPicture(
    $id: Int!,
    $picture: TypeUpload
  ) {
    UpdateClubPicture(
      id: $id,
      picture: $picture
    ) 
  }
`;

export const REMOVE_CLUB_PICTURE = gql`
  mutation RemoveClubPicture($id: Int!) {
    RemoveClubPicture(id: $id) 
  }
`;