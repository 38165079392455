import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { Link } from 'react-router-dom'
import { getRoute } from 'utils/route'

import PageAdmin from 'structure/PageAdmin'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import PageAdminPackagesTable from './PageAdminPackagesTable'

import { GET_CLUB_ADMIN } from 'graphql/query/club'

const PageAdminPackages = () => {

    const { club_id } = useParams()

    const { loading, error, data } = useQuery(GET_CLUB_ADMIN, {
        variables: {
            id: club_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Club === null) {
        return (
            <LoadingError error={{ message: "Club not found" }} />
        )
    }

    return (
        <PageAdmin title={data.Club.name} isWide={true} activeMenu="package">
            <Link to={getRoute("admin_package_create", { club_id })} className={'button is-primary mb-3'}>
                Nouveau
            </Link>

            <PageAdminPackagesTable 
                workspaceId={data.Club.workspace_id}
            />
            
        </PageAdmin>
    )
}
export default PageAdminPackages