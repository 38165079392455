import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";

import SessionModalContent from "modal/SessionModal/SessionModalContent";

import { GET_SESSION } from "graphql/query/session";

const SessionModal = ({ uid, onClose }) => {
    if (!uid) {
        return null;
    }

    const [currentSession, setCurrentSession] = useState(null)

    useEffect(() => {
        if (uid) {
            getSession({
                variables: {
                    uid,
                },
            })
        }
    }, [uid])

    const [getSession] = useLazyQuery(GET_SESSION, {
        fetchPolicy: "network-only",
        onCompleted({ Session }) {
            setCurrentSession(Session);
        }
    })

    if (!currentSession) {
        return null;
    }
    
    return (
        <SessionModalContent
            onClose={onClose}
            session={currentSession}
        />
    );
};

SessionModal.propTypes = {
    uid: PropTypes.string,
    onClose: PropTypes.func,
};

export default SessionModal;
