import React from 'react'
import { useNavigate } from 'react-router';

import Page from 'structure/Page'
import ClubForm from 'forms/Club/ClubForm'

import { getRoute } from 'utils/route';

const PageClubCreation = () => {

    const navigate = useNavigate();

    const afterCreation = (club) => {
        navigate(getRoute("admin_club_home", { club_id: club.id }))
    }

    return (
        <Page title="Créer votre club">
            <ClubForm afterCreation={afterCreation} />
        </Page>
    )
}

export default PageClubCreation