import React from "react"
import PropTypes from 'prop-types'

const Nl2br = ({ children }) => {
    return (
        <div style={{ whiteSpace: 'pre-line' }}>
            {children}
        </div>
    )
}

Nl2br.propTypes = {
    children: PropTypes.any
}

export default Nl2br