import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
    mutation CreateCompany(
        $clubId: Int!, 
        $name: String!, 
        $address: String!, 
        $zipCode: String!,
        $city: String!, 
        $siret: String, 
        $legalForm: String,
        $capital: Float,
        $rcs: String,
        $tvaIntra: String,
        $cityRegistry: String
    ) {
        CreateCompany (
            club_id: $clubId, 
            name: $name, 
            address: $address, 
            zip_code: $zipCode,
            city: $city, 
            siret: $siret, 
            legal_form: $legalForm, 
            capital: $capital
            rcs: $rcs,
            tva_intra: $tvaIntra,
            city_registry: $cityRegistry
        ) {
            id
            club_id
            name
            address
            zip_code
            city
            siret
            legal_form
            capital
            rcs
            tva_intra
            city_registry
        }
    }
`


export const UPDATE_COMPANY = gql`
    mutation UpdateCompany(
        $id: Int!, 
        $name: String!, 
        $address: String!, 
        $zipCode: String!,
        $city: String!, 
        $siret: String, 
        $legalForm: String,
        $capital: Float,
        $rcs: String,
        $tvaIntra: String,
        $cityRegistry: String
    ) {
        UpdateCompany (
            id: $id, 
            name: $name, 
            address: $address, 
            zip_code: $zipCode,
            city: $city, 
            siret: $siret, 
            legal_form: $legalForm, 
            capital: $capital
            rcs: $rcs,
            tva_intra: $tvaIntra,
            city_registry: $cityRegistry
        ) {
            id
            club_id
            name
            address
            zip_code
            city
            siret
            legal_form
            capital
            rcs
            tva_intra
            city_registry
        }
    }
`