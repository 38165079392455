import React, { useState } from "react";
import PropTypes from 'prop-types'
import moment from "moment";

import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


import FormAlert from "components/form/FormAlert";
import ControlCheckbox from "components/form/ControlCheckbox";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlSelect from "components/form/ControlSelect";
import ControlDatePicker from "components/form/ControlDatePicker";
import FormSubmit from "components/form/FormSubmit";

import { useMutation } from "@apollo/client";
import { setSubmitButtonState } from "utils/form";
import { catchFormErrors } from "utils/error";

import { CREATE_PACKAGE_USER, UPDATE_PACKAGE_USER } from "graphql/mutation/package-user";

import getSchema from "./PackageUserForm.schema";

import { intOrNull, floatOrNull, floatOrZero } from "utils/str";

const STATUS_ACTIVE = "active";
const STATUS_DISABLED = "disabled";

const PAYMENT_FREQUENCY_UNIQUE = "unique"
const PAYMENT_FREQUENCY_MONTHLY = "monthly"
const PAYMENT_FREQUENCY_QUATERLY = "quaterly"
const PAYMENT_FREQUENCY_ANNUALY = "annualy"

const DEFAULT_STATUS = 'active';
const DEFAULT_TAX = 20;

const getTaxedAmount = (amount, taxPercentage) => {
    return (floatOrZero(amount) * (1 + (floatOrZero(taxPercentage) / 100))).toFixed(2)
}

const PackageUserForm = ({ workspaceId, userId, packageUserData, clubData, typeData }) => {

    const { t } = useTranslation(["translation", "errors", "forms"]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");
    const [currentPackageUser, setCurrentPackageUser] = useState(packageUserData);
    const [ruleIsUnlimited, setRuleIsUnlimited] = useState(packageUserData && packageUserData.rules && packageUserData.rules.unlimited ? packageUserData.rules.unlimited : false)
    const [activeTab, setActiveTab] = useState("infos")

    const [dateStart, setDateStart] = useState(packageUserData ? packageUserData.date_start : null)
    const [dateEnd, setDateEnd] = useState(packageUserData ? packageUserData.date_end : null)

    const [currentLimitationTypeIds, setCurrentLimitationTypeIds] = useState((packageUserData && packageUserData.limitations && packageUserData.limitations.type_ids) ? packageUserData.limitations.type_ids.join(',') : '')
    const [currentLimitationRoomIds, setCurrentLimitationRoomIds] = useState((packageUserData && packageUserData.limitations && packageUserData.limitations.room_ids) ? packageUserData.limitations.room_ids.join(',') : '')
    const [currentLimitationClubIds, setCurrentLimitationClubIds] = useState((packageUserData && packageUserData.limitations && packageUserData.limitations.club_ids) ? packageUserData.limitations.club_ids.join(',') : '')

    const schema = getSchema(t);

    const clubCount = clubData.length;
    const roomCount = clubData.reduce((acc, club) => acc + club.Rooms.length, 0)

    const updateLimitationIds = (e, id, currentStateValue, currentStateFn) => {
        let ids = currentStateValue == '' ? [] : currentStateValue.split(',');

        if (e.currentTarget.checked) {
            if (!ids.includes(id)) {
                ids.push(id)
            }
        } else {
            ids = ids.filter((item) => item != id)
        }

        const str = (ids.length == 1) ? String(ids[0]) : ids.join(',')

        currentStateFn(str);
    }

    const updateTaxedAmount = () => {
        const amount = getValues("amount");
        const taxPercentage = getValues("tax_percentage");
        setValue("taxed_amount", getTaxedAmount(amount, taxPercentage));
    }
    const updateRuleIsUnlimited = (isUnlimited) => {
        setRuleIsUnlimited(isUnlimited);
        if (isUnlimited) {
            setValue('rule_sessions', '')
        }
    }

    const [createPackageUser] = useMutation(CREATE_PACKAGE_USER, {
        onCompleted(data) {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
            setCurrentPackageUser(data.CreatePackageUser);
            // window.history.replaceState(null, 'Pack', `/admin/club/${currentClubId}/package/${data.CreatePackage.id}`)
        },
        onError(error) {
            setIsLoading(false);
            setSubmitButtonState(setHasSubmitError);
            catchFormErrors({
                t,
                errorPath: "package_user",
                defaultMessagePath: "errors:messages.form_has_errors",
                error,
                setError,
                setMessage,
            });
        },
    });

    const [updatePackageUser] = useMutation(UPDATE_PACKAGE_USER, {
        onCompleted() {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            setIsLoading(false);
            setSubmitButtonState(setHasSubmitError);
            catchFormErrors({
                t,
                errorPath: "package_user",
                defaultMessagePath: "errors:messages.form_has_errors",
                error,
                setError,
                setMessage,
            });
        },
    });

    const { handleSubmit, control, errors, clearErrors, setError, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = ({
        title,
        description,
        status,
        rule_unlimited,
        rule_sessions,
        rule_sessions_per_week,
        rule_sessions_per_month,
        rule_simultaneous_sessions,
        amount,
        tax_percentage,
        payment_frequency,
    }) => {
        setIsLoading(true);
        setMessage("");
        clearErrors();

        const limitationTypeIds = currentLimitationTypeIds != '' ? currentLimitationTypeIds.split(',') : null;
        const limitationRoomIds = currentLimitationRoomIds != '' ? currentLimitationRoomIds.split(',') : null;
        const limitationClubIds = currentLimitationClubIds != '' ? currentLimitationClubIds.split(',') : null;

        const taxed_amount = getTaxedAmount(amount, tax_percentage);

        const rules = {
            unlimited: !!rule_unlimited,
            sessions: intOrNull(rule_sessions),
            sessions_per_week: intOrNull(rule_sessions_per_week),
            sessions_per_month: intOrNull(rule_sessions_per_month),
            simultaneous_sessions: intOrNull(rule_simultaneous_sessions)
        }

        const limitations = {
            club_ids: limitationClubIds,
            room_ids: limitationRoomIds,
            type_ids: limitationTypeIds
        }

        if (currentPackageUser) {
            updatePackageUser({
                variables: {
                    id: currentPackageUser.id,
                    status,
                    title,
                    description,
                    rules,
                    limitations,
                    amount,
                    taxPercentage: floatOrNull(tax_percentage),
                    taxedAmount: floatOrNull(taxed_amount),
                    paymentFrequency: payment_frequency,
                    dateStart: dateStart,
                    dateEnd: dateEnd
                },
            });
        } else {
            createPackageUser({
                variables: {
                    workspaceId,
                    userId,
                    status,
                    title,
                    description,
                    rules,
                    limitations,
                    amount,
                    taxPercentage: floatOrNull(tax_percentage),
                    taxedAmount: floatOrNull(taxed_amount),
                    paymentFrequency: payment_frequency,
                    dateStart: dateStart,
                    dateEnd: dateEnd
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormAlert>{message}</FormAlert>

                <div className="tabs is-boxed is-medium">
                    <ul>
                        <li onClick={() => setActiveTab("infos")} className={activeTab == "infos" ? "is-active" : ""}>
                            <a>Infos</a>
                        </li>
                        <li onClick={() => setActiveTab("limitations")} className={activeTab == "limitations" ? "is-active" : ""}>
                            <a>Limitations</a>
                        </li>
                    </ul>
                </div>

                <div className="mb-3">
                    <div className={activeTab == "infos" ? "" : "is-hidden"}>
                        <Controller
                            control={control}
                            name="title"
                            defaultValue={packageUserData ? packageUserData.title : ""}
                            render={({ onChange, value }) => (
                                <ControlText
                                    onChange={onChange}
                                    type="text"
                                    value={value}
                                    error={errors.title?.message}
                                    placeholder={t("forms:package_user.fields.title.placeholder")}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="description"
                            defaultValue={packageUserData ? packageUserData.description : ""}
                            render={({ onChange, value }) => (
                                <ControlTextarea
                                    onChange={onChange}
                                    value={value}
                                    error={errors.description?.message}
                                    placeholder={t("forms:package_user.fields.description.placeholder")}
                                />
                            )}
                        />
                        <div className="columns">
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="status"
                                    defaultValue={packageUserData ? packageUserData?.status : DEFAULT_STATUS}
                                    render={({ onChange, value }) => (
                                        <ControlSelect
                                            onChange={onChange}
                                            error={errors.status?.message}
                                            value={value}
                                            values={[
                                                { label: t("forms:package_user.fields.status.values.active"), value: STATUS_ACTIVE },
                                                { label: t("forms:package_user.fields.status.values.disabled"), value: STATUS_DISABLED }
                                            ]}
                                            placeholder={t("forms:package_user.fields.status.placeholder")}
                                        />
                                    )}
                                />
                            </div>
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="payment_frequency"
                                    defaultValue={packageUserData ? packageUserData?.payment_frequency : ''}
                                    render={({ onChange, value }) => (
                                        <ControlSelect
                                            onChange={onChange}
                                            error={errors.payment_frequency?.message}
                                            value={value}
                                            values={[
                                                { label: t("forms:package_user.fields.payment_frequency.values.unique"), value: PAYMENT_FREQUENCY_UNIQUE },
                                                { label: t("forms:package_user.fields.payment_frequency.values.monthly"), value: PAYMENT_FREQUENCY_MONTHLY },
                                                { label: t("forms:package_user.fields.payment_frequency.values.quaterly"), value: PAYMENT_FREQUENCY_QUATERLY },
                                                { label: t("forms:package_user.fields.payment_frequency.values.annualy"), value: PAYMENT_FREQUENCY_ANNUALY },
                                            ]}
                                            placeholder={t("forms:package_user.fields.payment_frequency.placeholder")}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="date_start"
                                    defaultValue={packageUserData ? packageUserData?.date_start : ''}
                                    render={({ onChange, value }) => (
                                        <ControlDatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={value}
                                            error={errors.date_start?.message}
                                            placeholderText={t("forms:package_user.fields.date_start.placeholder")}
                                            onChange={(value) => {
                                                onChange(value);
                                                if (value !== null) {
                                                    setDateStart(moment(value).format('YYYY-MM-DD'));
                                                } else {
                                                    setDateStart(null);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="date_end"
                                    defaultValue={packageUserData ? packageUserData?.date_end : ''}
                                    render={({ onChange, value }) => (
                                        <ControlDatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={value}
                                            error={errors.date_end?.message}
                                            placeholderText={t("forms:package_user.fields.date_end.placeholder")}
                                            onChange={(value) => {
                                                onChange(value);
                                                if (value !== null) {
                                                    setDateEnd(moment(value).format('YYYY-MM-DD'));
                                                } else {
                                                    setDateEnd(null);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={packageUserData ? packageUserData?.amount : ''}
                                    render={({ onChange, value }) => (
                                        <ControlText
                                            onChange={(e) => { onChange(e); updateTaxedAmount() }}
                                            type="text"
                                            value={value}
                                            error={errors.amount?.message}
                                            rightAddons={t("forms:package_user.fields.amount.unit")}
                                            placeholder={t("forms:package_user.fields.amount.placeholder")}
                                        />
                                    )}
                                />
                            </div>
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="tax_percentage"
                                    defaultValue={packageUserData ? packageUserData?.tax_percentage : DEFAULT_TAX}
                                    render={({ onChange, value }) => (
                                        <ControlText
                                            onChange={(e) => { onChange(e); updateTaxedAmount() }}
                                            type="text"
                                            value={value}
                                            error={errors.tax_percentage?.message}
                                            rightAddons="%"
                                            placeholder={t("forms:package_user.fields.tax_percentage.placeholder")}
                                        />
                                    )}
                                />
                            </div>
                            <div className="column">
                                <Controller
                                    control={control}
                                    name="taxed_amount"
                                    defaultValue={packageUserData ? packageUserData?.taxed_amount : ''}
                                    render={({ onChange, value }) => (
                                        <ControlText
                                            disabled={true}
                                            onChange={onChange}
                                            type="text"
                                            value={value}
                                            error={errors.taxed_amount?.message}
                                            rightAddons={t("forms:package_user.fields.taxed_amount.unit")}
                                            placeholder={t("forms:package_user.fields.taxed_amount.placeholder")}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="box">
                            <h4 className="is-size-4 mb-4"><b>{t("forms:package_user.fields.rules.title")}</b></h4>
                            <div className="columns">
                                <div className="column">
                                    <Controller
                                        control={control}
                                        name="rule_unlimited"
                                        defaultValue={packageUserData ? packageUserData?.rules?.unlimited : ''}
                                        render={({ onChange, value }) => (
                                            <ControlCheckbox
                                                onChange={(e) => { onChange(e); updateRuleIsUnlimited(e) }}
                                                label={t("forms:package_user.fields.rules.unlimited.label")}
                                                error={errors.rule_unlimited?.message}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="column">
                                    <Controller
                                        control={control}
                                        name="rule_sessions"
                                        defaultValue={(packageUserData && packageUserData.rules && packageUserData.rules.sessions) ? packageUserData.rules.sessions : ''}
                                        render={({ onChange, value }) => (
                                            <ControlText
                                                disabled={ruleIsUnlimited}
                                                onChange={onChange}
                                                type="number"
                                                value={value}
                                                error={errors.rule_sessions?.message}
                                                label={t("forms:package_user.fields.rules.sessions.label")}
                                                tooltip="Nombre de sessions totales autorisées durant la durée de l'abonnement"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="column">
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <Controller
                                        control={control}
                                        name="rule_sessions_per_week"
                                        defaultValue={(packageUserData && packageUserData.rules && packageUserData.rules.sessions_per_week) ? packageUserData.rules.sessions_per_week : ''}
                                        render={({ onChange, value }) => (
                                            <ControlText
                                                onChange={onChange}
                                                type="number"
                                                value={value}
                                                error={errors.rule_sessions_per_week?.message}
                                                label={t("forms:package_user.fields.rules.sessions_per_week.label")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="column">
                                    <Controller
                                        control={control}
                                        name="rule_sessions_per_month"
                                        defaultValue={(packageUserData && packageUserData.rules && packageUserData.rules.sessions_per_month) ? packageUserData.rules.sessions_per_month : ''}
                                        render={({ onChange, value }) => (
                                            <ControlText
                                                onChange={onChange}
                                                type="number"
                                                value={value}
                                                error={errors.rule_sessions_per_month?.message}
                                                label={t("forms:package_user.fields.rules.sessions_per_month.label")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="column">
                                    <Controller
                                        control={control}
                                        name="rule_simultaneous_sessions"
                                        defaultValue={(packageUserData && packageUserData.rules && packageUserData.rules.simultaneous_sessions) ? packageUserData.rules.simultaneous_sessions : ''}
                                        render={({ onChange, value }) => (
                                            <ControlText
                                                onChange={onChange}
                                                type="number"
                                                value={value}
                                                error={errors.simultaneous_sessions?.message}
                                                label={t("forms:package_user.fields.rules.simultaneous_sessions.label")}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={activeTab == "limitations" ? "" : "is-hidden"}>
                        <div className="columns">
                            <div className="column">
                                <strong className="is-block mb-3">{t("forms:package.fields.limitations.types.label")}</strong>
                                {typeData.map((type) =>
                                    <label className="checkbox is-block mb-2" key={"label-type-" + type.id}>
                                        <input
                                            key={"checkbox-type-" + type.id}
                                            checked={currentLimitationTypeIds.includes(type.id)}
                                            onChange={(e) => updateLimitationIds(e, type.id, currentLimitationTypeIds, setCurrentLimitationTypeIds)}
                                            className="mr-1"
                                            type="checkbox"
                                        />
                                        {type.name}
                                    </label>
                                )}
                            </div>

                            {roomCount > 1 && <div className="column">
                                <strong className="is-block mb-3">{t("forms:package.fields.limitations.rooms.label")}</strong>
                                {clubData.map((club) =>
                                    club.Rooms.map((room) =>
                                        <label className="checkbox is-block mb-2" key={"label-room-" + room.id}>
                                            <input
                                                key={"checkbox-room-" + room.id}
                                                checked={currentLimitationRoomIds.includes(room.id)}
                                                onChange={(e) => updateLimitationIds(e, room.id, currentLimitationRoomIds, setCurrentLimitationRoomIds)}
                                                className="mr-1"
                                                type="checkbox"
                                            />
                                            {clubCount > 1 && (club.name + " - ")}{room.name}
                                        </label>
                                    )
                                )}
                            </div>}

                            {clubCount > 1 && <div className="column">
                                <strong className="is-block mb-3">{t("forms:package.fields.limitations.clubs.label")}</strong>
                                {clubData.map((club) =>
                                    <label className="checkbox is-block mb-2" key={"label-club-" + club.id}>
                                        <input
                                            key={'checkbox-club-' + club.id}
                                            checked={currentLimitationClubIds.includes(club.id)}
                                            onChange={(e) => updateLimitationIds(e, club.id, currentLimitationClubIds, setCurrentLimitationClubIds)}
                                            className="mr-1"
                                            type="checkbox"
                                        />
                                        {club.name}
                                    </label>
                                )}
                            </div>}
                        </div>
                    </div>
                </div>

                <FormSubmit
                    isLoading={isLoading}
                    isSubmitted={isSubmitted}
                    hasSubmitError={hasSubmitError}
                >
                    {t("forms:global.btn_save")}
                </FormSubmit>

            </form>
        </>
    )
}

PackageUserForm.propTypes = {
    workspaceId: PropTypes.number,
    userId: PropTypes.number,
    packageUserData: PropTypes.object,
    clubData: PropTypes.array,
    typeData: PropTypes.array,
    currentClubId: PropTypes.number
}

export default PackageUserForm