import React from 'react';
import PropTypes from 'prop-types';

const SessionModalContentCoach = ({ show, firstname, lastname}) => {
    if (!show) {
        return null;
    }
    return (
        <p className="is-size-6">
            {firstname} {lastname}
        </p>
    )

}

SessionModalContentCoach.propTypes = {
    show: PropTypes.bool,
    firstname: PropTypes.string,
    lastname: PropTypes.string
}

export default SessionModalContentCoach;