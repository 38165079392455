import React, { useState } from 'react'
import Page from 'structure/Page'
import SearchClubsInput from 'components/search-clubs/SearchClubsInput'
import SearchClubsResults from 'components/search-clubs/SearchClubsResults'


const PageOnboard = () => {

    const [query, setQuery] = useState(null)
    const [timestamp, setTimestamp] = useState(Date.now())

    const search = (q) => {
        setQuery(q)
        setTimestamp(Date.now())
    }

    return (
        <Page title="Bienvenue !">
            <div className="subtitle">
                Recherchez un club ci-dessous et rejoignez-le. Vous pourrez alors vous inscrire à aux séances
            </div>
            <SearchClubsInput callback={search} />
            <SearchClubsResults query={query} timestamp={timestamp} />
        </Page>
    )
}

export default PageOnboard;