import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { ME_DISABLE_EMAIL_REMINDER } from 'graphql/mutation/me';
import Switch from "components/form/Switch";

const PageProfilContentNotifications = ({ emailReminderDisabled }) => {
    const { t } = useTranslation()
    const [emailReminderIsDisabled, setEmailReminderIsDisabled] = useState(emailReminderDisabled)
    const [disableEmailReminder] = useMutation(ME_DISABLE_EMAIL_REMINDER, {
        onCompleted() {
            setEmailReminderIsDisabled(current => !current)
        }
    })

    const onChange = () => {
        disableEmailReminder({
            variables: {
                disable: !emailReminderIsDisabled
            }
        })
    }

    return (
        <div className="box">
            <h4 className="is-size-4">{t("pages:profil.notifications.title")}</h4>
            <Switch
                label={t("pages:profil.notifications.disable_email_reminder")}
                labelPosition="left"
                onChange={onChange}
                name="show_coach"
                size="medium"
                checked={emailReminderIsDisabled}
            />
        </div>
    )
}

PageProfilContentNotifications.propTypes = {
    emailReminderDisabled: PropTypes.bool
}

export default PageProfilContentNotifications;