import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18next from 'config/i18n';
import {I18nextProvider} from "react-i18next";
// GraphQl
import { ApolloProvider } from '@apollo/client'
import getApolloClient from 'config/apollo'
import App from 'App'

const appoloClient = getApolloClient();

ReactDOM.render(
  <Suspense fallback="">
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ApolloProvider client={appoloClient}>
          <App />
        </ApolloProvider>
      </I18nextProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
