import { gql } from "@apollo/client";

export const MEMBER_JOIN = gql`
mutation MemberJoin($clubId: Int!) {
    MemberJoin(club_id: $clubId)
}
`;

export const MEMBER_ACCEPT = gql`
mutation MemberAccept($id: Int!) {
    MemberAccept(id: $id) {
        id
        status
    }
}
`;

export const MEMBER_REFUSE = gql`
mutation MemberRefuse($id: Int!) {
    MemberRefuse(id: $id) {
        id
        status
    }
}
`;

export const MEMBER_PEND = gql`
mutation MemberPend($id: Int!) {
    MemberPend(id: $id) {
        id
        status
    }
}
`;

export const MEMBER_ADD = gql`
mutation MemberAdd($clubId: Int!, $userId: Int!) {
    MemberAdd(club_id: $clubId, user_id: $userId) {
        id
        status
        created_at
        User {
            firstname
            lastname
        }
    }
}
`;


export const MEMBER_CREATE = gql`
mutation MemberCreate($clubId: Int!, $firstname: String!, $lastname: String!, $email: String!) {
    MemberCreate(club_id: $clubId, firstname: $firstname, lastname: $lastname, email: $email) {
        id
        status
        created_at
        User {
            firstname
            lastname
        }
    }
}
`;

export const MEMBER_SET_COACH = gql`
    mutation MemberSetCoach($id: Int!) {
        MemberSetCoach(id: $id) {
            id
            roles
        }
    }
`;

export const MEMBER_REMOVE_COACH = gql`
    mutation MemberRemoveCoach($id: Int!) {
        MemberRemoveCoach(id: $id) {
            id
            roles
        }
    }
`;
