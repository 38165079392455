import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Table from 'components/Table';
import { processData } from 'utils/table'
import { MEMBER_ACCEPT, MEMBER_PEND, MEMBER_REFUSE } from 'graphql/mutation/member'
import { Link } from "react-router-dom";
import { MessagePopinContext } from 'context/MessagePopinContext';

import { getRoute } from "utils/route";
import { getPackageUserStatusClass } from 'utils/package';
import { isJson, intOrZero } from 'utils/str';

const PageAdminMembersTable = ({ club_id, members }) => {
    const { t } = useTranslation(['admin'])
    const [currentMembers, setCurrentMembers] = useState(members)

    const messagePopinContext = useContext(MessagePopinContext)

    const setMessagePopin = (type, path) => {
        messagePopinContext.dispatch({ 'type': type, 'message': t(path) })
    }

    useEffect(() => {
        setCurrentMembers(members);
    }, [members])

    const handleOnError = (response) => {
        if (response.graphQLErrors) {
            response.graphQLErrors.map(item => {
                if (isJson(item.message)) {
                    const error = JSON.parse(item.message);
                    setMessagePopin('danger', `member.errors.${error.message}`)
                } else {
                    setMessagePopin('danger', `member.errors.${item.message}`)
                }
            });
        }
    }

    const handleOnCompleted = (member) => {
        setCurrentMembers(c => c.map(currentMember => {
            if (currentMember.id == member.id) {
                currentMember.status = member.status
            }
            return currentMember;
        }))
    }

    const [acceptMember] = useMutation(MEMBER_ACCEPT, {
        onError(error) {
            handleOnError(error)
        },
        onCompleted({ MemberAccept }) {
            handleOnCompleted( MemberAccept)
        }
    });
    const [refuseMember] = useMutation(MEMBER_REFUSE, {
        onError(error) {
            handleOnError(error)
        },
        onCompleted({ MemberRefuse }) {
            handleOnCompleted( MemberRefuse)
        }
    });
    const [pendMember] = useMutation(MEMBER_PEND, {
        onError(error) {
            handleOnError(error)
        },
        onCompleted({ MemberPend }) {
            handleOnCompleted( MemberPend )
        }
    });

    const getMemberSessionsCount = (packageUser) => {
        if (!packageUser) {
            return '-';
        }
        if (packageUser.rules.unlimited === true) {
            return '-';
        }
        if (packageUser.rules.sessions !== null && packageUser.sessions_counter !== null) {
            return intOrZero(packageUser.rules.sessions) - intOrZero(packageUser.sessions_counter)
        }

        return '-';
    }
    const getMemberStatusClass = (memberStatus, requiredStatus) => {
        if (requiredStatus == 'refused' && memberStatus == requiredStatus) {
            return ' is-warning'
        }
        if (requiredStatus == 'accepted' && memberStatus == requiredStatus) {
            return ' is-success'
        }
        return '';
    }

    const isCoach = (roles) => {
        return roles.includes('"coach"')
    }
    const getCoachTag = (roles) => {
        if (!isCoach(roles)) {
            return null
        }
        return <span className="tag is-info ml-1">Coach</span>
    }
    const handleAcceptMember = (memberId, memberStatus) => {
        if (memberStatus == 'accepted') {
            pendMember({ variables: { id: memberId } })
        } else {
            acceptMember({ variables: { id: memberId } })
        }
    }
    const confirmRefuseMember = (memberId) => {
        if (!confirm(t("member.confirmation_refuse_member"))) {
            return;
        }
        refuseMember({
            variables: { id: memberId },
            onCompleted() {
                setCurrentMembers(c => c.filter(member => member.id != memberId))
            }
        })
    }

    return (
        <>
            <Table
                options={{
                    search: true,
                    toolbar: true,
                    sort: true
                }}
                columns={[
                    {
                        title: t('member.table.columns.firstname'),
                        field: 'User.firstname',
                        render: rowData =>
                            <Link
                                to={getRoute("admin_member", { club_id, member_id: rowData.id })}
                            >
                                {rowData.User?.firstname} {getCoachTag(rowData.roles)}
                            </Link>,
                        customFilterAndSearch: (term, rowData) => rowData.User.firstname ? rowData.User.firstname.toLowerCase().indexOf(term.toLowerCase()) != -1 : false

                    },
                    {
                        title: t('member.table.columns.lastname'),
                        field: 'User.lastname',
                        render: rowData =>
                            <Link
                                to={getRoute("admin_member", { club_id, member_id: rowData.id })}
                            >
                                {rowData.User?.lastname}
                            </Link>,
                        customFilterAndSearch: (term, rowData) => rowData.User.lastname ? rowData.User.lastname.toLowerCase().indexOf(term.toLowerCase()) != -1 : false
                    },
                    {
                        title: t('member.table.columns.package_user'),
                        field: 'PackageUserCurrent.title',
                        render: rowData => {
                            if (rowData.PackageUserCurrent !== null) {
                                return (
                                    <>
                                        <Link
                                            to={getRoute("admin_package_user", {
                                                club_id,
                                                member_id: rowData.id,
                                                package_user_id: rowData.PackageUserCurrent.id
                                            })}
                                        >
                                            <span className={"tag " + getPackageUserStatusClass(rowData?.PackageUserCurrent.status)}>{rowData?.PackageUserCurrent?.title}</span>
                                        </Link>
                                    </>
                                )
                            }
                        },
                        customFilterAndSearch: (term, rowData) => rowData?.PackageUserCurrent?.title ? rowData.PackageUserCurrent.title.toLowerCase().indexOf(term.toLowerCase()) != -1 : false

                    },
                    {
                        title: "Date de fin",
                        field: 'PackageUserCurrent.date_end',
                        render: rowData => {
                            if (rowData.PackageUserCurrent !== null) {
                                const now = moment();
                                const dateEnd = moment(rowData?.PackageUserCurrent.date_end, 'YYYY-MM-DD');
                                const diff = dateEnd.diff(now, 'days');

                                let diffClass = '';
                                if (diff < 0) {
                                    diffClass = 'has-background-danger p-1';
                                } else if (diff < 30) {
                                    diffClass = 'has-background-warning p-1';
                                }
                                return <span className={diffClass}>{rowData?.PackageUserCurrent.date_end}</span>
                            }
                        },

                    },                    
                    {
                        title: t('member.table.columns.remaining_sessions'),
                        sorting: false,
                        headerStyle: {
                            fontWeight: 700
                        },
                        render: rowData => getMemberSessionsCount(rowData.PackageUserCurrent),
                    },
                    {
                        title: t('member.table.columns.action'),
                        sorting: false,
                        headerStyle: {
                            fontWeight: 700,
                            textAlign: "right",
                        },
                        render: rowData =>
                            <>
                                <button
                                    className={"button is-small is-pulled-right m-1" + getMemberStatusClass(rowData.status, "refused")}
                                    onClick={() => confirmRefuseMember(rowData.id)}>
                                    {t('member.table.columns.action_refuse')}
                                </button>
                                <button
                                    className={"button is-small is-pulled-right m-1" + getMemberStatusClass(rowData.status, "accepted")}
                                    onClick={() => handleAcceptMember(rowData.id, rowData.status)}>
                                    {t('member.table.columns.action_accept')}
                                </button>
                            </>
                    }
                ]}
                data={processData(currentMembers)}
            />
        </>
    )
}

PageAdminMembersTable.propTypes = {
    club_id: PropTypes.number,
    members: PropTypes.array
}

export default PageAdminMembersTable