import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Nl2br from 'components/Nl2br'
import config from 'config/app'
import { getRoute } from 'utils/route';

const SearchClubResultItem = ({ club }) => {

    return (
        <Link className="box" to={getRoute("club", { slug: club.slug })}>
            <div className="columns">
                <div className="column">
                    <strong className="is-link">{club.name}</strong>
                    <Nl2br>{club.address}</Nl2br>
                </div>
                <div className="column is-hidden-mobile">
                    <img className="image is-64x64 is-pulled-right" src={config.api_url + '/' + club.picture} />
                </div>
            </div>
        </Link>
    )
}

SearchClubResultItem.propTypes = {
    club: PropTypes.object
}

export default SearchClubResultItem;