import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import config from 'config/app'
import { UPDATE_ROOM_PICTURE, REMOVE_ROOM_PICTURE } from 'graphql/mutation/room-picture'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import FormAlert from 'components/form/FormAlert'

const RoomPicture = ({ room, picture }) => {

    const [currentPicture, setCurrentPicture] = useState(picture)
    const [dropdownActive, setDropdownActive] = useState(false)
    const [message, setMessage] = useState('')

    const fileInputRef = React.createRef()

    const { t } = useTranslation();

    const handlePictureRemove = () => {
        setMessage('')
        setDropdownActive(false)
        removeRoomPicture({
            variables: {
                id: room.id
            }
        })
    }

    const handlePictureChange = (e) => {
        setMessage('')
        setDropdownActive(false)
        updateRoomPicture({
            variables: {
                id: room.id,
                picture: e.target.files[0]
            }
        })
    }

    // remove mutation
    const [removeRoomPicture] = useMutation(REMOVE_ROOM_PICTURE, {
        onCompleted({ RemoveRoomPicture }) {
            setCurrentPicture(RemoveRoomPicture);
        }
    })

    // update mutation
    const [updateRoomPicture] = useMutation(UPDATE_ROOM_PICTURE, {
        context: {
            hasUpload: true
        },
        onCompleted({ UpdateRoomPicture }) {
            setCurrentPicture(UpdateRoomPicture);
        }
    });

    return (
        <Fragment>
            <div className="box">
                <div className="field">
                    <figure id="room-picture" className="image is-256x256">

                        <input ref={fileInputRef} className="file-input" type="file" name="resume" onChange={(e) => { handlePictureChange(e) }} />

                        {currentPicture && <img
                            className="room-picture"
                            src={config.api_url + '/' + currentPicture}
                        />}

                        <div id="dropdown-room-picture" className={'dropdown' + (dropdownActive ? ' is-active' : '')}>
                            <div className="dropdown-trigger" onClick={() => setDropdownActive(!dropdownActive)}>
                                <span className="tag is-info">
                                    <FontAwesomeIcon size="xs" icon={faPen} />
                                </span>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a className="dropdown-item" onClick={() => fileInputRef.current.click()} >
                                        {t('forms:global.picture.menu.edit')}
                                    </a>
                                    <a className="dropdown-item" onClick={() => handlePictureRemove()} >
                                        {t('forms:global.picture.menu.remove')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>

                <FormAlert>{message}</FormAlert>
            </div>
        </Fragment>

    )
}

RoomPicture.propTypes = {
    room: PropTypes.object,
    picture: PropTypes.string
}

export default RoomPicture