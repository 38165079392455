import React from "react";
import { useParams } from "react-router";
import { useTranslation } from 'react-i18next'

import PageAdmin from "structure/PageAdmin";
import RoomForm from "forms/Room/RoomForm";

import Back from "components/Back";

import { getRoute } from "utils/route";

const PageAdminRoomCreate = () => {
  const { club_id } = useParams();

  const { t } = useTranslation(['admin'])


  return (
    <PageAdmin title={t("admin:room_create.title")} metaTitle={t("admin:room_create.meta.title")} activeMenu="room">
      <Back to={getRoute("admin_rooms", { club_id })} />
      <RoomForm  clubId={parseInt(club_id)} />
    </PageAdmin>
  );
};

export default PageAdminRoomCreate;
