import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import {
  faUser,
  faEnvelope,
  faLock,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { catchFormErrors } from "utils/error";
import PasswordChecker from "components/PasswordChecker";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";

import FormPanel from "components/form/FormPanel";
import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlSelect from "components/form/ControlSelect";
import FormSubmit from "components/form/FormSubmit";
import FormLinks from "components/form/FormLinks";

import { routes } from "routes";
import { setSubmitButtonState } from "utils/form";
import { getDefaultTimezone } from "utils/time";
import getSchema from "./SignupForm.schema";
import { SIGNUP } from "graphql/mutation/signup";

const SignupForm = ({ timezones }) => {
  const currentTimezone = getDefaultTimezone();

  const { t } = useTranslation(["translation", "errors", "forms"]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [message, setMessage] = useState("");

  const schema = getSchema(t);

  const [signupUser] = useMutation(SIGNUP, {
    onCompleted() {
      setIsLoading(false);
      setIsSubmitted(true);
    },
    onError(error) {
      setIsLoading(false);
      setSubmitButtonState(setHasSubmitError);
      catchFormErrors({
        t,
        errorPath: "signup",
        defaultMessagePath: "errors:messages.form_has_errors",
        error,
        setError,
        setMessage,
      });
    },
  });

  const { handleSubmit, control, errors, clearErrors, setError, watch } =
    useForm({
      resolver: yupResolver(schema),
    });

  const onSubmit = ({
    email,
    firstname,
    lastname,
    timezone,
    password,
  }) => {
    setIsLoading(true);
    setMessage("");
    clearErrors();

    signupUser({
      variables: {
        email,
        firstname,
        lastname,
        timezone,
        password,
      },
      context: {
        isAnonymous: true,
      },
    });
  };
  const onError = () => {
    setMessage(t("errors:messages.form_has_errors"));
    setSubmitButtonState(setHasSubmitError);
  };

  const watchPassword = watch("password");
  const watchPasswordConfirmation = watch("password_confirmation");

  const form = () => (
    <FormPanel title="%forms:signup.title%">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormAlert>{message}</FormAlert>

        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faEnvelope}
              error={errors.email?.message}
              type="email"
              value={value}
              placeholder={t("forms:signup.fields.email.placeholder")}
              autoFocus={true}
            />
          )}
        />

        <Controller
          control={control}
          name="firstname"
          defaultValue=""
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faUser}
              error={errors.firstname?.message}
              type="text"
              value={value}
              placeholder={t("forms:signup.fields.firstname.placeholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="lastname"
          defaultValue=""
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faUser}
              error={errors.lastname?.message}
              type="text"
              value={value}
              placeholder={t("forms:signup.fields.lastname.placeholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="timezone"
          defaultValue={currentTimezone}
          render={({ onChange, value }) => (
            <ControlSelect
              onChange={onChange}
              icon={faClock}
              error={errors.timezone?.message}
              value={value}
              values={timezones}
              placeholder={t("forms:signup.fields.timezone.placeholder")}
            />
          )}
        />

        <hr />

        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faLock}
              error={errors.password?.message}
              type="password"
              value={value}
              placeholder={t("forms:signup.fields.password.placeholder")}
            />
          )}
        />

        <PasswordChecker password={watchPassword ?? ""} />

        <Controller
          control={control}
          name="password_confirmation"
          defaultValue=""
          render={({ onChange, value }) => (
            <ControlText
              onChange={onChange}
              icon={faLock}
              error={errors.password_confirmation?.message}
              type="password"
              value={value}
              placeholder={t(
                "forms:signup.fields.password_confirmation.placeholder"
              )}
            />
          )}
        />

        <PasswordChecker password={watchPasswordConfirmation ?? ""} />

        <FormSubmit
          isSubmitted={isSubmitted}
          isLoading={isLoading}
          hasSubmitError={hasSubmitError}
        >
          {t("forms:global.btn_save")}
        </FormSubmit>

        <FormLinks
          link1={routes.signin}
          txt1="%forms:signup.signin_link%"
          link2={routes.password_forgot}
          txt2="%forms:signup.forgot_password_link%"
        />
      </form>
    </FormPanel>
  );

  const redirect = () => <Navigate to={routes.signup_done} />;

  return isSubmitted ? redirect() : form();
};

SignupForm.propTypes = {
  timezones: PropTypes.arrayOf(String),
};

export default SignupForm;
