import moment from "moment";

export function getDateSessions(currentDate, sessions) {
  return sessions[currentDate] ? sessions[currentDate] : null
}

function checkPropertyFilters(session, property, filters) {
  if (!Array.isArray(filters) || filters.length <= 0) {
    return true;
  }
  if (!filters.includes(session[property])) {
    return false;
  }

  return true;
}

function checkFilters(session, typeFilters, roomFilters) {
  return (
    checkPropertyFilters(session, "type_id", typeFilters) &&
    checkPropertyFilters(session, "room_id", roomFilters)
  );
}

export function structureSessions(data, typeFilters, roomFilters) {
  const sessions = {};

  data.forEach((session) => {
    if (checkFilters(session, typeFilters, roomFilters)) {
      const startDate = moment(session.start_datetime).format("YYYY-MM-DD");

      if (!sessions[startDate]) {
        sessions[startDate] = [];
      }

      const clusterIndex = getClusterIndex(
        session.start_datetime,
        session.end_datetime,
        sessions[startDate]
      );

      if (clusterIndex === false) {
        sessions[startDate].push([session]);
      } else {
        sessions[startDate][clusterIndex].push(session);
      }
    }
  });

  const sortedSessions = sortClusterSessions(sessions);

  return sortedSessions;
}

function sortClusterSessions(sessions) {
  Object.keys(sessions).forEach((key) => {
    sessions[key].map((cluster) => {
      cluster.sort((a, b) => {
        let type_position_a = a.type_position ? a.type_position : 1000;
        let type_position_b = b.type_position ? b.type_position : 1000;
        return type_position_a > type_position_b ? 1 : -1;
      })
    })
  });

  return sessions;
}

function getClusterIndex(startDatetime, endDatetime, sessions) {
  if (sessions.length <= 0) {
    return false;
  }
  const startNum = convertTimeToNum(startDatetime);
  const endNum = convertTimeToNum(endDatetime);

  var clusterIndex = false;
  sessions.forEach((cluster, index) => {
    cluster.forEach((session) => {
      const sessionStartNum = convertTimeToNum(session.start_datetime);
      const sessionEndNum = convertTimeToNum(session.end_datetime);
      if (
        (startNum >= sessionStartNum && startNum < sessionEndNum) ||
        (endNum >= sessionStartNum && endNum < sessionEndNum)
      ) {
        clusterIndex = index;
      }
    });
  });
  return clusterIndex;
}

function convertTimeToNum(datetime) {
  const hours = moment(datetime, "YYYY-MM-DD HH:mm:ss").format("H");
  const minutes = moment(datetime, "YYYY-MM-DD HH:mm:ss").format("m");
  return parseInt(hours) * (15 * 4) + parseInt(minutes);
}
