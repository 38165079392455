import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import FormSubmit from "components/form/FormSubmit";

import { useMutation } from "@apollo/client";
import { setSubmitButtonState } from "utils/form";
import { catchFormErrors } from "utils/error";
import { getRoute } from 'utils/route';

import RoomPicture from "components/room/RoomPicture";
import { CREATE_ROOM, UPDATE_ROOM, DELETE_ROOM } from "graphql/mutation/room";
import getSchema from "./RoomForm.schema";

const RoomForm = ({ room, clubId }) => {
  const { t } = useTranslation(["translation", "errors", "forms"]);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [message, setMessage] = useState("");
  const [currentRoom, setCurrentRoom] = useState(room);

  const schema = getSchema(t);

  const [createRoom] = useMutation(CREATE_ROOM, {
    onCompleted(data) {
      setIsLoading(false);
      setSubmitButtonState(setIsSubmitted);
      setCurrentRoom(data.CreateRoom);
    },
    onError(error) {
      setIsLoading(false);
      setSubmitButtonState(setHasSubmitError);
      catchFormErrors({
        t,
        errorPath: "room",
        defaultMessagePath: "errors:messages.form_has_errors",
        error,
        setError,
        setMessage,
      });
    },
  });

  const [updateRoom] = useMutation(UPDATE_ROOM, {
    onCompleted() {
      setIsLoading(false);
      setSubmitButtonState(setIsSubmitted);
    },
    onError(error) {
      setIsLoading(false);
      setSubmitButtonState(setHasSubmitError);
      catchFormErrors({
        t,
        errorPath: "room",
        defaultMessagePath: "errors:messages.form_has_errors",
        error,
        setError,
        setMessage,
      });
    },
  });

  const [deleteRoom] = useMutation(DELETE_ROOM, {
    onCompleted: () => {
      navigate(getRoute("admin_rooms", { club_id: clubId }));
    },
  });
  
  const { handleSubmit, control, errors, clearErrors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ name, description }) => {
    setIsLoading(true);
    setMessage("");
    clearErrors();

    if (currentRoom) {
      updateRoom({
        variables: {
          id: currentRoom.id,
          name,
          description,
        },
      });
    } else {
      createRoom({
        variables: {
          clubId,
          name,
          description,
        },
      });
    }
  };
  const onError = () => {
    setMessage(t("errors:messages.form_has_errors"));
    setSubmitButtonState(setHasSubmitError);
  };

  const onDelete = () => {
    if (confirm(t("forms:room.messages.confirm_delete"))) {
      deleteRoom({
        variables: { id: currentRoom.id }
      })
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <FormAlert>{message}</FormAlert>

      {currentRoom && (
        <RoomPicture room={currentRoom} picture={currentRoom?.picture} />
      )}

      <Controller
        control={control}
        name="name"
        defaultValue={room ? room.name : ""}
        render={({ onChange, value }) => (
          <ControlText
            onChange={onChange}
            type="text"
            value={value}
            error={errors.name?.message}
            placeholder={t("forms:room.fields.name.placeholder")}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        defaultValue={room ? room.description : ""}
        render={({ onChange, value }) => (
          <ControlTextarea
            onChange={onChange}
            value={value}
            error={errors.description?.message}
            placeholder={t("forms:room.fields.description.placeholder")}
          />
        )}
      />

      <div className="columns">
        <div className="column is-three-quarters">
          <FormSubmit
            isLoading={isLoading}
            isSubmitted={isSubmitted}
            hasSubmitError={hasSubmitError}
          >
            {t("forms:global.btn_save")}
          </FormSubmit>
        </div>
        <div className="column">
          {currentRoom && <button onClick={() => onDelete()} type="button" className="button is-danger is-medium is-fullwidth">{t("forms:global.btn_delete")}</button>}
        </div>
      </div>
    </form>
  );
};

RoomForm.defaultProps = {
  room: null,
  clubId: null,
};

RoomForm.propTypes = {
  room: PropTypes.object,
  clubId: PropTypes.number,
};

export default RoomForm;
