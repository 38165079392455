import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import FormAlert from "components/form/FormAlert";
import FormSubmit from "components/form/FormSubmit";

import ConfigFormBoxVisibility from "./ConfigFormBoxVisibility";
import ConfigFormBoxRegistration from "./ConfigFormBoxRegistration";

import { setSubmitButtonState } from "utils/form";
import { yupResolver } from "@hookform/resolvers/yup";

import { catchFormErrors } from "utils/error";
import getSchema from "./ConfigForm.schema";

import { SET_CONFIG } from "graphql/mutation/config";
import ConfigFormBoxModeration from "./ConfigFormBoxModeration";
import ConfigFormBoxPlanningForm from "./ConfigFormBoxPlanningForm";

const ConfigForm = ({ config, clubId }) => {
    const { t } = useTranslation(["translation", "errors", "forms"]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");

    const schema = getSchema(t);

    const [setConfig] = useMutation(SET_CONFIG, {
        onCompleted() {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            setIsLoading(false);
            setSubmitButtonState(setHasSubmitError);
            catchFormErrors({
                t,
                errorPath: "config",
                defaultMessagePath: "errors:messages.form_has_errors",
                error,
                setError,
                setMessage,
            });
        },
    });

    const { handleSubmit, control, errors, clearErrors, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = ({
        planning_allowed_visibility,
        planning_allowed_registration,
        planning_visibility_day_of_week,
        member_join_policy,
        default_max_registrations,
        cancel_registration_hours_delay,
        show_coach,
    }) => {
        setIsLoading(true);
        setMessage("");
        clearErrors();

        const vars = {
            planning_allowed_visibility,
            planning_allowed_registration,
            planning_visibility_day_of_week,
            member_join_policy,
            default_max_registrations,
            cancel_registration_hours_delay,
            show_coach
        };

        setConfig({
            variables: {
                clubId,
                config: JSON.stringify(vars),
            },
        });
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <FormAlert>{message}</FormAlert>

            <div className="box">
                <h3 className="is-size-3 mb-3">{t("forms:config.box.visibility")}</h3>
                <ConfigFormBoxVisibility
                    config={config}
                    control={control}
                    errors={errors}
                />
            </div>

            <div className="box">
                <h3 className="is-size-3 mb-3">{t("forms:config.box.registrations")}</h3>
                <ConfigFormBoxRegistration
                    config={config}
                    control={control}
                    errors={errors}
                />
            </div>

            <div className="box">
                <h3 className="is-size-3 mb-3">{t("forms:config.box.moderation")}</h3>
                <ConfigFormBoxModeration
                    config={config}
                    control={control}
                    errors={errors}
                />
            </div>

            <div className="box">
                <h3 className="is-size-3 mb-3">{t("forms:config.box.planning")}</h3>
                <ConfigFormBoxPlanningForm
                    config={config}
                    control={control}
                    errors={errors}
                />
            </div>

            <FormSubmit
                isLoading={isLoading}
                isSubmitted={isSubmitted}
                hasSubmitError={hasSubmitError}
            >
                {t("forms:global.btn_save")}
            </FormSubmit>
        </form>
    );
};

ConfigForm.propTypes = {
    clubId: PropTypes.string,
    config: PropTypes.object,
};

export default ConfigForm;
