import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form";

import { faLock } from '@fortawesome/free-solid-svg-icons'

import { catchFormErrors } from 'utils/error'

import FormAlert from 'components/form/FormAlert'
import ControlText from 'components/form/ControlText'
import FormSubmit from 'components/form/FormSubmit'
import PasswordChecker from 'components/PasswordChecker'

import { setSubmitButtonState } from 'utils/form'
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client'
import { PASSWORD_RESET } from 'graphql/mutation/password-reset'

import getSchema from './PasswordResetForm.schema';

const PasswordResetForm = ({ uid, hash, token, callback }) => {

    const { t } = useTranslation(['translation', 'errors', 'forms'])

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [message, setMessage] = useState('')

    const schema = getSchema(t)

    const [passwordReset] = useMutation(PASSWORD_RESET, {
        onCompleted(response) {
            setIsSubmitted(true);
            setSubmitButtonState(setIsSubmitted)
            callback(response)
        },
        onError(error) {
            setIsLoading(false)
            setSubmitButtonState(setHasSubmitError)
            catchFormErrors({
                t,
                errorPath: 'password_reset',
                defaultMessagePath: 'errors:messages.form_has_errors',
                error,
                setError,
                setMessage
            })
        }
    })

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError,
        watch,
    } = useForm({
        resolver: yupResolver(schema)
    });

    const watchPassword = watch('password')
    const watchPasswordConfirmation = watch('password_confirmation')

    const onSubmit = ({ password }) => {
        setIsLoading(true)
        setMessage('')
        clearErrors()

        passwordReset({
            variables: {
                uid,
                hash,
                token,
                password
            },
            context: {
                isAnonymous: true
            }
        })
    }

    const onError = () => {
        setMessage(t('errors:messages.form_has_errors'))
        setSubmitButtonState(setHasSubmitError)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <FormAlert>{message}</FormAlert>

            <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        icon={faLock}
                        error={errors.password?.message}
                        type="password"
                        value={value}
                        placeholder={t('forms:password_reset.fields.password.placeholder')}
                        autoFocus={true}
                    />
                )}
            />

            <PasswordChecker password={watchPassword ?? ''} />

            <Controller
                control={control}
                name="password_confirmation"
                defaultValue=""
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        icon={faLock}
                        error={errors.password_confirmation?.message}
                        type="password"
                        value={value}
                        placeholder={t('forms:password_reset.fields.password_confirmation.placeholder')}
                    />
                )}
            />

            <PasswordChecker password={watchPasswordConfirmation ?? ''} />

            <FormSubmit isLoading={isLoading} isSubmitted={isSubmitted} hasSubmitError={hasSubmitError}>{t('forms:global.btn_save')}</FormSubmit>

        </form>
    )
}

PasswordResetForm.propTypes = {
    uid: PropTypes.string,
    token: PropTypes.string,
    hash: PropTypes.string,
    callback: PropTypes.func
};

// export default withTranslation()(LoginForm)
export default PasswordResetForm