import React, { useState } from "react";
import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlSelect from "components/form/ControlSelect";
import ControlDatePicker from "components/form/ControlDatePicker";
import PropTypes from "prop-types";
import moment from "moment";

import { Controller } from "react-hook-form";

import { getTimeRange } from 'utils/time';
import { getControlSelectDefaultValue } from 'utils/form'

import PlanningFormFieldWeeklyRepeatAt from "./PlanningFormFieldWeeklyRepeatAt";
import PlanningFormFieldMonthlyRepeatAt from "./PlanningFormFieldMonthlyRepeatAt";

const PlanningFormFields = ({
    data,
    config,
    formData,
    message,
    errors,
    control,
    t,
    register,
    setValue,
    getValues
}) => {

    const [repeatMode, setRepeatMode] = useState(
        data ? data.repeat_mode : ""
    );

    const [startDate, setStartDate] = useState(
        data ? data.start_date : null
    );

    const coaches = formData.Coaches.map((coach) => ({
        value: coach.id,
        label: `${coach.firstname} ${coach.lastname}`,
    }));

    const types = formData.Types.map((type) => ({
        value: type.id,
        label: type.name,
    }));

    const rooms = formData.Rooms.map((room) => ({
        value: room.id,
        label: room.name,
    }));

    const updatePlanningEndTime = (planningStartTimeValue) => {
        if (getValues('end_time') === '') {
            const planningEndTimeValue = moment(planningStartTimeValue, ['HH:mm']);
            planningEndTimeValue.add(1, 'hour');
            setValue("end_time", planningEndTimeValue.format('HH:mm'));
        }
    }

    return (
        <>
            <FormAlert>{message}</FormAlert>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="room_id"
                        defaultValue={data?.room_id ?? getControlSelectDefaultValue(rooms)}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                value={value}
                                values={rooms}
                                error={errors.room_id?.message}
                                label={t("forms:planning.fields.room.label")}
                                placeholder={t("forms:planning.fields.room.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="type_id"
                        defaultValue={data?.type_id ?? getControlSelectDefaultValue(types)}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                value={value}
                                values={types}
                                error={errors.type_id?.message}
                                label={t("forms:planning.fields.type.label")}
                                placeholder={t("forms:planning.fields.type.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            {config?.show_coach &&
                <div className="mb-5">
                    <Controller
                        control={control}
                        name="user_id"
                        defaultValue={data?.user_id ?? (config?.show_coach ? getControlSelectDefaultValue(coaches) : null)}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                value={value}
                                values={coaches}
                                error={errors.user_id?.message}
                                label={t("forms:planning.fields.user.label")}
                                placeholder={t("forms:planning.fields.user.placeholder")}
                            />
                        )}
                    />
                </div>}

            <Controller
                control={control}
                name="message"
                defaultValue={data?.message ?? ""}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        rows={2}
                        error={errors.message?.message}
                        label={t("forms:planning.fields.message.label")}
                        placeholder={t("forms:planning.fields.message.placeholder")}
                    />
                )}
            />

            <div className="columns">
                <div className="column is-half">
                    <Controller
                        control={control}
                        name="max_registrations"
                        defaultValue={data?.max_registrations ?? config.default_max_registrations}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.max_registrations?.message}
                                label={t("forms:planning.fields.max_registrations.label")}
                                placeholder={t(
                                    "forms:planning.fields.max_registrations.placeholder"
                                )}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="start_date"
                        defaultValue={data?.start_date ?? null}
                        render={({ onChange, value }) => (
                            <ControlDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={value}
                                error={errors.start_date?.message}
                                label={t("forms:planning.fields.start_date.label")}
                                placeholderText={t(
                                    "forms:planning.fields.start_date.placeholder"
                                )}
                                onChange={(value) => {
                                    onChange(value);
                                    setStartDate(moment(value).format('YYYY-MM-DD'));
                                }}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="end_date"
                        defaultValue={data?.end_date ?? ""}
                        render={({ onChange, value }) => (
                            <ControlDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={value}
                                error={errors.end_date?.message}
                                label={t("forms:planning.fields.end_date.label")}
                                placeholderText={t(
                                    "forms:planning.fields.end_date.placeholder"
                                )}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="start_time"
                        defaultValue={data?.start_time ?? ""}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                name="start_time"
                                register={register}
                                onChange={(e) => {
                                    updatePlanningEndTime(e.target.value)
                                    onChange(e)
                                }}
                                value={value}
                                values={getTimeRange()}
                                error={errors.start_time?.message}
                                label={t("forms:planning.fields.start_time.label")}
                                placeholder={t(
                                    "forms:planning.fields.start_time.placeholder"
                                )}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="end_time"
                        defaultValue={data?.end_time ?? ""}
                        render={({ onChange, value }) => {
                            return (
                                <ControlSelect
                                    name="end_time"
                                    register={register}
                                    onChange={onChange}
                                    value={value}
                                    values={getTimeRange()}
                                    error={errors.end_time?.message}
                                    label={t("forms:planning.fields.end_time.label")}
                                    placeholder={t(
                                        "forms:planning.fields.end_time.placeholder"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column is-two-fifths">{t("forms:planning.fields.repeat_frequency.label")}</div>

                <div className="column is-one-fifths">
                    <Controller
                        control={control}
                        name="repeat_frequency"
                        defaultValue={data?.repeat_frequency ?? 1}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="number"
                                value={value}
                                error={errors.repeat_frequency?.message}
                            />
                        )}
                    />
                </div>

                <div className="column is-two-fifths">
                    <Controller
                        control={control}
                        name="repeat_mode"
                        defaultValue={data?.repeat_mode ?? ""}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    setRepeatMode(e.target.value);
                                }}
                                value={value}
                                values={[
                                    { value: "daily", label: t("forms:planning.fields.repeat_mode.daily") },
                                    { value: "weekly", label: t("forms:planning.fields.repeat_mode.weekly") },
                                    { value: "monthly", label: t("forms:planning.fields.repeat_mode.monthly") },
                                    { value: "annualy", label: t("forms:planning.fields.repeat_mode.annualy") },
                                ]}
                                error={errors.repeat_mode?.message}
                                placeholder={t(
                                    "forms:planning.fields.repeat_mode.placeholder"
                                )}
                            />
                        )}
                    />
                </div>
            </div>

            {repeatMode == "weekly" && (
                <div className="field">
                    <div className="label">{t("forms:planning.fields.repeat_at.label")}</div>
                    <div className="block">
                        <Controller
                            control={control}
                            name="repeat_at_weekly"
                            defaultValue={data?.repeat_at ?? ""}
                            render={({ onChange, value }) => (
                                <PlanningFormFieldWeeklyRepeatAt
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                </div>
            )}

            {repeatMode == "monthly" && (
                <div className="field">
                    <div className="label">{t("forms:planning.fields.repeat_at.label")}</div>
                    <div className="block">
                        <Controller
                            control={control}
                            name="repeat_at_monthly"
                            defaultValue={data?.repeat_at ?? ""}
                            render={({ onChange, value }) => (
                                <PlanningFormFieldMonthlyRepeatAt
                                    onChange={onChange}
                                    value={value}
                                    date={startDate}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

PlanningFormFields.propTypes = {
    data: PropTypes.object,
    config: PropTypes.object,
    formData: PropTypes.object,
    message: PropTypes.string,
    errors: PropTypes.object,
    control: PropTypes.any,
    register: PropTypes.any,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    t: PropTypes.any,
};

export default PlanningFormFields;
