import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client'
import { SIGNUP_VALIDATE } from 'graphql/mutation/signup'

import Page from 'structure/Page'

import { Navigate } from 'react-router-dom'
import { routes } from 'routes'
import { useParams } from 'react-router';

const PageSignupValidate = () => {

    const { t } = useTranslation(['translation', 'pages']);

    const { uid, hash } = useParams()

    const [pageTitle, setPageTitle] = useState(t('pages:global.loading'))
    const [pageBody, setPageBody] = useState(t('pages:global.loading'))
    const [isValidated, setIsValidated] = useState(false)

    const [validateSignup] = useMutation(SIGNUP_VALIDATE, {
        onCompleted() {
            setIsValidated(true)
        }, onError() {
            setPageTitle(t('pages:signup_validate.title_error'))
            setPageBody(t('pages:signup_validate.body_error'))
        }
    })

    useEffect(() => {
        validateSignup({
            variables: {
                uid,
                hash
            }
        })
    }, [])

    return (isValidated ?
        <Navigate to={routes.signup_validate_done} />
        :
        <Page title={pageTitle} metaTitle={t("pages:signup_validate.meta.title")}>{pageBody}</Page>
    )
}

export default PageSignupValidate