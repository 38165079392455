import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import { getRoute } from 'utils/route';

import PageAdmin from 'structure/PageAdmin';
import CompanyForm from 'forms/Company/CompanyForm';

import Loading from "components/loading/Loading";
import LoadingError from "components/loading/LoadingError";
import Back from 'components/Back';

import { GET_COMPANY } from 'graphql/query/company';

const PageAdminCompany = () => {

    const { t } = useTranslation(["pages"])
    const { club_id: clubId } = useParams();

    const { loading, error, data } = useQuery(GET_COMPANY, {
        variables: {
            clubId,
        },
    });

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <LoadingError error={{ message: "Error while loading" }} />;
    }
    return (
        <PageAdmin title={t("pages:company.title")}>
            <Back to={getRoute("admin_club_home", { club_id:clubId })} />
            <h2 className="title">{data.Club.name}</h2>
            <CompanyForm
                company={data?.Club?.Company}
            />
        </PageAdmin>
    )

}


export default PageAdminCompany;