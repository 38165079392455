import { gql } from "@apollo/client";

export const CREATE_ROOM = gql`
  mutation CreateRoom(
    $clubId: Int!
    $name: String!
    $description: String!
  ) {
    CreateRoom(
      club_id: $clubId
      name: $name
      description: $description
    ) {
      id
      name
      description
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom(
    $id: Int!
    $name: String!
    $description: String
  ) {
    UpdateRoom(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation DeleteRoom($id: Int!) {
    DeleteRoom(id: $id)
  }
`;
