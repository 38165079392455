import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Page from 'structure/Page'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import SessionModal from "modal/SessionModal/SessionModal";
import Modal from "modal/Modal";

import { GET_MY_SESSIONS } from 'graphql/query/session'

import { SessionTableHeaderRow, SessionTableRow } from './SessionTableRow'

const PageMySessions = () => {

    const { t } = useTranslation(['pages', 'translation'])
    const [uid, setUid] = useState(null);
    const [modalIsActive, setModalIsActive] = useState(false);

    var { loading, error, data, refetch } = useQuery(GET_MY_SESSIONS, {
        fetchPolicy: "network-only",
    })

    if (loading) return <Loading />
    if (error) return <LoadingError error={error} />

    const { MySessions: sessions } = data;

    const onClick = (session) => {
        setUid(session.uid);
        setModalIsActive(true);
    };

    const onModalClose = (hasBeenUpdated) => {
        hasBeenUpdated && refetch();
        setModalIsActive(false);
    }

    return (
        <Page isWide={true} metaTitle={t("pages:my_sessions.meta.title")} title={t("pages:my_sessions.title")}>

            <Modal open={modalIsActive} isModalContent={true}>
                <SessionModal
                    uid={uid}
                    onClose={onModalClose}
                />
            </Modal>

            <table className="table is-striped is-fullwidth is-hoverable">
                <thead>
                    <SessionTableHeaderRow />
                </thead>
                <tbody>
                    {sessions.map((session, key) => {
                        return <SessionTableRow 
                                    key={key} 
                                    session={session} 
                                    onClick={onClick}
                                />
                    })}
                </tbody>
            </table>
        </Page>
    )
}

export default PageMySessions