import { gql } from '@apollo/client'

export const GET_CLUB_MEMBER = gql`
    query Club($id: Int) {
        Club(id: $id) {
            id
            Member {
                id
                status
            }
        }
    }
`

export const GET_CLUB = gql`
    query Club($id: Int, $slug: String, $url: String) {
        Club(id: $id, slug: $slug, url: $url) {
            id
            slug
            name
            address
            url
            picture
            timezone
            Member {
                id
                status
            }
            Rooms {
                id
                name
                description
                picture
            }
        }
    }  
`;


export const GET_CLUB_ADMIN = gql`
    query Club($id: Int, $slug: String, $url: String) {
        Club(id: $id, slug: $slug, url: $url) {
            id
            workspace_id
            slug
            name
            member_status
            address
            url
            picture
            Types {
                id
                club_id
                name
                description
                color
            }
            Rooms {
                id
                name
                description
                picture
            }
        }
    }  
`;

export const GET_CLUBS_USER = gql`
    query ClubsUser {
        ClubsUser {
            id
            slug
            name
            address
            url
            picture
        }
    }  
`;


export const GET_CLUBS_OWNED = gql`
    query ClubsOwned {
        ClubsOwned {
            id
            slug
            name
            address
            url
            picture
        }
    }  
`;


export const GET_CLUBS_OWNED_ADMIN = gql`
    query ClubsOwned {
        ClubsOwned {
            id
            workspace_id
            slug
            name
            address
            url
            picture
            Types {
                id
                club_id
                name
                description
                color
            }
            Rooms {
                id
                name
            }            
        }
    }  
`;

export const SEARCH_CLUBS = gql`
    query SearchClubs($q: String!) {
        SearchClubs(q: $q) {
            id
            slug
            name
            address
            picture
        }
    }  
`;

