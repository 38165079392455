import React from 'react'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Back = ({ to, label, icon }) => {

    const { t } = useTranslation()

    return (
        <Link to={to} className="button is-small mb-3">
            {icon && <span className="icon">
                <FontAwesomeIcon icon={icon} />
            </span>}
            <span>{label ?? t("global.back")}</span>
        </Link>
    )
}

Back.defaultProps = {
    icon: faArrowLeft
}
Back.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any
}

export default Back;