import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/*
Ensure values are only those present within daysOfWeeks->value 
*/
const filterAllowedDaysOfWeekValues = (values, daysOfWeeks) => {
  if (!values) {
    return [];
  }
  return values
    .split(",")
    .filter(
      (item1) => daysOfWeeks.filter((item2) => item1 == item2.value).length > 0
    );
};

const PlanningFormFieldWeeklyRepeatAt = ({ onChange, value }) => {
  const { t } = useTranslation();

  const daysOfWeeks = [
    { label: t("day_of_week_short.1").toUpperCase(), value: "mon" },
    { label: t("day_of_week_short.2").toUpperCase(), value: "tue" },
    { label: t("day_of_week_short.3").toUpperCase(), value: "wed" },
    { label: t("day_of_week_short.4").toUpperCase(), value: "thu" },
    { label: t("day_of_week_short.5").toUpperCase(), value: "fri" },
    { label: t("day_of_week_short.6").toUpperCase(), value: "sat" },
    { label: t("day_of_week_short.0").toUpperCase(), value: "sun" },
  ];

  const [selectedDays, setSelectedDays] = useState(
    filterAllowedDaysOfWeekValues(value, daysOfWeeks)
  );

  useEffect(() => {
    onChange(selectedDays.join(",")); 
  }, [selectedDays])

  const handleOnClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prevState) => prevState.filter((item) => item != day));
    } else {
      setSelectedDays((prevState) => [...prevState, day]);
    }    
  };

  const getDayClass = (day) =>
    selectedDays.includes(day) ? "is-primary" : "is-light";

  return (
    <>
      {daysOfWeeks.map((item, index) => (
        <span
          key={index}
          onClick={() => { handleOnClick(item.value) }}
          className={"tag is-clickable mr-2 " + getDayClass(item.value)}
        >
          {item.label}
        </span>
      ))}
    </>
  );
};

PlanningFormFieldWeeklyRepeatAt.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default PlanningFormFieldWeeklyRepeatAt;
