import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Controller } from "react-hook-form";

import { getTimeRange } from "utils/time";
import { getControlSelectDefaultValue } from "utils/form";

import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlSelect from "components/form/ControlSelect";
import ControlDatePicker from "components/form/ControlDatePicker";

const explodeDatetime = (startDatetimeStr, endDatetimeStr) => {
    let startDatetime = moment(startDatetimeStr, "YYYY-MM-DD HH:mm:ss");
    let endDatetime = moment(endDatetimeStr, "YYYY-MM-DD HH:mm:ss");

    let sessionDate = moment(startDatetime).format("YYYY-MM-DD");
    let sessionStartTime = moment(startDatetime).format("HH:mm");
    let sessionEndTime = moment(endDatetime).format("HH:mm");

    return [sessionDate, sessionStartTime, sessionEndTime];
};

const SessionFormFields = ({
    data,
    config,
    message,
    errors,
    control,
    t,
    formData,
    register,
    setValue,
    getValues
}) => {

    const [sessionDate, sessionStartTime, sessionEndTime] = data
        ? explodeDatetime(data.start_datetime, data.end_datetime)
        : [null, null, null];

    const coaches = formData.Coaches.map((coach) => ({
        value: coach.id,
        label: `${coach.firstname} ${coach.lastname}`,
    }));

    const rooms = formData.Rooms.map((room) => ({
        value: room.id,
        label: room.name,
    }));

    const types = formData.Types.map((type) => ({
        value: type.id,
        label: type.name,
    }));

    const updateSessionEndTime = (sessionStartTimeValue) => {
        if (getValues('session_end_time') === '') {
            const sessionEndTimeValue = moment(sessionStartTimeValue, ['HH:mm']);
            sessionEndTimeValue.add(1, 'hour');
            setValue("session_end_time", sessionEndTimeValue.format('HH:mm'));
        }
    }

    return (
        <>
            <FormAlert>{message}</FormAlert>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="room_id"
                        defaultValue={data?.room_id ?? getControlSelectDefaultValue(rooms)}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                value={value}
                                values={rooms}
                                error={errors.room_id?.message}
                                label={t("forms:session.fields.room.label")}
                                placeholder={t("forms:session.fields.room.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="type_id"
                        defaultValue={data?.type_id ?? getControlSelectDefaultValue(types)}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                value={value}
                                values={types}
                                error={errors.type_id?.message}
                                label={t("forms:session.fields.type.label")}
                                placeholder={t("forms:session.fields.type.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="mb-5">
                <Controller
                    control={control}
                    name="user_id"
                    defaultValue={data?.user_id ?? getControlSelectDefaultValue(coaches)}
                    render={({ onChange, value }) => (
                        <ControlSelect
                            onChange={onChange}
                            value={value}
                            values={coaches}
                            error={errors.user_id?.message}
                            label={t("forms:session.fields.user.label")}
                            placeholder={t("forms:session.fields.user.placeholder")}
                        />
                    )}
                />
            </div>

            <Controller
                control={control}
                name="message"
                defaultValue={data?.message ?? ""}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        rows={2}
                        error={errors.message?.message}
                        label={t("forms:session.fields.message.label")}
                        placeholder={t("forms:session.fields.message.placeholder")}
                    />
                )}
            />

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="max_registrations"
                        defaultValue={data?.max_registrations ?? config.default_max_registrations}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.max_registrations?.message}
                                label={t("forms:session.fields.max_registrations.label")}
                                placeholder={t(
                                    "forms:session.fields.max_registrations.placeholder"
                                )}
                            />
                        )}
                    />
                </div>

                <div className="column">
                    <Controller
                        control={control}
                        name="session_date"
                        defaultValue={sessionDate}
                        render={({ onChange, value }) => (
                            <ControlDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={value}
                                error={errors.session_date?.message}
                                label={t("forms:session.fields.session_date.label")}
                                placeholderText={t(
                                    "forms:session.fields.session_date.placeholder"
                                )}
                                onChange={(value) => {
                                    onChange(value);
                                }}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="session_start_time"
                        defaultValue={sessionStartTime}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                register={register}
                                name="session_start_time"
                                onChange={(e) => {
                                    updateSessionEndTime(e.target.value)
                                    onChange(e);
                                }}
                                value={value}
                                values={getTimeRange()}
                                error={errors.session_start_time?.message}
                                label={t("forms:session.fields.session_start_time.label")}
                                placeholder={t(
                                    "forms:session.fields.session_start_time.placeholder"
                                )}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="session_end_time"
                        defaultValue={sessionEndTime}
                        render={({ onChange, value }) => {
                            return (
                                <ControlSelect
                                    register={register}
                                    name="session_end_time"
                                    onChange={onChange}
                                    value={value}
                                    values={getTimeRange()}
                                    error={errors.session_end_time?.message}
                                    label={t("forms:session.fields.session_end_time.label")}
                                    placeholder={t(
                                        "forms:session.fields.session_end_time.placeholder"
                                    )}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
};

SessionFormFields.propTypes = {
    data: PropTypes.object,
    config: PropTypes.object,
    formData: PropTypes.object,
    message: PropTypes.string,
    errors: PropTypes.object,
    control: PropTypes.any,
    register: PropTypes.any,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    t: PropTypes.any,
};

export default SessionFormFields;
