import React from "react";
import moment from "moment";
import { getDateSessions } from "./planning.utils";
import PlanningColumn from "./PlanningColumn";

export const PlanningColumns = ({ columns, sessions, onClick }) => (
    columns.map((column, key) => {
        const isToday = column.date.isSame(moment().format("YYYY-MM-DD"));
        const columnSessions = getDateSessions(column.date.format("YYYY-MM-DD"), sessions)

        return (
            <PlanningColumn
                key={key}
                isToday={isToday}
                sessions={columnSessions}
                onClick={onClick}
            />
        )
    })
)