import { gql } from "@apollo/client";

export const CREATE_PACKAGE_USER = gql`
    mutation CreatePackageUser(
        $workspaceId: Int!,
        $userId: Int!
        $status: String!, 
        $title: String!, 
        $description: String,
        $rules: PackageRulesInput!, 
        $limitations: PackageLimitationsInput!, 
        $amount: Float!,
        $taxPercentage: Float!
        $taxedAmount: Float!
        $paymentFrequency: String!
        $dateStart: DateType!	
        $dateEnd: DateType
    ) {
        CreatePackageUser (
        workspace_id: $workspaceId, 
            user_id: $userId,
        status: $status, 
        title: $title, 
        description: $description,
        rules: $rules, 
        limitations: $limitations, 
        amount: $amount, 
            tax_percentage: $taxPercentage
        taxed_amount: $taxedAmount,
            payment_frequency: $paymentFrequency,
            date_start: $dateStart
            date_end: $dateEnd
        ) {
        id
            workspace_id
            user_id
        status
        title
        description
        rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
        limitations {
                club_ids
                type_ids
                room_ids
            }
        amount
        tax_percentage
        taxed_amount
        payment_frequency
            date_start
            date_end
        }
    }
`

export const UPDATE_PACKAGE_USER = gql`
    mutation UpdatePackageUser(
        $id: Int!, 
        $status: String!, 
        $title: String!, 
        $description: String,
        $rules: PackageRulesInput!, 
        $limitations: PackageLimitationsInput!, 
        $amount: Float!,
        $taxPercentage: Float!
        $taxedAmount: Float!
        $paymentFrequency: String!
        $dateStart: DateType!
        $dateEnd: DateType
    ) {
        UpdatePackageUser (
        id: $id, 
        status: $status, 
        title: $title, 
        description: $description,
        rules: $rules, 
        limitations: $limitations, 
        amount: $amount, 
            tax_percentage: $taxPercentage
        taxed_amount: $taxedAmount,
            payment_frequency: $paymentFrequency,
            date_start: $dateStart,
            date_end: $dateEnd
        ) {
        id
            workspace_id
        status
        title
        description
        rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
        limitations {
                club_ids
                type_ids
                room_ids
            }
        amount
        tax_percentage
        taxed_amount
        payment_frequency
            date_start
            date_end
        }
    }
`;

export const ADD_PACKAGE_TO_USER = gql`
    mutation AddPackageToUser(
        $packageId: Int!,
        $userId: Int!
    ) {
        AddPackageToUser (
        package_id: $packageId,
            user_id: $userId
        ) {
        id
            workspace_id
            user_id
        status
        title
        description
        rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
        limitations {
                club_ids
                type_ids
                room_ids
            }
        amount
        tax_percentage
        taxed_amount
        payment_frequency
            date_start
            date_end
        }
    }
`

export const DELETE_PACKAGE_USER = gql`
    mutation DeletePackageUser($id: Int!) {
        DeletePackageUser(id: $id) 
    }
`