import React from 'react'

const HOURS_PER_DAY = 24;

export const PlanningColumnHours = () => (
    <div className="column is-narrow">
    {[...Array(HOURS_PER_DAY).keys()].map((hour, index) => {
      const hourStr = hour > 0 ? hour + ":00" : "";
      return (
        <div className="planning-row-hours" key={index}>
          <span>{hourStr}</span>
        </div>
      );
    })}
  </div>
)
