import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { MEMBER_SET_COACH, MEMBER_REMOVE_COACH } from 'graphql/mutation/member'

const MemberCoachButton = ({ isCoach, memberId }) => {

    const { t } = useTranslation(["components"])

    const [isCoachLoading, setIsCoachLoading] = useState(false);
    const [isCoachCurrent, setIsCoachCurrent] = useState(isCoach)
    const [isHover, setIsHover] = useState(false)

    const [memberSetCoach] = useMutation(MEMBER_SET_COACH, {
        onCompleted() {
            setIsCoachLoading(false)
            setIsCoachCurrent(true)
        }
    })
    const [memberRemoveCoach] = useMutation(MEMBER_REMOVE_COACH, {
        onCompleted() {
            setIsCoachLoading(false)
            setIsCoachCurrent(false)
        }
    })

    const getBtnClass = (isCoach, isLoading) => {
        const classes = ["button", "is-info"]
        if (!isCoach) {
            classes.push("is-outlined")
        }
        if (isLoading) {
            classes.push("is-loading")
        }

        return classes.join(" ")
    }
    const getBtnLabel = (isCoach, isHover) => {
        if (isCoach) {
            if (isHover) {
                return t("member_coach_button.label.is_coach.is_hovered")
            } else {
                return t("member_coach_button.label.is_coach.is_not_hovered")
            }
        } else {
            return t("member_coach_button.label.is_not_coach")
        }
    }

    const toggleCoach = () => {
        const args = {
            variables: {
                id: memberId
            }
        }
        setIsCoachLoading(true)
        if (isCoachCurrent) {
            memberRemoveCoach(args)
        } else {
            memberSetCoach(args)
        }
    }

    return (
        <button
            onClick={(e) => { toggleCoach(); e.currentTarget.blur() }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            type="button"
            style={{"minWidth": "250px"}}
            className={getBtnClass(isCoachCurrent, isCoachLoading)}>{getBtnLabel(isCoach, isHover)}</button>
    )
}

MemberCoachButton.propTypes = {
    isCoach: PropTypes.bool,
    memberId: PropTypes.number
}

export default MemberCoachButton