import React from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export const SessionTableRow = ({ session, onClick }) => {

    const { t } = useTranslation(['pages', 'translation'])

    const now = moment();
    const isIncoming = now.diff(moment(session.start_datetime)) <= 0;

    return (
        <tr>
            <td onClick={() => onClick(session)}>
                <div className={"tag is-medium is-hidden-mobile is-clickable " + (isIncoming ? "is-info" : "is-light")}>{t("translation:day_of_week_short." + moment(session.start_datetime).day())} - {moment(session.start_datetime).format("DD/MM/YYYY")}</div>
                <div className={"tag is-normal is-hidden-desktop is-clickable " + (isIncoming ? "is-info" : "is-light")}>{t("translation:day_of_week_short." + moment(session.start_datetime).day())} - {moment(session.start_datetime).format("DD/MM/YYYY")}</div>
            </td>
            <td>
                <div className="is-pulled-left mr-2">{moment(session.start_datetime).format("HH:mm")} - {moment(session.end_datetime).format("HH:mm")}</div>
            </td>
            <td className="is-hidden-mobile">
                {session.type_name}
            </td>
            <td className="is-hidden-mobile">
                {session.club_name}
            </td>
            <td className="is-hidden-mobile">
                {session.counter_registrations} / {session.max_registrations}
            </td>
        </tr>
    )
}

SessionTableRow.propTypes = {
    session: PropTypes.object,
    onClick: PropTypes.func
}



export const SessionTableHeaderRow = () => {

    const { t } = useTranslation(['pages'])

    return (
        <tr>
            <th>
                {t("pages:my_sessions.table.columns.date")}
            </th>
            <th>
                {t("pages:my_sessions.table.columns.time")}
            </th>
            <th className="is-hidden-mobile">
                {t("pages:my_sessions.table.columns.type")}
            </th>
            <th className="is-hidden-mobile">
                {t("pages:my_sessions.table.columns.club")}
            </th>
            <th className="is-hidden-mobile">
                {t("pages:my_sessions.table.columns.members")}
            </th>

        </tr>
    )
}