import React from 'react'
import { useParams } from 'react-router'

import Page from 'structure/Page';
import SessionModal from 'modal/SessionModal/SessionModal'
import Back from 'components/Back';
import { getRoute } from 'utils/route';

const PageSession = () => {

    const { uid } = useParams();

    return (
        <Page metaTitle="Session">
        <Back to={getRoute("clubs")} />

        <SessionModal
            uid={uid}
          />

        </Page>
    )
}

export default PageSession