import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";

import PageAdmin from "structure/PageAdmin";

import Loading from "components/loading/Loading";
import Switch from "components/form/Switch";

import NewMemberModal from "modal/newMemberModal/NewMemberModal";

import { GET_MEMBERS } from "graphql/query/member";

import PageAdminMembersTable from "./PageAdminMembersTable";

const PageAdminMembers = () => {
    const { club_id } = useParams();

    const { t } = useTranslation(["admin"]);

    const [isLoading, setIsLoading] = useState(true);
    const [addMemberModalIsActive, setAddMemberModalIsActive] = useState(false);
    const [membersList, setMembersList] = useState(false);
    const [membersListFiltered, setMembersListFiltered] = useState(false);
    const [clubName, setClubName] = useState("");
    const [showRefusedMembers, setShowRefusedMembers] = useState(false)

    const openAddMemberModal = () => {
        setAddMemberModalIsActive(true);
    };

    const closeAddMemberModal = () => {
        setAddMemberModalIsActive(false);
    };

    useEffect(() => {
        setIsLoading(true);
        getMembers({
            variables: {
                clubId: parseInt(club_id),
            },
        });
    }, [club_id]);

    useEffect(() => {
        if (membersList !== false) {
            setMembersListFiltered(filterMembers(membersList, {showRefusedMembers}));
        }
    }, [showRefusedMembers])

    const filterMembers = (members, filters) => {
        if (filters.showRefusedMembers === true) {
            return members.filter((member) => member.status === "refused")
        }
        return members.filter((member) => member.status !== "refused")
    }

    const [getMembers] = useLazyQuery(GET_MEMBERS, {
        onCompleted(data) {
            setMembersList(data.Members);
            setClubName(data.Club.name)
            setMembersListFiltered(filterMembers(data.Members, {showRefusedMembers}));
            setIsLoading(false);
        },
    });

    const onChangeSwitchShowRefused = (value) => {
        setShowRefusedMembers(value)
    };

    if (isLoading) {
        return <Loading />
    }

    
    return membersListFiltered !== false && (
        <PageAdmin title={clubName} activeMenu="member" isWide={true}>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <button
                            className="button is-primary mb-3"
                            onClick={() => openAddMemberModal()}
                        >
                            {t("member.add_member")}
                        </button>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item pb-3">
                        <Switch
                            onChange={onChangeSwitchShowRefused}
                            name="show_refused"
                            size="medium"
                            checked={false}
                            label={t("member.show_refused_members")}
                            labelPosition="left"
                        />
                    </div>
                </div>
            </div>

            <NewMemberModal
                isActive={addMemberModalIsActive}
                onClose={closeAddMemberModal}
                clubId={parseInt(club_id)}
            />

            <PageAdminMembersTable
                club_id={parseInt(club_id)}
                members={membersListFiltered}
            />
        </PageAdmin>
    );
};

export default PageAdminMembers;
