import React from "react";
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client'

import { getRoute } from 'utils/route'

import PageAdmin from 'structure/PageAdmin'

import Back from 'components/Back';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import PackageUserForm from "forms/PackageUser/PackageUserForm";

import { GET_PACKAGE_USER_ADMIN } from "graphql/query/package-user";

const PageAdminPackageUser = () => {

    const { club_id, member_id, package_user_id } = useParams()

    const { loading, error, data } = useQuery(GET_PACKAGE_USER_ADMIN, {
        variables: {
            id: package_user_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.PackageUser === null) {
        return (
            <LoadingError error={{ message: "PackageUser not found" }} />
        )
    }    

    const packageUser = data.PackageUser;

    return (
        <PageAdmin>
            <Back to={getRoute("admin_member", { club_id, member_id })} />
            <PackageUserForm
                workspaceId={packageUser.workspace_id}
                userId={packageUser.user_id}
                packageUserData={packageUser}
                clubData={data.ClubsOwned}
                typeData={data.TypesOwned}
            />
        </PageAdmin>
    )
}

export default PageAdminPackageUser