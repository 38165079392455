import React from 'react'
import { useTranslation } from 'react-i18next'
import Page from 'structure/Page'

const Page404 = () => {
    const { t } = useTranslation(["pages"])
    return (
        <Page>
            <div className="title-404">😵 404</div>
            <div className="content-404">{t("pages:404.content")}</div>
        </Page>
    )
}

export default Page404;