import React from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom'

import PageAdmin from 'structure/PageAdmin'

import RoomCard from 'components/room/RoomCard'
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { getRoute } from 'utils/route';

import { GET_CLUB_ADMIN } from 'graphql/query/club'

const PageAdminRooms = () => {

    const { t } = useTranslation(['admin'])
    const { club_id } = useParams()

    const { loading, error, data } = useQuery(GET_CLUB_ADMIN, {
        fetchPolicy: "no-cache",
        variables: {
            id: club_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Club === null) {
        return (
            <LoadingError error={{ message: "Club not found" }} />
        )
    }

    return (
        <PageAdmin title={data.Club.name} activeMenu="room" isWide={true}>
            <Link to={getRoute("admin_room_create", { club_id})} className={'button is-primary mb-3'}>
                {t("admin:rooms.btn_create")}
            </Link>
            <div className="is-clearfix"></div>
            {data.Club.Rooms.map((room, index) => (
                <RoomCard key={index} room={room} club={data.Club} adminMode={true} />
            ))}
            <div className="is-clearfix"></div>
        </PageAdmin>
    )
}

export default PageAdminRooms