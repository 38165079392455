import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Nl2br from 'components/Nl2br'
import { getPicture } from 'utils/picture'
import { getRoute } from 'utils/route'

function RoomCard({ room, club, isHosted, adminMode }) {

    let link = ''
    if (!adminMode) {
        link = isHosted ? "/room/" + room.slug : "/club/" + club.slug + '/' + room.slug
    } else {
        link = getRoute("admin_room_edit", { club_id: club.id, room_id: room.id })
    }

    return (
        <div className="card room-card">
            <div className="card-content">
                <Link to={link}>
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-96x96">
                                <img src={getPicture(room.picture)} />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">{room.name}</p>
                            <Nl2br>{room.description}</Nl2br>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

RoomCard.defaultProps = {
    adminMode: false,
    isHosted: false
}

RoomCard.propTypes = {
    club: PropTypes.object,
    room: PropTypes.object,
    isHosted: PropTypes.bool,
    adminMode: PropTypes.bool
}
export default RoomCard
