import React from 'react';

import ContentCenter from 'structure/ContentCenter'
import SignupForm from 'forms/Signup/SignupForm'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { GET_TIMEZONES } from 'graphql/query/timezones'

const PageSignup = () => {

    const { t } = useTranslation('pages')

    const { loading, error, data } = useQuery(GET_TIMEZONES);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Timezones === null) {
        return (
            <LoadingError error={{ message: "Error while loading timezones" }} />
        )
    }

    return (
        <>
            <Helmet>
                <title>{t("pages:signup.meta.title")}</title>
            </Helmet>
            <ContentCenter>
                <SignupForm timezones={data.Timezones} />
            </ContentCenter>
        </>
    )
}

export default PageSignup
