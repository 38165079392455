import { gql } from '@apollo/client'


export const GET_ROOM = gql`
    query Room($id: Int) {
        Room(id: $id) {
            id
            name
            description
            picture
        }
    }  
`;
