import React, { useEffect, useContext } from "react";
import { useSwipeable } from "react-swipeable";
import PropTypes from "prop-types";
import { PlanningColumnHours } from "./PlanningColumnHours";
import { PlanningColumns } from "./PlanningColumns";

import { PlanningContext } from 'context/PlanningContext'

function PlanningBody({
    columns,
    sessions,
    planningBodyHeight,
    onClick,
    reference,
    gotoNext,
    gotoPrevious

}) {
    useEffect(() => {
        setTimeout(() => reference.current.scrollTo({ top: 300 }), 300);
    }, []);

    const style = planningBodyHeight ? { height: planningBodyHeight } : {}

    const { setIsSwipedLeft, setIsSwipedRight } = useContext(PlanningContext);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            gotoNext && gotoNext()
            setIsSwipedLeft(true);
        },
        onSwipedRight: () => {
            gotoPrevious && gotoPrevious()
            setIsSwipedRight(true);
        },
    });

    return (
        <div
            ref={reference}
            className="planning-body"
            style={style}
        >
            <div {...handlers} className="columns is-gapless is-mobile">
                <PlanningColumnHours />

                <PlanningColumns
                    columns={columns}
                    sessions={sessions}
                    onClick={onClick}
                />
            </div>
        </div>
    );
}

PlanningBody.propTypes = {
    planningBodyHeight: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    sessions: PropTypes.object,
    onClick: PropTypes.func,
    gotoPrevious: PropTypes.func,
    gotoNext: PropTypes.func,
    reference: PropTypes.any,
};

export default PlanningBody;
