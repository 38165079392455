import React from 'react'

import { useParams } from 'react-router'

import Back from 'components/Back'

import PageAdmin from 'structure/PageAdmin'
import TypeForm from 'forms/Type/TypeForm'

import { getRoute } from 'utils/route'

const PageAdminTypeCreate = () => {

    const { club_id } = useParams()

    return (
        <PageAdmin activeMenu="type">
            <Back to={getRoute("admin_types", { club_id })} />
            <TypeForm
                clubId={parseInt(club_id)}
            />
        </PageAdmin>
    )
}

export default PageAdminTypeCreate