import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router";

import PageAdmin from "structure/PageAdmin";

import Loading from "components/loading/Loading";
import LoadingError from "components/loading/LoadingError";
import PlanningFormModal from "modal/PlanningFormModal";
import SessionFormModal from "modal/SessionFormModal";
import PlanningOrSessionCreateModal from "modal/PlanningOrSessionCreateModal";
import PlanningOrSessionEditModal from "modal/PlanningOrSessionEditModal";
import Modal from "modal/Modal";

import { GET_SESSIONS_ADMIN } from "graphql/query/session";
import Planning from "components/planning/Planning";
import moment from "moment";

const isUniqueSession = (session) => session.uid.includes('_session_')

const PageAdminPlannings = () => {
    const today = moment();
    const currentDateStart = today.format("YYYY-MM-DD");
    const currentDateEnd = today.add(60, "days").format("YYYY-MM-DD");

    const { t } = useTranslation(["admin"]);

    const [dateStart] = useState(currentDateStart);
    const [dateEnd] = useState(currentDateEnd);

    const [selectedPlanningId, setSelectedPlanningId] = useState();
    const [selectedSessionUid, setSelectedSessionUid] = useState();

    const [currentPlanningId, setCurrentPlanningId] = useState();
    const [currentSessionUid, setCurrentSessionUid] = useState();

    const [planningFormModalIsActive, setPlanningFormModalIsActive] = useState(false);
    const [sessionFormModalIsActive, setSessionFormModalIsActive] = useState(false);
    const [planningOrSessionCreateModalIsActive, setPlanningOrSessionCreateModalIsActive] = useState(false);
    const [planningOrSessionEditModalIsActive, setPlanningOrSessionEditModalIsActive] = useState(false);

    const { club_id } = useParams();

    const { loading, error, data, refetch: refetchSessions } = useQuery(GET_SESSIONS_ADMIN, {
        variables: {
            clubId: club_id,
            dateStart,
            dateEnd,
        },
    });

    if (loading) {
        return <Loading />;
    }

    if (error || !data.SessionsAdmin) {
        return <LoadingError error={{ message: "Record not found" }} />;
    }

    const createModalCallback = (mode) => {
        setPlanningOrSessionCreateModalIsActive(false);

        if (mode == "planning") {
            setPlanningFormModalIsActive(true);
            setSelectedPlanningId(null);
        } else if (mode == "session") {
            setSessionFormModalIsActive(true);
            setSelectedSessionUid(null);
        }
    }

    const editModalCallback = (mode) => {
        setPlanningOrSessionCreateModalIsActive(false);

        if (mode == "planning") {
            setPlanningFormModalIsActive(true);
            setSelectedPlanningId(currentPlanningId);
        } else if (mode == "session") {
            setSessionFormModalIsActive(true);
            setSelectedSessionUid(currentSessionUid);
        }
    }

    const onEdit = (session) => {        
        if (isUniqueSession(session)) {
            setSelectedSessionUid(session.uid);
            setSessionFormModalIsActive(true);
        } else {
            setCurrentPlanningId(session.planning_id)
            setCurrentSessionUid(session.uid)
            setPlanningOrSessionEditModalIsActive(true);
        }
    };

    const onCreate = () => {
        setPlanningOrSessionCreateModalIsActive(true);
    };

    const onClose = (hasBeenUpdated) => {
        if (hasBeenUpdated) {
            refetchSessions();
        }
        setPlanningFormModalIsActive(false);
        setSessionFormModalIsActive(false);
        setPlanningOrSessionCreateModalIsActive(false);
        setPlanningOrSessionEditModalIsActive(false);
    };

    return (
        <PageAdmin
            title={data.Club.name}
            metaTitle={t("admin:planning.meta.title")}
            activeMenu="planning"
            isWide={true}
        >

            <Modal open={planningFormModalIsActive}>
                <PlanningFormModal
                    planningId={selectedPlanningId}
                    onClose={onClose}
                />
            </Modal>

            <Modal open={sessionFormModalIsActive}>
                <SessionFormModal
                    sessionUid={selectedSessionUid}
                    onClose={onClose}
                />
            </Modal>

            <PlanningOrSessionCreateModal
                isActive={planningOrSessionCreateModalIsActive}
                onClose={onClose}
                callback={(mode) => createModalCallback(mode)}
            />

            <PlanningOrSessionEditModal
                isActive={planningOrSessionEditModalIsActive}
                onClose={onClose}
                callback={(mode) => editModalCallback(mode)}
            />

            <Planning
                dateStart={dateStart}
                dateEnd={dateEnd}
                sessions={data.SessionsAdmin}
                types={data.Types}
                rooms={data.Rooms}
                onClick={(session) => onEdit(session)}
                onCreate={onCreate}
            />
        </PageAdmin>
    );
};
export default PageAdminPlannings;
