import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const SearchClubsInput = ({ callback }) => {

    const { t } = useTranslation(["components"])
    const queryRef = useRef(null)

    const onSubmit = (e) => {
        e.preventDefault()
        callback && callback(queryRef.current.value)
    }

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <div className="field has-addons">
                <div className="control is-expanded">
                    <input autoFocus={true} ref={queryRef} type="text" className="input is-medium" placeholder={t("search_club.input_placeholder")} />
                </div>
                <div className="control is-hidden-mobile">
                    <button type="submit" className="button is-primary is-medium">{t("search_club.input_button")}</button>
                </div>
            </div>
        </form>
    )
}
SearchClubsInput.propTypes = {
    callback: PropTypes.func
}

export default SearchClubsInput;