import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { GET_MEMBERS, MEMBER_FIND } from "graphql/query/member";
import { MEMBER_ADD, MEMBER_CREATE } from "graphql/mutation/member";

import NewMemberModalFind from "./NewMemberModal.find";
import NewMemberModalCreate from "./NewMemberModal.create";
import NewMemberModalAdd from "./NewMemberModal.add";
import { getMemberFindSchema, getMemberCreateSchema } from "./NewMemberModal.schema";

const ERROR_USER_ALREADY_JOINED = "user_already_joined";
const ERROR_USER_NOT_FOUND = "user_not_found";

const MODE_CREATE_USER = "mode_create_user";
const MODE_ADD_USER = "mode_add_user";
const MODE_USER_ALREADY_JOINED = "mode_user_already_joined";

const NewMemberModal = ({ clubId, isActive, onClose }) => {
  const modalRef = useRef();

  const { t } = useTranslation(["admin", "translation", "errors"]);

  const [memberFindError, setMemberFindError] = useState("");
  const [memberCreateError, setMemberCreateError] = useState("");
  const [memberCreateLoading, setMemberCreateLoading] = useState(false);
  const [memberAddLoading, setMemberAddLoading] = useState(false);
  const [memberMode, setMemberMode] = useState(null);
  const [findEmail, setFindEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState();
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");

  const memberFindSchema = getMemberFindSchema(t);
  const memberCreateSchema = getMemberCreateSchema(t);
  
  const clean = (cleanFindEmail = true) => {
    setUserId(null);
    setUserFirstname("");
    setUserLastname("");
    setUserEmail("");
    setMemberMode(null);

    cleanFindEmail && setFindEmail("");
  };

  const [memberFindQuery, { loading }] = useLazyQuery(MEMBER_FIND, {
    fetchPolicy: "network-only",

    onCompleted: ({ MemberFind }) => {
      if (MemberFind) {
        setMemberMode(MODE_ADD_USER);
        setUserId(MemberFind.id);
        setUserFirstname(MemberFind.firstname);
        setUserLastname(MemberFind.lastname);
      }
    },
    onError: (response) => {
      const error =
        response && response.graphQLErrors
          ? response.graphQLErrors[0].message
          : null;
      clean(false);

      if (error == ERROR_USER_ALREADY_JOINED) {
        setMemberMode(MODE_USER_ALREADY_JOINED);
      } else if (error == ERROR_USER_NOT_FOUND) {
        setMemberMode(MODE_CREATE_USER);
        setUserEmail(findEmail);
      }
    },
  });

  const [memberAddMutation] = useMutation(MEMBER_ADD, {
    update: (cache, { data }) => {
      updateCache(cache, data.MemberAdd);
    },
    onCompleted() {
      setMemberAddLoading(false);
      clean();
      onClose();
    },
    onError: () => {
      setMemberAddLoading(false);
    }
  });

  const [memberCreateMutation] = useMutation(MEMBER_CREATE, {
    update: (cache, { data }) => {
      updateCache(cache, data.MemberCreate);
    },
    onCompleted() {
      setMemberCreateLoading(false);
      clean();
      onClose();
    },
    onError: (response) => {
      setMemberCreateLoading(false);
      setMemberCreateError(t("errors:messages."+response?.message))
    }
  });

  const updateCache = (cache, newMember) => {
    const currentMembers = cache.readQuery({
      query: GET_MEMBERS,
      variables: { clubId: clubId },
    });

    const newMembers = currentMembers
      ? [...currentMembers.Members, newMember]
      : [newMember];

    cache.writeQuery({
      query: GET_MEMBERS,
      variables: { clubId },
      data: { Members: newMembers },
    });
  };

  const memberFind = () => {
    setMemberFindError("")
    memberFindSchema
      .validate({
        findEmail,
      })
      .catch((err) => {
        setMemberFindError(err.message)
        clean(false);
      })
      .then((valid) => {
        if (valid) {
          setMemberMode("");
          memberFindQuery({
            variables: {
              clubId,
              email: findEmail,
            },
          });
        }
      });
  };

  const memberCreate = () => {
    setMemberCreateError("")
    memberCreateSchema
    .validate({
      userFirstname,
      userLastname,
      userEmail
    })
    .catch((err) => {
      setMemberCreateError(err.message)
    })
    .then((valid) => {
      if (valid) {
        setMemberCreateLoading(true);
        memberCreateMutation({
          variables: {
            clubId,
            firstname: userFirstname,
            lastname: userLastname,
            email: userEmail,
          }
        });
      }
    })
  };

  const memberAdd = () => {
    setMemberAddLoading(true);
    memberAddMutation({
      variables: {
        clubId,
        userId,
      },
    });
  };

  useEffect(() => {
    isActive
      ? modalRef.current.classList.add("is-active")
      : modalRef.current.classList.remove("is-active");
  }, [isActive]);

  return (
    <div ref={modalRef} className="modal">
      <div className="modal-background"></div>

      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t("admin:member.modal.title")}</p>
          <button className="delete" aria-label="close"></button>
        </header>

        <section className="modal-card-body">
          <NewMemberModalFind
            findEmail={findEmail}
            setFindEmail={setFindEmail}
            loading={loading}
            memberFind={memberFind}
            memberFindError={memberFindError}
          />

          {memberMode == MODE_USER_ALREADY_JOINED && (
            <>
              <hr />
              <div className="notification is-warning has-text-centered">
                {t("admin:member.modal.notifications.user_already_joined")}
              </div>
            </>
          )}

          {memberMode == MODE_CREATE_USER && (
            <>
              <hr />
              <NewMemberModalCreate
                userFirstname={userFirstname}
                setUserFirstname={setUserFirstname}
                userLastname={userLastname}
                setUserLastname={setUserLastname}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                memberCreate={memberCreate}
                memberCreateError={memberCreateError}
                memberCreateLoading={memberCreateLoading}
              />
            </>
          )}

          {memberMode == MODE_ADD_USER && userId && (
            <>
              <hr />
              <NewMemberModalAdd
                userFirstname={userFirstname}
                userLastname={userLastname}
                memberAdd={memberAdd}
                memberAddLoading={memberAddLoading}
              />
            </>
          )}
        </section>

        <footer className="modal-card-foot">
          <button
            onClick={() => {
              clean();
              onClose();
            }}
            type="button"
            className="button is-info"
          >
            {t("translation:global.close")}
          </button>
        </footer>
      </div>
    </div>
  );
};

NewMemberModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  clubId: PropTypes.number,
};

export default NewMemberModal;
