import { gql } from '@apollo/client';

export const GET_CONFIG = gql`
    query Config($clubId: Int!) {
        Club(id: $clubId) {
            name
        }
        Config(club_id: $clubId) {
            id
            club_id
            config
        }
    }
`


