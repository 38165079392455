import { gql } from "@apollo/client";

export const CREATE_PACKAGE = gql`
    mutation CreatePackage(
        $workspaceId: Int!, 
        $status: String!, 
        $title: String!, 
        $description: String,
        $rules: PackageRulesInput!, 
        $limitations: PackageLimitationsInput!, 
        $amount: Float!,
        $taxPercentage: Float!,
        $taxedAmount: Float!,
        $paymentFrequency: String!,
        $durationDays: Int,
        $isDefaultOnJoin: Int
    ) {
        CreatePackage (
            workspace_id: $workspaceId, 
            status: $status, 
            title: $title, 
            description: $description,
            rules: $rules, 
            limitations: $limitations, 
            amount: $amount, 
            tax_percentage: $taxPercentage,
            taxed_amount: $taxedAmount,
            payment_frequency: $paymentFrequency,
            duration_days: $durationDays,
            is_default_on_join: $isDefaultOnJoin
        ) {
            id
            workspace_id
            status
            title
            description
            rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                type_ids
                room_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            duration_days
            is_default_on_join
        }
    }
`

export const UPDATE_PACKAGE = gql`
    mutation UpdatePackage(
        $id: Int!, 
        $status: String!, 
        $title: String!, 
        $description: String,
        $rules: PackageRulesInput!, 
        $limitations: PackageLimitationsInput!, 
        $amount: Float!,
        $taxPercentage: Float!,
        $taxedAmount: Float!,
        $paymentFrequency: String!,
        $durationDays: Int,
        $isDefaultOnJoin: Int
    ) {
        UpdatePackage (
            id: $id, 
            status: $status, 
            title: $title, 
            description: $description,
            rules: $rules, 
            limitations: $limitations, 
            amount: $amount, 
            tax_percentage: $taxPercentage,
            taxed_amount: $taxedAmount,
            payment_frequency: $paymentFrequency,
            duration_days: $durationDays,
            is_default_on_join: $isDefaultOnJoin
        ) {
            id
            workspace_id
            status
            title
            description
            rules {
                unlimited
                sessions
                hours
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                type_ids
                room_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            duration_days
            is_default_on_join
        }
    }
`


export const DELETE_PACKAGE = gql`
    mutation DeletePackage($id: Int!) {
        DeletePackage(id: $id) 
    }
`