import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        slug:
            yup.string()
                .required(t('errors:forms.club.fields.slug.-100')),

        name:
            yup.string()
                .required(t('errors:forms.club.fields.name.-100')),

        address:
            yup.string()
                .required(t('errors:forms.club.fields.address.-100')),

        timezone:
            yup.string()
                .required(t('errors:forms.club.fields.timezone.-100')),
    });
}

export default getSchema