import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';
import { getRoute } from 'utils/route';

import PageProfilContentInfos from './PageProfilContentInfos';
import PageProfilContentApp from './PageProfilContentApp';
import PageProfilContentNotifications from './PageProfilContentNotifications';

const PageProfilContent = ({ me, timezones }) => {

    return (
        <>
            <PageProfilContentApp
                lang={me.lang}
                theme={me.theme}
            />

            <PageProfilContentNotifications
                emailReminderDisabled={me.disable_email_reminder}
            />

            <PageProfilContentInfos
                me={me}
                timezones={timezones}
            />

            <div className="block mt-5">
                <Link to={getRoute("signout")} className="button is-warning is-medium is-wide">Déconnexion</Link>
            </div>

        </>
    )
}

PageProfilContent.propTypes = {
    me: PropTypes.object,
    timezones: PropTypes.array
}

export default PageProfilContent;