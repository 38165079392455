import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        name:
            yup.string()
                .required(t('errors:forms.room.fields.name.-100')),
    });    
}

export default getSchema