import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom'
import { routes } from 'routes'

import FormPanel from 'components/form/FormPanel'
import ContentCenter from 'structure/ContentCenter'
import PasswordResetForm from 'forms/Password/PasswordResetForm'

const PagePasswordReset = () => {

    const { t } = useTranslation(['pages', 'forms'])

    const { uid, hash, token } = useParams()
    const [isSubmitted, setIsSubmitted] = useState(false);

    const form = () => (
        <>
            <Helmet>
                <title>{t("pages:password_reset.meta.title")}</title>
            </Helmet>

            <ContentCenter>
                <FormPanel title="%forms:password_reset.title%">
                    <PasswordResetForm
                        uid={uid}
                        token={token}
                        hash={hash}
                        callback={() => setIsSubmitted(true)}
                    />
                </FormPanel>
            </ContentCenter>
        </>
    )

    return isSubmitted ? <Navigate to={routes.password_reset_done} /> : form()
}

export default PagePasswordReset
