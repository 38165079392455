import { gql } from "@apollo/client";

export const GET_PLANNING_FORM = gql`
    query Planning_Form($clubId: Int!) {
        Rooms(club_id: $clubId) {
            id
            name
            description
        }
        Types(club_id: $clubId) {
            id
            club_id
            name
            description
            color
        }
        Coaches(club_id: $clubId) {
            id
            firstname
            lastname
        }
        Config(club_id: $clubId) {
            id
            club_id
            config
        }
    }
`;

export const GET_PLANNING = gql`
    query Planning($id: Int!) {
        Planning(id: $id) {
            id
            room_id
            type_id
            user_id
            status
            message
            max_registrations
            start_date
            end_date
            start_time
            end_time
            repeat_mode
            repeat_frequency
            repeat_at
            end_occurencies
        }
    }
`;

export const GET_PLANNINGS = gql`
    query Plannings($roomId: Int!) {
        Plannings(room_id: $roomId) {
            id
            room_id
            type_id
            Type {
                id
                name
            }
            message
            status
            start_date
            end_date
            start_time
            end_time
        }
    }
`;
