import { 
    REGISTRATION_STATUS_NONE, 
    REGISTRATION_STATUS_VALIDATED, 
    REGISTRATION_STATUS_PENDING, 
    REGISTRATION_STATUS_CANCELLED, 
    ACTION_REGISTER, 
    ACTION_PENDING, 
    ACTION_CANCEL 
} from './const'

/** Return action to execute according to current status and if session is complete */
export const computeAction = (status, isComplete) => {
    if ((status == REGISTRATION_STATUS_NONE || status == REGISTRATION_STATUS_CANCELLED) && isComplete === true) {
        return ACTION_PENDING
    }
    if (status == REGISTRATION_STATUS_VALIDATED || status == REGISTRATION_STATUS_PENDING) {
        return ACTION_CANCEL
    }
    return ACTION_REGISTER
}

export const getBtnClass = ({ registrationStatus, isComplete }) => {
    switch (registrationStatus) {
        case REGISTRATION_STATUS_NONE:
        case REGISTRATION_STATUS_CANCELLED:
            return isComplete ? 'is-danger' : ''
        case REGISTRATION_STATUS_VALIDATED:
            return 'is-success'
        case REGISTRATION_STATUS_PENDING:
            return 'is-warning'
    }
    return ''
}

export const getBtnLabelInfo = (t, { registrationStatus, isComplete }) => {
    switch (registrationStatus) {
        case REGISTRATION_STATUS_NONE:
            return isComplete ? t("session_card_button.label_info.complete") : t("session_card_button.label_info.register")
        case REGISTRATION_STATUS_VALIDATED:
            return t("session_card_button.label_info.validated")
        case REGISTRATION_STATUS_PENDING:
            return t("session_card_button.label_info.pending")
    }
    return isComplete ? t("session_card_button.label_info.complete") : t("session_card_button.label_info.register")
}

export const getBtnLabelAction = (t, { registrationStatus, isComplete }) => {
    switch (registrationStatus) {
        case REGISTRATION_STATUS_NONE:
            return isComplete ? t("session_card_button.label_action.register_queue") : t("session_card_button.label_action.register")
        case REGISTRATION_STATUS_VALIDATED:
            return t("session_card_button.label_action.register_cancel")
        case REGISTRATION_STATUS_PENDING:
            return t("session_card_button.label_action.pending_cancel")
    }
    return isComplete ? t("session_card_button.label_action.register_queue") : t("session_card_button.label_action.register")
}