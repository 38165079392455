import React, { useState } from 'react';
import PropTypes from 'prop-types'

const Switch = ({ label, labelPosition, name, checked, size, color, onChange }) => {

    const [isChecked, setIsChecked] = useState(checked)
    const handleOnChange = (e) => {
        setIsChecked(current => !current)
        onChange && onChange(e.target.checked)
    }

    const getLabel = (position) => {
        if (position != labelPosition) return null
        return (
            <span className={'switch-label ' + (labelPosition == 'left' ? 'mr-2' : 'ml-2')}>{label}
            </span>
        )
    }

    return (
        <div className={"switch-wrapper " + size}>
            {getLabel("left")}
            <div className={"switch " + size}>
                <label className={isChecked ? " checked" : ""}>
                    <input type="checkbox" name={name} checked={isChecked} onChange={handleOnChange} />
                    <span className={"slider " + color}></span>
                </label>
            </div>
            {getLabel("right")}
        </div>
    )
}

Switch.defaultProps = {
    color: 'primary',
    size: 'normal',
    labelPosition: 'right'
}

Switch.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    checked: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'danger']),
    size: PropTypes.oneOf(['small', 'normal', 'medium']),
    onChange: PropTypes.func
}

export default Switch;