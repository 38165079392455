import { gql } from "@apollo/client";

export const REGISTRATION_PRESENT_FLAG = gql`
    mutation RegistrationPresentFlag($registrationId: Int!, $presentFlag: Boolean!) {
        RegistrationPresentFlag(
            registration_id: $registrationId
            present_flag: $presentFlag
        ) {
            id
            present_flag
        }
    }
`;
