import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router';

import PageAdmin from 'structure/PageAdmin';
import RoomForm from 'forms/Room/RoomForm';

import Back from 'components/Back';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { GET_ROOM } from 'graphql/query/room'

import { getRoute } from 'utils/route';

const PageAdminRoomEdit = () => {

    const { room_id, club_id } = useParams()

    const { loading, error, data } = useQuery(GET_ROOM, {
        variables: {
            id: room_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <LoadingError error={{ message: "Error while loading" }} />
        )
    }

    return (
        <PageAdmin activeMenu="room">
            <Back to={getRoute("admin_rooms", { club_id })} />
            <RoomForm room={data.Room} clubId={parseInt(club_id)} />
        </PageAdmin>
    )
}

export default PageAdminRoomEdit