import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { intOrZero } from "utils/str";

function getSessionPosition(session, index, clusterLength) {
    const hourStart = parseInt(moment(session.start_datetime).format("H"));
    const minuteStart = parseInt(moment(session.start_datetime).format("m"));

    const duration = parseInt(
        moment
            .duration(
                moment(session.end_datetime).diff(moment(session.start_datetime))
            )
            .asMinutes()
    );

    const top = hourStart * (15 * 4) + minuteStart;
    const height = (duration / 15) * 15;
    const width = 100 / clusterLength;
    const left = clusterLength > 1 ? (100 / clusterLength) * index : 0;

    return {
        top,
        left,
        width,
        height,
    };
}

function PlanningSession({ session, onClick, index, clusterLength }) {
    const { top, left, width, height } = getSessionPosition(
        session,
        index,
        clusterLength
    );

    const classOutdated = moment(session.end_datetime).isBefore(moment())
        ? "is-outdated"
        : "";

    const timeStart = moment(session.start_datetime).format("HH:mm");
    const timeEnd = moment(session.end_datetime).format("HH:mm");
    const counterRegistrationsFinal = intOrZero(session.counter_registrations) + intOrZero(session.counter_pending)

    const registrationStatus = session?.Registration?.status ?? '';
    return (
        <div
            className={`planning-session ${registrationStatus}`}
            onClick={() => onClick(session)}
            style={{
                top: top + "px",
                left: left + "%",
                height: height + "px",
                width: width + "%",
            }}
        >
            <div className="planning-session-content">
                <div
                    className={`planning-session-counter-registrations tag is-rounded ${registrationStatus}`}
                >
                    {counterRegistrationsFinal} / {session.max_registrations}
                </div>
                <div
                    className={`planning-session-header ${classOutdated}`}
                    style={{
                        backgroundColor: session.type_color,
                    }}
                ></div>

                <div className="planning-session-body">
                    <p className="planning-session-body-name">{session.type_name}</p>
                    <p className="planning-session-body-time">
                        {timeStart} - {timeEnd}
                    </p>
                </div>
            </div>
        </div>
    );
}

PlanningSession.propTypes = {
    session: PropTypes.any,
    onClick: PropTypes.func,
    clusterLength: PropTypes.number,
    index: PropTypes.number,
};

export default PlanningSession;
