import React from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { getRoute } from "utils/route";

const PageAdminMenu = ({ clubId, activeMenu }) => {
  const { t } = useTranslation(["admin"]);

  const removeHasAdminMenuClass = () => {
    document.body.classList.remove("has-admin-menu");
  };
  return (
    <aside className="menu p-2">
      <ul className="menu-list">
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_home_persist")}
            className={activeMenu == "home" ? "is-active" : ""}
          >
            {t("admin:menu.admin")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_club_home", { club_id: clubId })}
            className={activeMenu == "club" ? "is-active" : ""}
          >
            {t("admin:menu.club")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_rooms", { club_id: clubId })}
            className={activeMenu == "room" ? "is-active" : ""}
          >
            {t("admin:menu.rooms")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_types", { club_id: clubId })}
            className={activeMenu == "type" ? "is-active" : ""}
          >
            {t("admin:menu.types")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_plannings", { club_id: clubId })}
            className={activeMenu == "planning" ? "is-active" : ""}
          >
            {t("admin:menu.plannings")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_members", { club_id: clubId })}
            className={activeMenu == "member" ? "is-active" : ""}
          >
            {t("admin:menu.members")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_packages", { club_id: clubId })}
            className={activeMenu == "package" ? "is-active" : ""}
          >
            {t("admin:menu.packages")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_config", { club_id: clubId })}
            className={activeMenu == "config" ? "is-active" : ""}
          >
            {t("admin:menu.config")}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => removeHasAdminMenuClass()}
            to={getRoute("admin_absences", { club_id: clubId })}
            className={activeMenu == "absences" ? "is-active" : ""}
          >
            {t("admin:menu.absences")}
          </Link>
        </li>
      </ul>
    </aside>
  );
};

PageAdminMenu.propTypes = {
  activeMenu: PropTypes.string,
  clubId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PageAdminMenu;
