import React, { useState, useContext, useEffect }  from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { PlanningContext } from "context/PlanningContext";

import { setSwipeEffect } from './utils';

export const PlanningColumnHeader = ({ column }) => {
    const isToday = column.date.isSame(moment().format("YYYY-MM-DD")) ? "is-today" : '';

    const [swipeClass, setSwipeClass] = useState('');
    const { setIsSwipedLeft, isSwipedLeft, setIsSwipedRight, isSwipedRight } = useContext(PlanningContext)

    useEffect(() => {
        if (isSwipedLeft) {
            setSwipeEffect(setSwipeClass, setIsSwipedLeft, 'left')
        }
    }, [isSwipedLeft])

    useEffect(() => {
        if (isSwipedRight) {
            setSwipeEffect(setSwipeClass, setIsSwipedRight, 'right')
        }
    }, [isSwipedRight])

    return (
        <div className={`planning-column-header column ${isToday} ${swipeClass}`}>
            <div className="date-wrapper">
                <div className="date-content">
                    <div className="day-of-week">
                        <span>{column.dayOfWeek}</span>
                    </div>
                    <div className="day-number">
                        <span>{column.day}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

PlanningColumnHeader.propTypes = {
    column: PropTypes.object
}