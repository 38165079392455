import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { PlanningColumnHeader } from "./PlanningColumnHeader";

import { isAdminUrl } from "utils/app";

function PlanningHeader({ columns, reference }) {

  const location = useLocation()
  const [isAdminLocation, setIsAdminLocation] = useState(isAdminUrl())

  useEffect(() => {
    setIsAdminLocation(isAdminUrl())
  }, [location])

  return (
    <div ref={reference} className={isAdminLocation ? "planning-header-admin" : "planning-header"}>
      <div className="columns is-gapless is-mobile">

        <div className="planning-column-header column is-narrow"></div>

        {columns.map((column, index) => (
          <PlanningColumnHeader key={index} column={column} />
        ))}
      </div>
    </div>
  );
}

PlanningHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  reference: PropTypes.any,
};

export default PlanningHeader;
