import React, { useState } from "react";
import PropTypes from "prop-types";

const ControlCheckbox = ({
  error,
  label,
  help,
  value,
  onChange,
}) => {
  const hasError = error && error.length > 0;
  const [currentValue, setCurrentValue] = useState(value)

  return (
    <div className="field">
      <div
        className="control"
      >
        <label className="checkbox">
            <input
                type="checkbox"
                onChange={(e) => { setCurrentValue(e.target.checked); onChange(e.target.checked) }}
                checked={currentValue}
                value={currentValue}
                />
            {label && <span className="ml-1">{label}</span>}                
        </label>
      </div>

      {hasError && <p className="help is-danger">{error}</p>}

      {help && <p className="help has-text-grey-light">{help}</p>}
    </div>
  );
};

ControlCheckbox.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  ref: PropTypes.any,
};

export default ControlCheckbox;
