import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";

import ControlCheckbox from "components/form/ControlCheckbox";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlSelect from "components/form/ControlSelect";
import Switch from 'components/form/Switch';

import { getTaxedAmount, binToBool } from './utils';

const STATUS_ENABLED = "enabled";
const STATUS_DISABLED = "disabled";

const PAYMENT_FREQUENCY_UNIQUE = "unique"
const PAYMENT_FREQUENCY_MONTHLY = "monthly"
const PAYMENT_FREQUENCY_QUATERLY = "quaterly"
const PAYMENT_FREQUENCY_ANNUALY = "annualy"

const DEFAULT_STATUS = 'enabled';
const DEFAULT_TAX = 20;

const packageDataFormTabInfos = ({ packageData, control, errors, getValues, setValue }) => {

    const { t } = useTranslation(["translation", "errors", "forms"])

    const [ruleIsUnlimited, setRuleIsUnlimited] = useState(packageData && packageData.rules && packageData.rules.unlimited ? packageData.rules.unlimited : false)

    const updateTaxedAmount = () => {
        const amount = getValues("amount");
        const taxPercentage = getValues("tax_percentage");
        setValue("taxed_amount", getTaxedAmount(amount, taxPercentage));
    }
    const updateRuleIsUnlimited = (isUnlimited) => {
        setRuleIsUnlimited(isUnlimited);
        if (isUnlimited) {
            setValue('rule_sessions', '')
        }
    }
    return (
        <>
            <Controller
                control={control}
                name="title"
                defaultValue={packageData ? packageData.title : ""}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        type="text"
                        value={value}
                        error={errors.title?.message}
                        placeholder={t("forms:package.fields.title.placeholder")}
                    />
                )}
            />
            <div className="block">
                <Controller
                    control={control}
                    name="is_default_on_join"
                    defaultValue={binToBool(packageData?.is_default_on_join, false)}
                    render={({ onChange, value }) => (
                        <Switch
                            onChange={onChange}
                            name="is_default_on_join"
                            checked={value}
                            size="medium"
                            label={t("forms:package.fields.is_default_on_join.label")}
                            labelPosition="right"
                        />
                    )}
                />
            </div>
            <Controller
                control={control}
                name="description"
                defaultValue={packageData ? packageData.description : ""}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        error={errors.description?.message}
                        placeholder={t("forms:package.fields.description.placeholder")}
                    />
                )}
            />
            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="status"
                        defaultValue={packageData ? packageData?.status : DEFAULT_STATUS}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                error={errors.status?.message}
                                value={value}
                                values={[
                                    { label: t("forms:package.fields.status.values.enabled"), value: STATUS_ENABLED },
                                    { label: t("forms:package.fields.status.values.disabled"), value: STATUS_DISABLED }
                                ]}
                                placeholder={t("forms:package.fields.status.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="payment_frequency"
                        defaultValue={packageData ? packageData?.payment_frequency : ''}
                        render={({ onChange, value }) => (
                            <ControlSelect
                                onChange={onChange}
                                error={errors.payment_frequency?.message}
                                value={value}
                                values={[
                                    { label: t("forms:package.fields.payment_frequency.values.unique"), value: PAYMENT_FREQUENCY_UNIQUE },
                                    { label: t("forms:package.fields.payment_frequency.values.monthly"), value: PAYMENT_FREQUENCY_MONTHLY },
                                    { label: t("forms:package.fields.payment_frequency.values.quaterly"), value: PAYMENT_FREQUENCY_QUATERLY },
                                    { label: t("forms:package.fields.payment_frequency.values.annualy"), value: PAYMENT_FREQUENCY_ANNUALY },
                                ]}
                                placeholder={t("forms:package.fields.payment_frequency.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column  is-one-third ">
                    <Controller
                        control={control}
                        name="duration_days"
                        defaultValue={packageData ? packageData?.duration_days : ''}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="number"
                                value={value}
                                rightAddons={t("forms:package.fields.duration_days.unit")}
                                rightAddonsClass="is-static"
                                error={errors.duration_days?.message}
                                placeholder={t("forms:package.fields.duration_days.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="amount"
                        defaultValue={packageData ? packageData?.amount : ''}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={(e) => { onChange(e); updateTaxedAmount() }}
                                type="text"
                                value={value}
                                error={errors.amount?.message}
                                rightAddons={t("forms:package.fields.amount.unit")}
                                placeholder={t("forms:package.fields.amount.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="tax_percentage"
                        defaultValue={packageData ? packageData?.tax_percentage : DEFAULT_TAX}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={(e) => { onChange(e); updateTaxedAmount() }}
                                type="text"
                                value={value}
                                error={errors.tax_percentage?.message}
                                rightAddons="%"
                                placeholder={t("forms:package.fields.tax_percentage.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="taxed_amount"
                        defaultValue={packageData ? packageData?.taxed_amount : ''}
                        render={({ onChange, value }) => (
                            <ControlText
                                disabled={true}
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.taxed_amount?.message}
                                rightAddons={t("forms:package.fields.taxed_amount.unit")}
                                placeholder={t("forms:package.fields.taxed_amount.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="box">
                <h4 className="is-size-4 mb-4"><b>{t("forms:package.fields.rules.title")}</b></h4>
                <div className="columns">
                    <div className="column">
                        <Controller
                            control={control}
                            name="rule_unlimited"
                            defaultValue={packageData ? packageData?.rules?.unlimited : ''}
                            render={({ onChange, value }) => (
                                <ControlCheckbox
                                    onChange={(e) => { onChange(e); updateRuleIsUnlimited(e) }}
                                    label={t("forms:package.fields.rules.unlimited.label")}
                                    error={errors.rule_unlimited?.message}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className="column">
                        <Controller
                            control={control}
                            name="rule_sessions"
                            defaultValue={(packageData && packageData.rules && packageData.rules.sessions) ? packageData.rules.sessions : ''}
                            render={({ onChange, value }) => (
                                <ControlText
                                    disabled={ruleIsUnlimited}
                                    onChange={onChange}
                                    type="number"
                                    value={value}
                                    error={errors.rule_sessions?.message}
                                    label={t("forms:package.fields.rules.sessions.label")}
                                    tooltip="Nombre de sessions totales autorisées durant la durée de l'abonnement"
                                />
                            )}
                        />
                    </div>
                    <div className="column">
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Controller
                            control={control}
                            name="rule_sessions_per_week"
                            defaultValue={(packageData && packageData.rules && packageData.rules.sessions_per_week) ? packageData.rules.sessions_per_week : ''}
                            render={({ onChange, value }) => (
                                <ControlText
                                    onChange={onChange}
                                    type="number"
                                    value={value}
                                    error={errors.rule_sessions_per_week?.message}
                                    label={t("forms:package.fields.rules.sessions_per_week.label")}
                                />
                            )}
                        />
                    </div>
                    <div className="column">
                        <Controller
                            control={control}
                            name="rule_sessions_per_month"
                            defaultValue={(packageData && packageData.rules && packageData.rules.sessions_per_month) ? packageData.rules.sessions_per_month : ''}
                            render={({ onChange, value }) => (
                                <ControlText
                                    onChange={onChange}
                                    type="number"
                                    value={value}
                                    error={errors.rule_sessions_per_month?.message}
                                    label={t("forms:package.fields.rules.sessions_per_month.label")}
                                />
                            )}
                        />
                    </div>
                    <div className="column">
                        <Controller
                            control={control}
                            name="rule_simultaneous_sessions"
                            defaultValue={(packageData && packageData.rules && packageData.rules.simultaneous_sessions) ? packageData.rules.simultaneous_sessions : ''}
                            render={({ onChange, value }) => (
                                <ControlText
                                    onChange={onChange}
                                    type="number"
                                    value={value}
                                    error={errors.simultaneous_sessions?.message}
                                    label={t("forms:package.fields.rules.simultaneous_sessions.label")}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

packageDataFormTabInfos.propTypes = {
    packageData: PropTypes.object,
    control: PropTypes.any,
    errors: PropTypes.any
}

export default packageDataFormTabInfos;