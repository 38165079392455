import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { GET_TIMEZONES } from 'graphql/query/timezones'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import ClubFormContent from './ClubFormContent'

const ClubForm = ({ club, afterCreation }) => {

    const { loading, error, data } = useQuery(GET_TIMEZONES);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <LoadingError error={{ message: "Error while loading" }} />;
    }

    return (
        <ClubFormContent 
            club={club} 
            timezones={data.Timezones} 
            afterCreation={afterCreation}
        />
    )
}

ClubForm.propTypes = {
    club: PropTypes.any,
    afterCreation: PropTypes.func
}

export default ClubForm;