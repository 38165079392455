import React from "react";
import PropTypes from "prop-types";

import SessionModalAttendee from "./SessionModalAttendee";

const SessionModalAttendees = ({ registrations, isOwner, show }) => {
    if (!show) {
        return null;
    }

    const messageStyle = { maxHeight: "300px", overflow: "auto" };

    return (
        <article className="message" style={messageStyle}>
            <div className="message-body">
                <ul>
                    {registrations &&
                        registrations.map((registration, index) => (
                            <SessionModalAttendee
                                showPresentButtons={isOwner}
                                registration={registration}
                                key={index}
                            />
                        ))}
                </ul>
            </div>
        </article>
    );
};

SessionModalAttendees.propTypes = {
    show: PropTypes.bool,
    registrations: PropTypes.array,
    isOwner: PropTypes.bool
};

export default SessionModalAttendees;
