import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        title:
            yup.string()
                .required(t('errors:forms.package_user.fields.title.-100')),

        status:
            yup.string()
                .required(t('errors:forms.package_user.fields.status.-100')),

        payment_frequency:
            yup.string()
                .required(t('errors:forms.package_user.fields.payment_frequency.-100')),

        amount:
            yup.string()
                .required(t('errors:forms.package_user.fields.amount.-100')),

        tax_percentage:
            yup.string()
                .required(t('errors:forms.package_user.fields.tax_percentage.-100')),

        date_start:
            yup.date().typeError(t('errors:forms.package_user.fields.date_start.-200'))
                .required(t('errors:forms.package_user.fields.date_start.-100')),

        rule_unlimited:
            yup.boolean(),

        rule_sessions:
            yup.string()
                .when("rule_unlimited", {
                    is: false,
                    then: yup.string()
                        .required(t('errors:forms.package_user.fields.rule_sessions.-100')).nullable()
                })
    });
}

export default getSchema