import { floatOrZero } from 'utils/str';

export const getTaxedAmount = (amount, taxPercentage) => {
    return (floatOrZero(amount) * (1 + (floatOrZero(taxPercentage) / 100))).toFixed(2)
}

export const binToBool = (value, defaultValue) => {
    if (value === 1) return true;
    if (value === 0) return false;
    return defaultValue;
}

export const boolToBin = (value, defaultValue) => {
    if (value === true) return 1;
    if (value === false) return 0;
    return defaultValue;
}
