import React from "react";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import PageAdmin from "structure/PageAdmin";

import Loading from "components/loading/Loading";
import LoadingError from "components/loading/LoadingError";
import Nl2br from "components/Nl2br";

import { getRoute } from "utils/route";
import { getPicture } from "utils/picture";

import { GET_CLUB } from "graphql/query/club";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faClock,
    faCode,
} from "@fortawesome/free-solid-svg-icons";

const PageAdminClubHome = () => {
    const { t } = useTranslation(["admin"]);
    const { club_id } = useParams();

    const { loading, error, data } = useQuery(GET_CLUB, {
        variables: {
            id: club_id,
        },
    });

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <LoadingError error={{ message: "Error while loading" }} />;
    }

    return (
        <PageAdmin
            title={data.Club.name}
            metaTitle={data.Club.name}
            activeMenu="club"
            isWide={true}
        >
            <div className="image is-128x128 box">
                <img src={getPicture(data.Club.picture)} />
            </div>

            <div className="is-size-6 box">
                <Nl2br>
                    <span className="icon is-small is-left mr-2 mb-2">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>
                    {data.Club.address}
                </Nl2br>
            </div>

            {data.Club.url && <div className="is-size-6 box">
                <Nl2br>
                    <span className="icon is-small is-left mr-2 mb-2">
                        <FontAwesomeIcon icon={faCode} />
                    </span>
                    {data.Club.url}
                </Nl2br>
            </div>}

            <div className="is-size-6 box">
                <Nl2br>
                    <span className="icon is-small is-left mr-2 mb-2">
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    {data.Club.timezone}
                </Nl2br>
            </div>

            <Link
                to={getRoute("admin_club_edit", { club_id })}
                className="button is-primary mt-3 mb-3 mr-3"
            >
                {t("admin:club.edit_infos")}
            </Link>

            <Link
                to={getRoute("admin_company", { club_id })}
                className="button is-info m-3"
            >
                {t("admin:club.edit_company")}
            </Link>
        </PageAdmin>

    );
};

export default PageAdminClubHome;
