import React from 'react';

export const PlanningState = {
    isSwipedLeft: false,
    setIsSwipedLeft: (isSwipedLeft) => isSwipedLeft,
    
    isSwipedRight: false,
    setIsSwipedRight: (isSwipedRight) => isSwipedRight
}

export const PlanningContext = React.createContext(PlanningState);