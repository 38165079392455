import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getDayOfWeek } from "../../utils/date";
/*
Ensure values are only those present within daysOfWeeks->value 
*/
const filterValues = (values, daysOfMonth) => {
  if (!values) {
    return [];
  }
  return values
    .split(",")
    .filter(
      (item1) => daysOfMonth.filter((item2) => item1 == item2.value).length > 0
    );
};

const PlanningFormFieldMonthlyRepeatAt = ({ onChange, value, date }) => {
  const { t } = useTranslation();

  const dateDay = moment(date).format("D");
  const dateDayOfWeekLong = t(
    "day_of_week_long." + moment(date).day()
  ).toUpperCase();
  const dateDayOfWeek = getDayOfWeek(moment(date).day());

  const daysOfMonth = [
    {
      label: t("forms:planning.fields.repeat_monthly.dateday_of_month", {
        dateDay,
      }),
      value: dateDay,
    },
    {
      label: t("forms:planning.fields.repeat_monthly.first_day_of_month", {dateDayOfWeekLong}),
      value: `first_${dateDayOfWeek}`,
    },
    {
      label: t("forms:planning.fields.repeat_monthly.second_day_of_month", {dateDayOfWeekLong}),
      value: `second_${dateDayOfWeek}`,
    },
    {
      label: t("forms:planning.fields.repeat_monthly.third_day_of_month", {dateDayOfWeekLong}),
      value: `third_${dateDayOfWeek}`,
    },
    {
      label: t("forms:planning.fields.repeat_monthly.last_day_of_month", {dateDayOfWeekLong}),
      value: `fourth_${dateDayOfWeek}`,
    },
  ];

  const [selectedDays, setSelectedDays] = useState(
    filterValues(value, daysOfMonth)
  );

  useEffect(() => {
    onChange(selectedDays.join(","));
  }, [selectedDays]);

  const handleOnClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prevState) => prevState.filter((item) => item != day));
    } else {
      setSelectedDays((prevState) => [...prevState, day]);
    }
  };

  const getDayClass = (day) =>
    selectedDays.includes(day) ? "is-primary" : "is-light";

  return (
    <>
      {daysOfMonth.map((item, index) => (
        <span
          key={index}
          onClick={() => handleOnClick(item.value)}
          className={"tag is-clickable mr-2 mb-2 " + getDayClass(item.value)}
        >
          {item.label}
        </span>
      ))}
    </>
  );
};

PlanningFormFieldMonthlyRepeatAt.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  date: PropTypes.string,
};

export default PlanningFormFieldMonthlyRepeatAt;
