import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'structure/Page';
import ContactForm from 'forms/Contact/ContactForm';

const PageContact = () => {

    const { t } = useTranslation(['pages']);
    const [messageSent, setMessageSent] = useState(false);

    return (
        <Page title={t("pages:contact.title")}>

            <h2 className="subtitle">
                {t("pages:contact.baseline")}
            </h2>

            {!messageSent &&
                <ContactForm
                    authentified={true}
                    onMessageSent={() => setMessageSent(true)}
                />
            }

            {messageSent &&
                <article className="message is-success">
                    <div className="message-body">
                        <h4 className="is-size-4">{t("pages:contact.success_title")}</h4>
                        <p>{t("pages:contact.success_message")}</p>
                    </div>
                </article>
            }

        </Page>
    )
}

export default PageContact;