import * as yup from "yup";

export const getMemberFindSchema = (t) =>
  yup.object().shape({
    findEmail: yup
      .string()
      .required(t("admin:member.modal.find.email_required"))
      .email(t("admin:member.modal.find.email_required")),
  });

export const getMemberCreateSchema = (t) =>
  yup.object().shape({
    userFirstname: yup
      .string()
      .required(t("admin:member.modal.create_user.firstname_required")),
    userLastname: yup
      .string()
      .required(t("admin:member.modal.create_user.lastname_required")),
    userEmail: yup
      .string()
      .required(t("admin:member.modal.create_user.email_required"))
      .email(t("admin:member.modal.create_user.email_required")),
  });
