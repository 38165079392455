import React from 'react'
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next'
import { Controller } from "react-hook-form";

import ControlText from "components/form/ControlText";
import ControlSelect from "components/form/ControlSelect";

const ConfigFormBoxVisibility = ({ config, control, errors }) => {
    const { t } = useTranslation(["translation", "forms"]);

    const getDayOfWeek = () =>
        [
            {'label': t("day_of_week_long.1"), 'value': 'monday'},
            {'label': t("day_of_week_long.2"), 'value': 'tuesday'},
            {'label': t("day_of_week_long.3"), 'value': 'wednesday'},
            {'label': t("day_of_week_long.4"), 'value': 'thursday'},
            {'label': t("day_of_week_long.5"), 'value': 'friday'},
            {'label': t("day_of_week_long.6"), 'value': 'saturday'},
            {'label': t("day_of_week_long.0"), 'value': 'sunday'},
        ]

    return (
        <div className="columns">
            <div className="column">
                <div className="field mb-3">
                    <label className="label is-medium">
                        {t("forms:config.fields.planning_allowed_visibility.label")}
                    </label>
                    <div className="field has-addons">
                        <div className="control">
                            <Controller
                                control={control}
                                name="planning_allowed_visibility"
                                defaultValue={config ? config.planning_allowed_visibility : ""}
                                render={({ onChange, value }) => (
                                    <ControlText
                                        onChange={onChange}
                                        type="text"
                                        value={value}
                                        error={errors.planning_allowed_visibility?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className="control">
                            <div className="button is-static is-medium">
                                {t("forms:config.global.days")}
                            </div>
                        </div>
                    </div>
                    <p className="help">
                        {t("forms:config.fields.planning_allowed_visibility.help")}
                    </p>
                </div>
            </div>

            <div className="column">
                <div className="field mb-3">
                    <label className="label is-medium">
                    {t("forms:config.fields.planning_visibility_day_of_week.label")}
                    </label>
                    <div className="field">
                        <div className="control">
                            <Controller
                                control={control}
                                name="planning_visibility_day_of_week"
                                defaultValue={config ? config.planning_visibility_day_of_week : ""}
                                render={({ onChange, value }) => (
                                    <ControlSelect
                                        onChange={onChange}
                                        value={value}
                                        values={getDayOfWeek()}
                                        error={errors.planning_visibility_day_of_week?.message}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <p className="help">
                        {t("forms:config.fields.planning_visibility_day_of_week.help")}
                    </p>
                </div>
            </div>
        </div>
    )
}

ConfigFormBoxVisibility.propTypes = {
    config: PropTypes.object,
    control: PropTypes.any,
    errors: PropTypes.any,
}

export default ConfigFormBoxVisibility