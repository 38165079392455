import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom'

import Page from 'structure/Page'
import PasswordResetForm from 'forms/Password/PasswordResetForm'

import { routes } from 'routes'

const PageSignupFinalize = () => {

    const { t } = useTranslation('pages');

    const { uid, hash, token } = useParams()
    const [isSubmitted, setIsSubmitted] = useState(false);

    const form = () => (
        <Page title="%pages:signup_finalize.title%">
            <div className="box">
                {t("pages:signup_finalize.body")}
            </div>
            <PasswordResetForm
                uid={uid}
                token={token}
                hash={hash}
                callback={() => setIsSubmitted(true)}
            />
        </Page>
    )

    return isSubmitted ? <Navigate to={routes.password_reset_done} /> : form()

}

export default PageSignupFinalize