import { gql } from '@apollo/client'

export const GET_PACKAGES = gql`
    query Packages($workspaceId: Int!) {
        Packages(workspace_id: $workspaceId) {
        id
            workspace_id
            status
            title
            description
            rules {
                unlimited
                sessions
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                room_ids
                type_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            duration_days
            is_default_on_join
        }
    }
`;

export const GET_PACKAGE_ADMIN = gql`
    query Package($id: Int!) {
        Package(id: $id) {
            id
            workspace_id
            status
            title
            description
            rules {
                unlimited
                sessions
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                room_ids
                type_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            duration_days
            is_default_on_join
        }
        ClubsOwned {
            id
            slug
            name
            address
            url
            picture
            Types {
                id
                club_id
                name
                description
                color
            }
            Rooms {
                id
                name
            }
        }       
    }
`