import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

const LoadingError = ({ error }) => {
    const { t } = useTranslation();

    return (
        <div className="notification is-warning is-wide">
            {error.message ? error.message : t('error.unknow')}
        </div>
    )
}

LoadingError.propTypes = {
    error: PropTypes.any
}

export default LoadingError