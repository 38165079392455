import React from "react";
import PropTypes from "prop-types";
import PlanningSession from "./PlanningSession";

function PlanningSessions({ sessions, onClick }) {
  return sessions.map((session, index) => (
    <PlanningSession
      key={`${session.id}-${index}`}
      index={index}
      session={session}
      onClick={onClick}
      clusterLength={sessions.length}
    />
  ));
}

PlanningSessions.propTypes = {
  sessions: PropTypes.any,
  onClick: PropTypes.func,
};

export default PlanningSessions;
