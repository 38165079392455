import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NewMemberModalAdd = ({ userFirstname, userLastname, memberAdd, memberAddLoading }) => {

  const { t } = useTranslation(["admin"]);

  return (
    <>
      <div className="notification is-success has-text-centered">
        {t("admin:member.modal.add_user.message", { userFirstname, userLastname })}
      </div>

      <div className="field">
        <button
          onClick={() => memberAdd()}
          type="button"
          className={"button is-primary is-wide" + (memberAddLoading ? ' is-loading' : '')}
        >
          {t("admin:member.modal.add_user.button")}
        </button>
      </div>
    </>
  );
};

NewMemberModalAdd.propTypes = {
  userFirstname: PropTypes.string,
  userLastname: PropTypes.string,
  memberAdd: PropTypes.func,
  memberAddLoading: PropTypes.bool
};
export default NewMemberModalAdd;
