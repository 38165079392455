import { gql } from '@apollo/client';

export const CREATE_TYPE = gql`
    mutation CreateType(
        $clubId: Int!, 
        $name: String!, 
        $description: String!, 
        $color: String!,
        $position: Int
    ) {
        CreateType(
            club_id: $clubId, 
            name: $name, 
            description: $description, 
            color: $color,
            position: $position
        ) {
            id
            club_id
            name
            description
            color
            position
        }
}
`;

export const UPDATE_TYPE = gql`
    mutation UpdateType(
        $id: Int!, 
        $name: String!, 
        $description: String!, 
        $color: String!,
        $position: Int
    ) {
        UpdateType(
            id: $id, 
            name: $name, 
            description: $description, 
            color: $color,
            position: $position
        ) {
            id
            name
            description
            color
            position
        }
    }
`;


export const DELETE_TYPE = gql`
    mutation DeleteType($id: Int!) {
        DeleteType(id: $id) 
    }
`;