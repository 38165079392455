import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        name:
            yup.string()
                .required(t('errors:forms.type.fields.name.-100')),
        position:
            yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
        });
}

export default getSchema