import { gql } from '@apollo/client';

export const UPDATE_ROOM_PICTURE = gql`
  mutation UpdateRoomPicture(
    $id: Int!,
    $picture: TypeUpload
  ) {
    UpdateRoomPicture(
      id: $id,
      picture: $picture
    ) 
  }
`;

export const REMOVE_ROOM_PICTURE = gql`
  mutation RemoveRoomPicture($id: Int!) {
    RemoveRoomPicture(id: $id) 
  }
`;