import React from "react";
import { useParams } from "react-router";
import PageAdmin from "structure/PageAdmin";
import PageAdminAbsencesTable from "./PageAdminAbsencesTable";

const PageAdminAbsences = () => {
    const { club_id } = useParams();
    
    return (
        <PageAdmin title="Absences" activeMenu="absences" isWide={true}>
            <PageAdminAbsencesTable
                clubId={club_id}
            />            
        </PageAdmin>
    );
};
export default PageAdminAbsences;
