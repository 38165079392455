import React, { useEffect, useState } from "react";
import moment from "moment";

const getTop = () => {
  const hourStart = parseInt(moment().format("H"));
  const minuteStart = parseInt(moment().format("m"));
  return hourStart * (15 * 4) + minuteStart;
};

export const Timeline = () => {
  var timer;
  const [top, setTop] = useState(getTop());

  useEffect(() => {
    timer = setInterval(() => setTop(getTop()), 1000 * 60);
    return () => clearInterval(timer);
  }, []);

  return <div className="planning-timeline" style={{ top: top + "px" }}></div>;
};
