import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Page from 'structure/Page'
import SearchClubsInput from 'components/search-clubs/SearchClubsInput'
import SearchClubsResults from 'components/search-clubs/SearchClubsResults'


const PageClubSearch = () => {

    const { t } = useTranslation(["pages"]);
    const [query, setQuery] = useState(null)
    const [timestamp, setTimestamp] = useState(Date.now())

    const search = (q) => {
        setQuery(q)
        setTimestamp(Date.now())
    }

    return (
        <Page title={t("search_club.title")}>
            <SearchClubsInput callback={search} />
            <SearchClubsResults query={query} timestamp={timestamp} />
        </Page>
    )
}

export default PageClubSearch