import { gql } from "@apollo/client";

export const CREATE_CLUB = gql`
  mutation CreateClub(
    $slug: String!
    $name: String!
    $timezone: String!
    $address: String!
  ) {
    CreateClub(
      slug: $slug
      name: $name
      timezone: $timezone
      address: $address
    ) {
      id
      slug
      name
      timezone
      address
      workspace_id
    }
  }
`;

export const UPDATE_CLUB = gql`
  mutation UpdateClub(
    $id: Int!
    $slug: String!
    $name: String!
    $timezone: String!
    $address: String
  ) {
    UpdateClub(
      id: $id
      slug: $slug
      name: $name
      timezone: $timezone
      address: $address
    ) {
      id
      slug
      name
      timezone
      address
      workspace_id
    }
  }
`;
