export const getPackageUserStatusClass = (status) => {
    if (status == 'active')
        return "is-success is-light"

    if (status == 'expired')
        return "is-warning is-light"

    if (status == 'disabled')
        return "is-light is-light"
}
