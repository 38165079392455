import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { isMobile } from 'react-device-detect';

import PlanningContent from "./PlanningContent";
import PlanningNav from "./PlanningNav";
import PlanningFilters from "./PlanningFilters";

import { structureSessions } from "./planning.utils";
import { useTranslation } from "react-i18next";

import { usePlanningColumns } from "./usePlanningColumns";

import { PlanningContext } from 'context/PlanningContext';

const getColumns = (dateStart, offset, numberOfColumns, t) => {
    const dateCurrent = moment(dateStart).add(offset, "days");
    const keys = Array.from(Array(numberOfColumns).keys());
    return [...keys].map((index) => {
        const dateNew = moment(dateCurrent, "YYYY-MM-DD").add(index, "days");
        return {
            dayOfWeek: t("day_of_week_short." + dateNew.day()),
            day: dateNew.format("DD"),
            date: dateNew,
        };
    });
};

function Planning({ dateStart, sessions, types, rooms, onClick, onCreate, autoHeight }) {
    const { t } = useTranslation();

    const planningRef = useRef();

    const [typeFilters, setTypeFilters] = useState([]);
    const [roomFilters, setRoomFilters] = useState([]);

    const [numberOfColumns] = usePlanningColumns(planningRef);

    const [columns, setColumns] = useState([]);
    const [currentSessions, setCurrentSessions] = useState({});
    const [offset, setOffset] = useState(0);

    const [isSwipedLeft, setIsSwipedLeft] = useState(false);
    const [isSwipedRight, setIsSwipedRight] = useState(false);

    const planningState = {
        isSwipedLeft,
        setIsSwipedLeft,
        isSwipedRight,
        setIsSwipedRight
    };

    useEffect(() => {
        setCurrentSessions(structureSessions(sessions, typeFilters, roomFilters));
    }, [sessions, typeFilters, roomFilters]);

    useEffect(() => {
        setColumns(getColumns(dateStart, offset, numberOfColumns, t));
    }, [numberOfColumns, offset]);

    const gotoNext = () => {
        setOffset(current => current + 1)
        if (isMobile) {
            setIsSwipedLeft(true);
        }
    }
    const gotoPrevious = () => {
        if (offset > 0) {
            setOffset(current => current - 1)
            if (isMobile) {
                setIsSwipedRight(true);
            }
        }
    }

    return (
        <PlanningContext.Provider value={planningState}>
            <div className="planning" ref={planningRef}>
                <PlanningNav
                    gotoPrevious={gotoPrevious}
                    gotoNext={gotoNext}
                />

                {onCreate && <button
                    onClick={onCreate}
                    className="button is-primary mb-3"
                >
                    {t("global.new")}
                </button>}

                <PlanningFilters
                    types={types}
                    rooms={rooms}
                    roomFilters={roomFilters}
                    setRoomFilters={setRoomFilters}
                    typeFilters={typeFilters}
                    setTypeFilters={setTypeFilters}
                />

                <PlanningContent
                    columns={columns}
                    sessions={currentSessions}
                    onClick={onClick}
                    autoHeight={autoHeight}
                    gotoNext={gotoNext}
                    gotoPrevious={gotoPrevious}
                />
            </div>
        </PlanningContext.Provider>
    );
}

Planning.defaultProps = {
    autoHeight: false,
};

Planning.propTypes = {
    sessions: PropTypes.array,
    types: PropTypes.array,
    rooms: PropTypes.array,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    onClick: PropTypes.func,
    onCreate: PropTypes.func,
    autoHeight: PropTypes.bool,
    getPreviousData: PropTypes.func,
    getNextData: PropTypes.func,
};

export default Planning;
