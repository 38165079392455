import React, { useRef } from "react";
import PlanningHeader from "./PlanningHeader";
import PlanningBody from "./PlanningBody";
import PropTypes from "prop-types";
import { usePlanningBodyHeight } from "./usePlanningBodyHeight";

const PlanningContent = ({ 
  columns, 
  sessions, 
  onClick, 
  autoHeight,
  gotoNext,
  gotoPrevious
}) => {
  const planningHeaderRef = useRef();
  const planningBodyRef = useRef();

  const [planningBodyHeight] = autoHeight ? usePlanningBodyHeight(
    planningHeaderRef,
    planningBodyRef
  ) : [null];

  return (
    <>
      <PlanningHeader reference={planningHeaderRef} columns={columns} />

      <PlanningBody
        reference={planningBodyRef}
        planningBodyHeight={planningBodyHeight}
        columns={columns}
        sessions={sessions}
        onClick={onClick}
        gotoNext={gotoNext}
        gotoPrevious={gotoPrevious}
      />
    </>
  );
};

PlanningContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  sessions: PropTypes.object,
  onClick: PropTypes.func,
  gotoNext: PropTypes.func,
  gotoPrevious: PropTypes.func,
  autoHeight: PropTypes.bool,
};
export default PlanningContent;
