import { gql } from '@apollo/client';

export const CREATE_PLANNING = gql`
    mutation CreatePlanning(
        $roomId: Int!, 
        $typeId: Int!, 
        $userId: Int, 
        $message: String!, 
        $status: String!, 
        $maxRegistrations: Int!, 
        $startDate: DatetimeType, 
        $endDate: DatetimeType, 
        $repeatMode: String, 
        $repeatFrequency: Int, 
        $repeatAt: String, 
        $endOccurencies: Int, 
        $startTime: String, 
        $endTime: String
    ) {
        CreatePlanning(
            room_id: $roomId, 
            type_id: $typeId, 
            user_id: $userId, 
            message: $message, 
            status: $status, 
            max_registrations: $maxRegistrations, 
            start_date: $startDate, 
            end_date: $endDate, 
            repeat_mode: $repeatMode, 
            repeat_frequency: $repeatFrequency, 
            repeat_at: $repeatAt, 
            end_occurencies: $endOccurencies, 
            start_time: $startTime, 
            end_time: $endTime
        ) {
            id
            room_id
            type_id
            user_id
            status
            message
            max_registrations
            start_date
            end_date
            start_time
            end_time
            repeat_mode
            repeat_frequency
            repeat_at
            end_occurencies
        }
    }    
`;

export const UPDATE_PLANNING = gql`
    mutation UpdatePlanning(
        $id: Int!,
        $roomId: Int!, 
        $typeId: Int!, 
        $userId: Int, 
        $message: String!, 
        $status: String!, 
        $maxRegistrations: Int!, 
        $startDate: DatetimeType, 
        $endDate: DatetimeType, 
        $repeatMode: String, 
        $repeatFrequency: Int, 
        $repeatAt: String, 
        $endOccurencies: Int, 
        $startTime: String, 
        $endTime: String
    ) {
        UpdatePlanning(
            id: $id,
            room_id: $roomId, 
            type_id: $typeId, 
            user_id: $userId, 
            message: $message, 
            status: $status, 
            max_registrations: $maxRegistrations, 
            start_date: $startDate, 
            end_date: $endDate, 
            repeat_mode: $repeatMode, 
            repeat_frequency: $repeatFrequency, 
            repeat_at: $repeatAt, 
            end_occurencies: $endOccurencies, 
            start_time: $startTime, 
            end_time: $endTime
        ) {
            id
            room_id
            type_id
            user_id
            status
            message
            max_registrations
            start_date
            end_date
            start_time
            end_time
            repeat_mode
            repeat_frequency
            repeat_at
            end_occurencies
        }
    }
`;

export const DELETE_PLANNING = gql`
    mutation DeletePlanning($id: Int!) {
        DeletePlanning(id: $id) 
    }
`;