import { gql } from "@apollo/client";

export const GET_ABSENCES = gql`
    query Absences($clubId: Int!, $offset: Int!, $limit: Int!, $showFlagged: Boolean, $query: String) {
        Absences(
            club_id: $clubId
            offset: $offset
            limit: $limit
            query: $query,
            show_flagged: $showFlagged
        ) {
            count
            absences {
                user_id
                user_firstname
                user_lastname
                registration_id
                session_id
                session_type
                session_start_datetime
                session_end_datetime
                present_flag
            }
        }
    }
`;
