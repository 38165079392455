import { gql } from '@apollo/client';

export const GET_TYPE = gql`
    query Type($id: Int!) {
        Type(id: $id) {
            id
            club_id
            name
            description
            color
            position
        }
    }
`

export const GET_TYPES = gql`
    query Types($clubId: Int!) {
        Club(id: $clubId) {
            name
        }
        Types(club_id: $clubId) {
            id
            club_id
            name
            description
            color
            position
        }
    }
`