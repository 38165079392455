import React from 'react'
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next'
import { Controller } from "react-hook-form";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

const ConfigFormBoxModeration = ({ config, control, errors }) => {
    const { t } = useTranslation();

    return (
        <div className="field">
            <label className="label is-medium">
                {t("forms:config.fields.member_join_policy.label")}
            </label>
            <div className="control">
                <Controller
                    control={control}
                    name="member_join_policy"
                    defaultValue={config ? config.member_join_policy : ""}
                    as={
                        <RadioGroup>
                            <FormControlLabel
                                value="auto"
                                control={<Radio />}
                                label={t("forms:config.fields.member_join_policy.auto.label")}
                            />
                            <FormControlLabel
                                value="moderate"
                                control={<Radio />}
                                label={t(
                                    "forms:config.fields.member_join_policy.moderate.label"
                                )}
                            />
                        </RadioGroup>
                    }
                />
                <p className="help is-danger">{errors.member_join_policy?.message}</p>
            </div>
        </div>
    )
}

ConfigFormBoxModeration.propTypes = {
    config: PropTypes.object,
    control: PropTypes.any,
    errors: PropTypes.any,
}

export default ConfigFormBoxModeration