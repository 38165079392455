import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        name:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        address:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        zip_code:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        city:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        siret:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        legal_form:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        capital:
            yup.number().typeError(t("errors:forms.company.fields.capital.-200"))
            .required(t('errors:forms.mandatory_field')),

        rcs:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        tva_intra:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

        city_registry:
            yup.string()
                .required(t('errors:forms.mandatory_field')),

    });
}

export default getSchema