import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/client";

import SessionForm from "forms/Session/SessionForm";
import { GET_SESSION, GET_SESSION_FORM } from "graphql/query/session";
import Loading from "components/loading/Loading";

const SessionFormModal = ({ sessionUid, onClose }) => {

    const { club_id: clubId } = useParams();

    const [sessionData, setSessionData] = useState(null)

    const [getSessionData] = useLazyQuery(
        GET_SESSION,
        {
            variables: { uid: sessionUid },
            fetchPolicy: "network-only",
            onCompleted: ({ Session }) => {
                setSessionData(Session)
            }
        }
    );

    const { loading, data: formData } = useQuery(GET_SESSION_FORM, {
        variables: {
            clubId
        }
    });

    useEffect(() => {
        if (sessionUid) {
            getSessionData({
                variables: { uid: sessionUid }
            });
        } else {
            setSessionData(null)
        }
    }, [sessionUid]);


    if (loading || (sessionUid && !sessionData)) {
        return <Loading />
    }

    const config = formData?.Config?.config ? JSON.parse(formData.Config.config) : {}

    return (
        <SessionForm
            sessionUid={sessionUid}
            data={sessionData}
            formData={formData}
            config={config}
            onClose={onClose}
        />
    )
};

SessionFormModal.propTypes = {
    sessionUid: PropTypes.string,
    onClose: PropTypes.func
};

export default SessionFormModal;
