import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router";

import { Helmet } from 'react-helmet-async';

import PageHeader from "structure/PageHeader";
import PageBody from "structure/PageBody";
import PageAdminMenu from "structure/PageAdminMenu";

import MessagePopin from "components/MessagePopin";
import {
    MessagePopinContext,
    MessagePopinState,
    MessagePopinReducer,
} from "context/MessagePopinContext";
import { translate } from "utils/lang";

const PageAdmin = ({
    header,
    body,
    children,
    title,
    metaTitle,
    isWide,
    isTransparent,
    hasNoMargin,
    activeMenu,
}) => {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(MessagePopinReducer, MessagePopinState);

    const { club_id } = useParams();

    title = translate(t, title);
    metaTitle = metaTitle && translate(t, metaTitle);

    // body if surrounded with %
    if (
        body &&
        typeof body === "string" &&
        body.charAt(0) == "%" &&
        body.slice(-1) == "%"
    ) {
        body = body.slice(1, -1);
        body = <Trans t={t}>{body}</Trans>;
    }

    return (
        <>
            {metaTitle && (
                <Helmet>
                    <title>{metaTitle}</title>
                </Helmet>
            )}

            <MessagePopinContext.Provider value={{ state, dispatch }}>
                <MessagePopin />

                <div className="page-admin">
                    <PageBody>
                        <div className="columns is-fullheight">

                            <div className="admin-menu">
                                <PageAdminMenu clubId={club_id} activeMenu={activeMenu} />
                            </div>

                            <div className="column is-10">
                                <div
                                    className={
                                        "page" +
                                        (isWide ? " page-wide" : "") +
                                        (isTransparent ? " page-transparent" : "") +
                                        (hasNoMargin ? " page-no-margin" : "")
                                    }
                                >
                                    {title && !header && <PageHeader>{title}</PageHeader>}
                                    {header && header}
                                    {body ? body : children}
                                </div>
                            </div>
                        </div>
                    </PageBody>
                </div>
            </MessagePopinContext.Provider>
        </>
    );
};

PageAdmin.defaultProps = {
    isWide: false,
    isTransparent: false,
    hasNoMargin: false,
};

PageAdmin.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    metaTitle: PropTypes?.string,
    header: PropTypes.any,
    body: PropTypes.any,
    isWide: PropTypes.bool,
    isTransparent: PropTypes.bool,
    hasNoMargin: PropTypes.bool,
    activeMenu: PropTypes.string,
};

export default PageAdmin;
