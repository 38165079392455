import { gql } from '@apollo/client';

export const ME = gql`
    mutation Me($email: String!, $firstname: String!, $lastname: String!, $timezone: String!, $password: String) {
        Me(email: $email, firstname: $firstname, lastname: $lastname, timezone: $timezone, password: $password) {
            token
            user {
                email
                firstname
                lastname
            }
        }
    }
`;

export const ME_DISABLE_EMAIL_REMINDER = gql`
    mutation MeDisableEmailReminder($disable: Boolean!) {
        MeDisableEmailReminder(disable: $disable)
    }
`;