import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createTheme } from '@mui/material';
// documentation available here: https://material-table.com/#/
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next';

const tableTheme = createTheme({});

export const DEFAULT_PAGE_SIZE = 10

const Table = ({ actions, options, columns, data, handleChangeRowsPerPage, tableRef }) => {

    const { t } = useTranslation()

    const defaultOptions = {
        search: false,
        paging: true,
        showTitle: false,
        toolbar: false,
        pageSizeOptions: [10, 25, 50, 100],
        pageSize: DEFAULT_PAGE_SIZE
    }

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialTable
                localization={{
                    toolbar: {
                        searchPlaceholder: t('global.search')
                    }
                }}
                actions={actions}
                options={{ ...defaultOptions, ...options }}
                columns={columns}
                data={data}
                onChangeRowsPerPage={(rowsPerPage) => handleChangeRowsPerPage && handleChangeRowsPerPage(rowsPerPage)}
                tableRef={tableRef}
            />
        </ThemeProvider>
    )
}

Table.propTypes = {
    options: PropTypes.any,
    actions: PropTypes.any,
    columns: PropTypes.any,
    data: PropTypes.any,
    handleChangeRowsPerPage: PropTypes.func,
    tableRef: PropTypes.any
}

export default Table