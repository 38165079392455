import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import PageAdmin from 'structure/PageAdmin'

import Back from "components/Back";
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import PackageForm from 'forms/Package/PackageForm'

import { GET_CLUBS_OWNED_ADMIN } from 'graphql/query/club'

import { getRoute } from "utils/route";
import { intOrNull } from 'utils/str';

const PageAdminPackageCreate = () => {

    const { t } = useTranslation(["admin"]);
    const { club_id } = useParams();

    const { loading, error, data } = useQuery(GET_CLUBS_OWNED_ADMIN, {
        variables: {
            id: club_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.ClubsOwned === null) {
        return (
            <LoadingError error={{ message: "Club not found" }} />
        )
    }

    return (
        <PageAdmin metaTitle={(t("admin:package.meta.title"))} activeMenu="package">
            <Back to={getRoute("admin_packages", { club_id })} />

            <PackageForm
                workspaceId={data.ClubsOwned[0].workspace_id}
                clubData={data.ClubsOwned}
                currentClubId={intOrNull(club_id)}
            />
        </PageAdmin>
    )
}

export default PageAdminPackageCreate