import React, { useState } from "react";
import PropTypes from 'prop-types'
import { Controller, useForm } from "react-hook-form";

import FormAlert from 'components/form/FormAlert'
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlColorPicker from "components/form/ControlColorPicker";
import FormSubmit from 'components/form/FormSubmit'

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { setSubmitButtonState } from 'utils/form'
import { yupResolver } from '@hookform/resolvers/yup';

import { CREATE_TYPE, UPDATE_TYPE } from "graphql/mutation/type";
import { GET_TYPES } from 'graphql/query/type'

import { catchFormErrors } from "utils/error";
import getSchema from './TypeForm.schema';

const TypeForm = ({ type, clubId }) => {

    const { t } = useTranslation(['translation', 'errors', 'forms'])

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [message, setMessage] = useState('')
    const [currentType, setCurrentType] = useState(type)

    const schema = getSchema(t)

    const [createType] = useMutation(CREATE_TYPE, {
        update: (cache, { data }) => {
            const currentTypes = cache.readQuery({
                query: GET_TYPES,
                variables: { clubId: clubId }
            })
            const newTypes = [...currentTypes.Types, data.CreateType]
            cache.writeQuery({
                query: GET_TYPES,
                variables: { clubId: clubId },
                data: { Types: newTypes }
            })
        },
        onCompleted(data) {
            setIsLoading(false)
            setSubmitButtonState(setIsSubmitted)
            setCurrentType(data.CreateType)
        },
        onError(error) {
            setIsLoading(false)
            setSubmitButtonState(setHasSubmitError)
            catchFormErrors({
                t,
                errorPath: 'type',
                defaultMessagePath: 'errors:messages.form_has_errors',
                error,
                setError,
                setMessage
            })
        }
    })
    const [updateType] = useMutation(UPDATE_TYPE, {
        onCompleted() {
            setIsLoading(false)
            setSubmitButtonState(setIsSubmitted)
        },
        onError(error) {
            setIsLoading(false)
            setSubmitButtonState(setHasSubmitError)
            catchFormErrors({
                t,
                errorPath: 'type',
                defaultMessagePath: 'errors:messages.form_has_errors',
                error,
                setError,
                setMessage
            })
        }
    })

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError
    } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = ({ name, description, color, position }) => {
        setIsLoading(true)
        setMessage('')
        clearErrors()

        if (currentType) {
            updateType({
                variables: {
                    id: currentType.id,
                    name,
                    description,
                    color,
                    position
                }
            })
        } else {
            createType({
                variables: {
                    clubId,
                    name,
                    description,
                    color,
                    position
                }
            })
        }
    }

    const onError = () => {
        setMessage(t('errors:messages.form_has_errors'))
        setSubmitButtonState(setHasSubmitError)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <FormAlert>{message}</FormAlert>

            <Controller
                control={control}
                name="name"
                defaultValue={type ? type.name : ''}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        type="text"
                        value={value}
                        error={errors.name?.message}
                        placeholder={t('forms:type.fields.name.placeholder')}
                    />
                )}
            />

            <Controller
                control={control}
                name="description"
                defaultValue={type ? type.description : ''}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        error={errors.description?.message}
                        placeholder={t('forms:type.fields.description.placeholder')}
                        help={t('forms:type.fields.description.help')}
                    />
                )}
            />

            <Controller
                control={control}
                name="color"
                defaultValue={type ? type.color : ''}
                render={({ onChange, value }) => (
                    <ControlColorPicker
                        onChange={onChange}
                        defaultValue={value}
                        help={t('forms:type.fields.color.help')}
                        error={errors.color?.message}
                        placeholder={t('forms:type.fields.color.placeholder')}
                    />
                )}
            />
            
            <Controller
                control={control}
                name="position"
                defaultValue={type ? type.position : ''}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        type="number"
                        value={value}
                        error={errors.position?.message}
                        placeholder={t('forms:type.fields.position.placeholder')}
                        help={t('forms:type.fields.position.help')}
                    />
                )}
            />

            <FormSubmit isLoading={isLoading} isSubmitted={isSubmitted} hasSubmitError={hasSubmitError}>
                {t('forms:global.btn_save')}
            </FormSubmit>

        </form>
    )
}

TypeForm.propTypes = {
    type: PropTypes.object,
    clubId: PropTypes.number
}

export default TypeForm