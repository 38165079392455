import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import FormSubmit from "components/form/FormSubmit";

import { setSubmitButtonState } from "../../utils/form";
import { nullIfEmpty, dateOrNull } from "../../utils/str";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    CREATE_PLANNING,
    UPDATE_PLANNING,
    DELETE_PLANNING,
} from "graphql/mutation/planning";

import { catchFormErrors } from "utils/error";
import getSchema from "./PlanningForm.schema";
import PlanningFormFields from "./PlanningFormFields";

const PlanningForm = ({ planningId, data, formData, config, onClose }) => {
    const { t } = useTranslation(["translation", "errors", "forms"]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");
    const [hasBeenUpdated, setHasBeenUpdated] = useState(false);

    const [currentPlanningId, setCurrentPlanningId] = useState(planningId)

    const schema = getSchema(t);

    useEffect(() => {
        setCurrentPlanningId(planningId)
    }, [planningId])

    const catchError = (error) => {
        setIsLoading(false);
        setSubmitButtonState(setHasSubmitError);
        catchFormErrors({
            t,
            errorPath: "planning",
            defaultMessagePath: "errors:messages.form_has_errors",
            error,
            setError,
            setMessage,
        });
    }

    // Create planning mutation
    const [createPlanning] = useMutation(CREATE_PLANNING, {
        onCompleted({ CreatePlanning }) {
            setCurrentPlanningId(CreatePlanning.id)
            setIsLoading(false);
            setHasBeenUpdated(true);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error)
        },
    });

    // Update planning mutation
    const [updatePlanning] = useMutation(UPDATE_PLANNING, {
        onCompleted() {
            setIsLoading(false);
            setHasBeenUpdated(true);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error)
        },
    });

    // Delete planning mutation
    const [deletePlanning] = useMutation(DELETE_PLANNING, {
        onCompleted: () => {
            setCurrentPlanningId(null)
            onClose(true);
        },
    });

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError,
        register,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (values) => {

        setIsLoading(true);
        setMessage("");
        clearErrors();

        if (values.repeat_mode == "weekly") {
            values.repeat_at = values.repeat_at_weekly;
        }
        if (values.repeat_mode == "monthly") {
            values.repeat_at = values.repeat_at_monthly;
        }

        if (currentPlanningId) {
            updatePlanning({
                variables: {
                    id: currentPlanningId,
                    roomId: values.room_id,
                    typeId: values.type_id,
                    userId: nullIfEmpty(values.user_id),
                    message: values.message,
                    status: "enabled",
                    maxRegistrations: values.max_registrations,
                    startDate: dateOrNull(values.start_date),
                    endDate: dateOrNull(values.end_date),
                    repeatMode: values.repeat_mode,
                    repeatFrequency: values.repeat_frequency,
                    repeatAt: nullIfEmpty(values.repeat_at),
                    endOccurencies: null,
                    startTime: values.start_time,
                    endTime: values.end_time,
                },
            });
        } else {
            createPlanning({
                variables: {
                    roomId: values.room_id,
                    typeId: values.type_id,
                    userId: nullIfEmpty(values.user_id),
                    message: values.message,
                    status: "enabled",
                    maxRegistrations: values.max_registrations,
                    startDate: dateOrNull(values.start_date),
                    endDate: dateOrNull(values.end_date),
                    repeatMode: values.repeat_mode,
                    repeatFrequency: values.repeat_frequency,
                    repeatAt: nullIfEmpty(values.repeat_at),
                    endOccurencies: nullIfEmpty(values.end_occurencies),
                    startTime: values.start_time,
                    endTime: values.end_time,
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    const onDelete = () => {
        if (confirm(t("forms:planning.messages.confirm_delete"))) {
            deletePlanning({
                variables: { id: currentPlanningId },
            });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <PlanningFormFields
                data={data}
                config={config}
                formData={formData}
                message={message}
                errors={errors}
                control={control}
                register={register}
                setValue={setValue}
                getValues={getValues}
                t={t}
            />

            <footer className="modal-footer">
                <div className="columns">
                    <div className="column">
                        <FormSubmit
                            isLoading={isLoading}
                            isSubmitted={isSubmitted}
                            hasSubmitError={hasSubmitError}
                            classSize={"is-normal"}
                        >
                            {t("forms:global.btn_save")}
                        </FormSubmit>
                    </div>

                    <div className="column">
                        <button
                            onClick={() => {
                                onClose(hasBeenUpdated);
                                setHasBeenUpdated(false);
                            }}
                            type="button"
                            className="button is-info is-wide"
                        >
                            {t("translation:global.close")}
                        </button>
                    </div>

                    <div className="column">
                        {currentPlanningId && (
                            <button
                                onClick={() => onDelete()}
                                type="button"
                                className="button is-danger is-wide"
                            >
                                {t("forms:global.btn_delete")}
                            </button>
                        )}
                    </div>
                </div>


            </footer>
        </form>
    );
};

PlanningForm.propTypes = {
    planningId: PropTypes.number,
    data: PropTypes.object,
    formData: PropTypes.object,
    config: PropTypes.object,
    onClose: PropTypes.func,
};

export default PlanningForm;
