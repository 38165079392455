import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

import { getLang } from "utils/lang";

const getDate = (value) => {
  if (value instanceof Date) {
    return value
  }
  if (value === '' || value === null) {
    return null
  }
  return moment(value).toDate()
}

const getLocaleProps = (lang) => {
  if (lang !== 'fr') return {}

  const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'dd/mm/yyyy'
    }
  }

  return {
    locale: locale,
    calendarStartDay: 1
  }
}

const ControlDatePicker = ({ dateFormat, help, label, selected, error, onChange, placeholderText }) => {
  const hasError = error && error.length > 0;

  const lang = getLang();

  const localeProps = getLocaleProps(lang);

  return (
    <div className="field">
      {label && <div className="label">{label}</div>}
      <div className="control">
        <DatePicker
          {...localeProps}
          dateFormat={dateFormat}
          selected={getDate(selected)}
          onChange={(date) => onChange(moment(date).toISOString())}
          className={"input is-medium" + (hasError ? " is-danger" : "")}
          placeholderText={placeholderText}
        />
      </div>

      {hasError && <p className="help is-danger">{error}</p>}

      {help && <p className="help has-text-grey-light">{help}</p>}
    </div>
  );
};

ControlDatePicker.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  error: PropTypes.string,
  selected: PropTypes.any,
  placeholderText: PropTypes.string,
  onChange: PropTypes.func,
};

export default ControlDatePicker;
