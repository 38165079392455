import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";

import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import ControlSelect from "components/form/ControlSelect";
import FormSubmit from "components/form/FormSubmit";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { setSubmitButtonState } from "utils/form";
import { yupResolver } from "@hookform/resolvers/yup";

import ClubPicture from "components/club/ClubPicture";
import { CREATE_CLUB, UPDATE_CLUB } from "graphql/mutation/club";
import { catchFormErrors } from "utils/error";
import { getDefaultTimezone } from 'utils/time'
import getSchema from "./ClubForm.schema";
import config from 'config/app'

const ClubFormContent = ({ timezones, club, afterCreation }) => {
    const { t } = useTranslation(["translation", "errors", "forms"]);

    const [currentClub, setCurrentClub] = useState(club);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");

    const schema = getSchema(t);

    const catchError = (error) => {
        setIsLoading(false);
        setSubmitButtonState(setHasSubmitError);
        catchFormErrors({
            t,
            errorPath: "club",
            defaultMessagePath: "errors:messages.form_has_errors",
            error,
            setError,
            setMessage,
        });

    }
    const [createClub] = useMutation(CREATE_CLUB, {
        onCompleted(data) {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
            setCurrentClub(data.CreateClub);
            afterCreation && afterCreation(data.CreateClub)
        },
        onError(error) {
            catchError(error)
        },
    });

    const [updateClub] = useMutation(UPDATE_CLUB, {
        onCompleted() {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error)
        },
    });

    const { handleSubmit, control, errors, clearErrors, setError, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    // const slugWatcher = watch('slug', currentClub?.slug ?? '')

    const onSubmit = ({ slug, name, address, timezone }) => {
        setIsLoading(true);
        setMessage("");
        clearErrors();

        if (currentClub) {
            updateClub({
                variables: {
                    id: currentClub.id,
                    slug,
                    name,
                    address,
                    timezone,
                },
            });
        } else {
            createClub({
                variables: {
                    slug,
                    name,
                    address,
                    timezone,
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    const defaultTimezone = getDefaultTimezone()

    const cleanSlug = (slug) => {
        if (!slug) return null;
        return slug.toLowerCase().replace(/\s/g, '-').replace(/'/g, '-').replace(/"/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z-]/g, "");
    }
    const updateSlug = (e) => {
        if (currentClub?.slug) return null;
        setValue("slug", cleanSlug(e.target.value));
    }
    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <FormAlert>{message}</FormAlert>

            {currentClub && (
                <ClubPicture club={currentClub} picture={currentClub?.picture} />
            )}

            <Controller
                control={control}
                name="name"
                defaultValue={currentClub ? currentClub.name : ""}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={(e) => { updateSlug(e); onChange(e)}}
                        type="text"
                        value={value}
                        error={errors.name?.message}
                        placeholder={t("forms:club.fields.name.placeholder")}
                    />
                )}
            />
            <Controller
                control={control}
                name="address"
                defaultValue={currentClub ? currentClub.address : ""}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        error={errors.address?.message}
                        placeholder={t("forms:club.fields.address.placeholder")}
                    />
                )}
            />

            <Controller
                control={control}
                name="timezone"
                defaultValue={currentClub ? currentClub.timezone : defaultTimezone}
                render={({ onChange, value }) => (
                    <ControlSelect
                        onChange={onChange}
                        value={value}
                        values={timezones}
                        error={errors.timezone?.message}
                        placeholder={t("forms:club.fields.timezone.placeholder")}
                    />
                )}
            />

            <Controller
                control={control}
                name="slug"
                defaultValue={currentClub ? currentClub.slug : ''}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        type="text"
                        value={value}
                        error={errors.slug?.message}
                        placeholder={t("forms:club.fields.slug.placeholder")}
                        leftAddons={`https://${config.hostname}/club/`}
                        leftAddonsClass="is-static"
                        label={t("forms:club.fields.slug.label")}
                    />
                )}
            />

            <FormSubmit
                isLoading={isLoading}
                isSubmitted={isSubmitted}
                hasSubmitError={hasSubmitError}
            >
                {t("forms:global.btn_save")}
            </FormSubmit>
        </form>
    );
};

ClubFormContent.propTypes = {
    club: PropTypes.object,
    timezones: PropTypes.arrayOf(PropTypes.string),
    afterCreation: PropTypes.func
};

export default ClubFormContent;
