import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const SessionModalContentRegistrations = ({ counterRegistrations, counterPending, maxRegistrations }) => {
    const { t } = useTranslation()
    
    return (
        <div className="tags has-addons">
            <span className="tag is-info is-clickable">
                {counterRegistrations} / {maxRegistrations} {t("session_modal.attendees")}
            </span>

            {counterPending > 0 &&
                <span className="tag is-warning is-clickable">
                    {counterPending} {t("session_modal.waiting_list")}
                </span>}

            {(counterRegistrations > 0 || counterPending > 0) &&
                <span className="tag is-light is-clickable">
                    {t("session_modal.show")}
                </span>}
        </div>
    )
}

SessionModalContentRegistrations.propTypes = {
    counterRegistrations: PropTypes.number,
    counterPending: PropTypes.number,
    maxRegistrations: PropTypes.number
}

export default SessionModalContentRegistrations