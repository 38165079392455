import React from "react";
import PropTypes from "prop-types";

const getFilterClass = (filters, id) =>
  Array.isArray(filters) && filters.includes(id) ? "is-info" : "is-info is-light";

const toggleFilter = (filters, fn, id) => {
  if (filters.includes(id)) {
    fn(filters.filter((item) => item != id));
  } else {
    fn([...filters, id]);
  }
};

function PlanningFilter({ items, filters, stateFunction }) {
  if (items.length <= 1)
    return null

  return (
    <div className="mr-4">
      {items.map((item, index) => (
        <span
          key={index}
          onClick={() => toggleFilter(filters, stateFunction, item.id)}
          className={
            "tag is-rounded is-clickable mr-2 " +
            getFilterClass(filters, item.id)
          }
        >
          {item.name}
        </span>
      ))}
    </div>
  );
}

PlanningFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  filters: PropTypes.arrayOf(PropTypes.number),
  stateFunction: PropTypes.func,
};

export default PlanningFilter;
