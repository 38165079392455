import { gql } from '@apollo/client';

export const ME = gql`
    query {
        Me {
            email
            firstname
            lastname
            timezone
            picture
            lang
            theme
            disable_email_reminder
            has_workspace
            club_count
        }
    }
`


