import React from "react";
import { useTranslation } from "react-i18next";

import Page from "structure/Page";
import ClubForm from "forms/Club/ClubForm";
import Back from 'components/Back';

import { getRoute } from "utils/route";

const PageAdminClubCreate = () => {

  const { t } = useTranslation(["admin"]);
  return (
    <Page title={t("admin:club.create.title")} metaTitle={t("admin:club.create.meta.title")}>
      <Back to={getRoute("admin_home_persist")} />
      <ClubForm />
    </Page>
  );
};

export default PageAdminClubCreate;
