import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PlanningOrSessionEditModal = ({
    isActive,
    onClose,
    callback,
}) => {
    const modalRef = useRef(null);

    const { t } = useTranslation(["translation"]);

    useEffect(() => {
        isActive
            ? modalRef.current.classList.add("is-active")
            : modalRef.current.classList.remove("is-active");
    }, [isActive]);

    return (
        <div ref={modalRef} className="modal">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{t("planning_or_session_edit_modal.title")}</p>
                    <button className="delete" aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                    <div className="columns">
                        <div className="column">
                            <a className="button is-primary is-wide is-medium" onClick={() => callback("planning")}>
                                {t("planning_or_session_edit_modal.items.item1")}
                            </a>
                        </div>
                        <div className="column">
                            <a className="button is-primary is-wide is-medium" onClick={() => callback("session")}>
                                {t("planning_or_session_edit_modal.items.item2")}
                            </a>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={() => {
                            onClose(false);
                        }}
                        type="button"
                        className="button is-info"
                    >
                        {t("global.cancel")}
                    </button>
                </footer>
            </div>
        </div>
    );
};

PlanningOrSessionEditModal.propTypes = {
    isActive: PropTypes.bool,
    onClose: PropTypes.func,
    callback: PropTypes.func,
};

export default PlanningOrSessionEditModal;
