import React, { useState, useContext } from "react";
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { MessagePopinContext } from 'context/MessagePopinContext';

import FormAlert from "components/form/FormAlert";
import FormSubmit from "components/form/FormSubmit";

import { useMutation } from "@apollo/client";
import { setSubmitButtonState } from "utils/form";
import { catchFormErrors } from "utils/error";

import { CREATE_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE } from "graphql/mutation/package";

import getSchema from "./PackageForm.schema";
import { GET_PACKAGES } from "graphql/query/package";

import { intOrNull, floatOrNull } from "utils/str";
import { getRoute } from 'utils/route';
import PackageFormTabInfos from "./PackageFormTabInfos";

import { getTaxedAmount, boolToBin } from './utils';
import PackageFormTabLimitations from "./PackageFormTabLimitations";

const PackageForm = ({ workspaceId, packageData, clubData, currentClubId }) => {

    const { t } = useTranslation(["translation", "errors", "forms"]);
    const navigate = useNavigate();
    const messagePopinContext = useContext(MessagePopinContext)

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");
    const [currentPackage, setCurrentPackage] = useState(packageData);
    const [activeTab, setActiveTab] = useState("infos")

    const [currentLimitationTypeIds, setCurrentLimitationTypeIds] = useState((packageData && packageData.limitations && packageData.limitations.type_ids) ? packageData.limitations.type_ids.join(',') : '')
    const [currentLimitationRoomIds, setCurrentLimitationRoomIds] = useState((packageData && packageData.limitations && packageData.limitations.room_ids) ? packageData.limitations.room_ids.join(',') : '')
    const [currentLimitationClubIds, setCurrentLimitationClubIds] = useState((packageData && packageData.limitations && packageData.limitations.club_ids) ? packageData.limitations.club_ids.join(',') : '')

    const schema = getSchema(t);

    const setMessagePopin = (type, path) => {
        messagePopinContext.dispatch({ 'type': type, 'message': t(path) })
    }

    const catchError = (error) => {
        setIsLoading(false);
        setSubmitButtonState(setHasSubmitError);
        catchFormErrors({
            t,
            errorPath: "package",
            defaultMessagePath: "errors:messages.form_has_errors",
            error,
            setError,
            setMessage,
        });
    }

    const [createPackage] = useMutation(CREATE_PACKAGE, {
        update: (cache, { data }) => {
            const currentPackages = cache.readQuery({
                query: GET_PACKAGES,
                variables: { workspaceId }
            })
            const newPackages = [...currentPackages.Packages, data.CreatePackage]
            cache.writeQuery({
                query: GET_PACKAGES,
                variables: { workspaceId },
                data: { Packages: newPackages }
            })
        },
        onCompleted(data) {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
            setCurrentPackage(data.CreatePackage);
            window.history.replaceState(null, 'Pack', `/admin/club/${currentClubId}/package/${data.CreatePackage.id}`)
        },
        onError(error) { catchError(error) },
    });

    const [updatePackage] = useMutation(UPDATE_PACKAGE, {
        onCompleted() {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) { catchError(error) },
    });

    const [deletePackage] = useMutation(DELETE_PACKAGE, {
        update: (cache) => {
            const currentPackages = cache.readQuery({
                query: GET_PACKAGES,
                variables: { workspaceId: currentPackage.workspace_id }
            })

            const newPackages = currentPackages.Packages.filter(item => item.id !== currentPackage.id)

            cache.writeQuery({
                query: GET_PACKAGES,
                variables: { workspaceId: currentPackage.workspace_id },
                data: { Packages: newPackages }
            })
        },
        onCompleted: () => {
            navigate(getRoute("admin_packages", { club_id: currentClubId }));
        },
        onError(error) {
            error.graphQLErrors.map(item => {
                setMessagePopin('danger', t("errors:forms.package.messages." + item.message));
            })

        }
    });

    const { handleSubmit, control, errors, clearErrors, setError, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = ({
        status,
        title,
        description,
        rule_unlimited,
        rule_sessions,
        rule_sessions_per_week,
        rule_sessions_per_month,
        rule_simultaneous_sessions,
        amount,
        tax_percentage,
        payment_frequency,
        duration_days,
        is_default_on_join
    }) => {
        setIsLoading(true);
        setMessage("");
        clearErrors();

        const limitationTypeIds = currentLimitationTypeIds != '' ? currentLimitationTypeIds.split(',') : null;
        const limitationRoomIds = currentLimitationRoomIds != '' ? currentLimitationRoomIds.split(',') : null;
        const limitationClubIds = currentLimitationClubIds != '' ? currentLimitationClubIds.split(',') : null;

        const taxed_amount = getTaxedAmount(amount, tax_percentage);

        const rules = {
            unlimited: !!rule_unlimited,
            sessions: intOrNull(rule_sessions),
            sessions_per_week: intOrNull(rule_sessions_per_week),
            sessions_per_month: intOrNull(rule_sessions_per_month),
            simultaneous_sessions: intOrNull(rule_simultaneous_sessions)
        }

        const limitations = {
            club_ids: limitationClubIds,
            room_ids: limitationRoomIds,
            type_ids: limitationTypeIds
        }

        if (currentPackage) {
            updatePackage({
                variables: {
                    id: currentPackage.id,
                    status,
                    title,
                    description,
                    rules,
                    limitations,
                    amount,
                    taxPercentage: floatOrNull(tax_percentage),
                    taxedAmount: floatOrNull(taxed_amount),
                    paymentFrequency: payment_frequency,
                    durationDays: intOrNull(duration_days),
                    isDefaultOnJoin: boolToBin(is_default_on_join, 0)
                },
            });
        } else {
            createPackage({
                variables: {
                    workspaceId,
                    status,
                    title,
                    description,
                    rules,
                    limitations,
                    amount,
                    taxPercentage: floatOrNull(tax_percentage),
                    taxedAmount: floatOrNull(taxed_amount),
                    paymentFrequency: payment_frequency,
                    durationDays: intOrNull(duration_days),
                    isDefaultOnJoin: boolToBin(is_default_on_join, 0)
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    const onDelete = () => {
        if (confirm(t("forms:package.messages.confirm_delete"))) {
            deletePackage({
                variables: { id: currentPackage.id }
            })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormAlert>{message}</FormAlert>

                <div className="tabs is-boxed is-medium">
                    <ul>
                        <li onClick={() => setActiveTab("infos")} className={activeTab == "infos" ? "is-active" : ""}>
                            <a>Infos</a>
                        </li>
                        <li onClick={() => setActiveTab("limitations")} className={activeTab == "limitations" ? "is-active" : ""}>
                            <a>Limitations</a>
                        </li>
                    </ul>
                </div>

                <div className="mb-3">
                    <div className={activeTab == "infos" ? "" : "is-hidden"}>
                        <PackageFormTabInfos
                            packageData={packageData}
                            control={control}
                            errors={errors}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </div>

                    <div className={activeTab == "limitations" ? "" : "is-hidden"}>
                        <PackageFormTabLimitations
                            clubData={clubData}
                            currentLimitationTypeIds={currentLimitationTypeIds}
                            setCurrentLimitationTypeIds={setCurrentLimitationTypeIds}
                            currentLimitationRoomIds={currentLimitationRoomIds}
                            setCurrentLimitationRoomIds={setCurrentLimitationRoomIds}
                            currentLimitationClubIds={currentLimitationClubIds}
                            setCurrentLimitationClubIds={setCurrentLimitationClubIds}
                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-three-quarters">
                        <FormSubmit
                            isLoading={isLoading}
                            isSubmitted={isSubmitted}
                            hasSubmitError={hasSubmitError}
                        >
                            {t("forms:global.btn_save")}
                        </FormSubmit>
                    </div>
                    <div className="column">
                        {currentPackage && <button onClick={() => onDelete()} type="button" className="button is-danger is-medium is-fullwidth">{t("forms:global.btn_delete")}</button>}
                    </div>
                </div>

            </form>
        </>
    )
}

PackageForm.propTypes = {
    workspaceId: PropTypes.number,
    packageData: PropTypes.object,
    clubData: PropTypes.array,
    currentClubId: PropTypes.number
}

export default PackageForm