import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Page from 'structure/Page'
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import Nl2br from 'components/Nl2br'

import { GET_PACKAGE_USER } from 'graphql/query/package-user'

const PagePackageUser = () => {

    const { t } = useTranslation(['pages'])
    const { package_user_id } = useParams()

    const getPackageUserSessionsCount = (packageUser) => {
        if (packageUser.rules.unlimited) {
            return t("package_user.rule_unlimited")
        } else {
            return `${packageUser.sessions_counter} / ${packageUser.rules.sessions}`
        }
    }
    var { loading, error, data } = useQuery(GET_PACKAGE_USER, {
        variables: {
            id: package_user_id
        }
    })

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <LoadingError error={error} />
    }

    const packageUser = data.PackageUser;

    return (
        <Page title="Mon abonnement" metaTitle="Mon abonnement">
            <h3 className="is-size-3">{packageUser.title} </h3>

            <div className="block"><span className="tag">{t("package_user.status." + packageUser.status)}</span></div>

            {packageUser.description && <div className="block"><Nl2br>{packageUser.description}</Nl2br></div>}



            <table className="table is-striped is-bordered">
                <tbody>
                    <tr>
                        <td>Date de début de l&apos;abonnement</td>
                        <td>{packageUser.date_start}</td>
                    </tr>
                    <tr>
                        <td>Date de fin de l &apos;abonnement</td>
                        <td>{packageUser.date_end}</td>
                    </tr>

                    <tr>
                        <td>{t("package_user.sessions_label")}</td>
                        <td>{getPackageUserSessionsCount(packageUser)}</td>
                    </tr>

                    {packageUser.rules.sessions_per_week &&
                        <tr>
                            <td>{t("package_user.sessions_per_week_label")}</td>
                            <td>{packageUser.rules.sessions_per_week}</td>
                        </tr>}

                    {packageUser.rules.sessions_per_month &&
                        <tr>
                            <td>{t("package_user.sessions_per_month_label")}</td>
                            <td>{packageUser.rules.sessions_per_month}</td>
                        </tr>}

                    {packageUser.rules.simultaneous_sessions &&
                        <tr>
                            <td>{t("package_user.simultaneous_sessions_label")}</td>
                            <td>{packageUser.rules.simultaneous_sessions}</td>
                        </tr>}
                </tbody>
            </table>

            <table className="table is-bordered is-fullwidth">
                <thead>
                    <tr>
                        <td width="25%">{t("package_user.amount_label")}</td>
                        <td width="25%">{t("package_user.tax_percentage_label")}</td>
                        <td width="25%">{t("package_user.taxed_amount_label")}</td>
                        <td width="25%">{t("package_user.payment_frequency_label")}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{packageUser.amount} €</td>
                        <td>{packageUser.tax_percentage} %</td>
                        <td>{packageUser.taxed_amount} €</td>
                        <td>{t("package_user.payment_frequency." + packageUser.payment_frequency)}</td>
                    </tr>
                </tbody>
            </table>

        </Page>
    )
}

export default PagePackageUser