import React, { useContext } from "react";
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import PropTypes from 'prop-types'

import Page from 'structure/Page'

// Pages
import PageHome from 'pages/Home/PageHome'
import PageSignin from 'pages/PageSignin'
import PageSignup from 'pages/PageSignup'
import PageSignupValidate from 'pages/PageSignupValidate'
import PageSignupFinalize from 'pages/PageSignupFinalize'
import PageSignout from 'pages/PageSignout'
import PagePasswordForgot from 'pages/PagePasswordForgot'
import PagePasswordReset from 'pages/PagePasswordReset'
import PageClubs from 'pages/PageClubs'
import PageClub from 'pages/Club/PageClub'
import PagePackageUser from 'pages/PagePackageUser'
import PageProfil from 'pages/profil/PageProfil'
import PageProfilNotificationsEmailReminder from "pages/profil/PageProfilNotificationsEmailReminder";
import PageMySessions from 'pages/my-sessions/PageMySessions'
import PageSession from "pages/PageSession";
import PageOnboard from "pages/PageOnboard";
import PageClubCreation from "pages/PageClubCreation";
import PageClubSearch from "pages/PageClubSearch";
import PageContact from "pages/PageContact";

import PageAdminHome from "pages/admin/PageAdminHome";
import PageAdminClubHome from "pages/admin/Club/PageAdminClubHome";
import PageAdminClubEdit from "pages/admin/Club/PageAdminClubEdit";
import PageAdminClubCreate from "pages/admin/Club/PageAdminClubCreate";
import PageAdminCompany from "pages/admin/Company/PageAdminCompany";
import PageAdminRooms from "pages/admin/Room/PageAdminRooms";
import PageAdminRoomCreate from "pages/admin/Room/PageAdminRoomCreate";
import PageAdminRoomEdit from "pages/admin/Room/PageAdminRoomEdit";
import PageAdminTypes from "pages/admin/Type/PageAdminTypes";
import PageAdminTypeCreate from "pages/admin/Type/PageAdminTypeCreate";
import PageAdminTypeEdit from "pages/admin/Type/PageAdminTypeEdit";
import PageAdminPlannings from "pages/admin/PageAdminPlannings";
import PageAdminPackages from "pages/admin/Package/PageAdminPackages";
import PageAdminPackageCreate from "pages/admin/Package/PageAdminPackageCreate";
import PageAdminPackageEdit from "pages/admin/Package/PageAdminPackageEdit";
import PageAdminMembers from "pages/admin/Members/PageAdminMembers";
import PageAdminMember from "pages/admin/PageAdminMember";
import PageAdminPackageUserEdit from "pages/admin/PackageUser/PageAdminPackageUserEdit";
import PageAdminMemberModerate from "pages/admin/PageAdminMemberModerate";
import PageAdminConfig from "pages/admin/PageAdminConfig";
import PageAdminAbsences from "pages/admin/Absences/PageAdminAbsences";

import { UserContext } from 'context/UserContext'

import { hasAccessToken } from 'utils/auth'
import { isHosted } from 'utils/app'
import { getRoute } from 'utils/route'
import Page404 from "pages/Page404";

function RequireAuth({ children }) {
    if (hasAccessToken()) {
        return children
    }

    return <Navigate to={getRoute("signin")} />
}
RequireAuth.propTypes = {
    children: PropTypes.any
}

const getHomePage = (isConnected) => {
    if (isConnected) {
        if (isHosted()) {
            return <PageClub />
        } else {
            return <PageClubs />
        }
    }
    return <PageHome />
}

const AppRoutes = () => {

    const { isConnected } = useContext(UserContext)

    return (
        <Routes>

            {/* public pages */}
            <Route path={getRoute("home")} element={
                getHomePage(isConnected)
            } />

            <Route path={getRoute("signup")} element={
                <PageSignup />
            } />
            <Route path={getRoute("signup_done")} element={
                <Page
                    title="%pages:signup_done.title%"
                    body="%pages:signup_done.body%"
                    metaTitle="%pages:signup_done.meta.title%"
                    img="/img/gifs/signup.gif" />
            } />
            <Route path={getRoute("signup_validate")} element={
                <PageSignupValidate />
            } />
            <Route path={getRoute("signup_validate_done")} element={
                <Page
                    title="%pages:signup_validate_done.title%"
                    body="%pages:signup_validate_done.body%"
                    metaTitle="%pages:signup_validate_done.meta.title%"
                    linkTo={getRoute("signin")}
                    linkMsg="%pages:signup_validate_done.signin_link%" />
            } />
            <Route path={getRoute("signup_finalize")} element={
                <PageSignupFinalize />
            } />
            <Route path={getRoute("signup_finalize_done")} element={
                <Page
                    title="%pages:signup_validate_done.title%"
                    body="%pages:signup_validate_done.body%"
                    metaTitle="%pages:signup_validate_done.meta.title%"
                    linkTo={getRoute("signin")}
                    linkMsg="%pages:signup_validate_done.signin_link%" />
            } />

            <Route path={getRoute("signin")} element={
                <PageSignin />
            } />

            <Route path={getRoute("signout")} element={
                <PageSignout />
            } />

            <Route path={getRoute("password_forgot")} element={
                <PagePasswordForgot />
            } />
            <Route path={getRoute("password_forgot_done")} element={
                <Page
                    title="%pages:password_forgot_done.title%"
                    body="%pages:password_forgot_done.body%"
                    metaTitle="%pages:password_forgot_done.meta.title%"
                />
            } />

            <Route path={getRoute("password_reset")} element={
                <PagePasswordReset />
            } />
            <Route path={getRoute("password_reset_done")} element={
                <Page
                    title="%pages:password_reset_done.title%"
                    body="%pages:password_reset_done.body%"
                    metaTitle="%pages:password_reset_done.meta.title%"
                    linkTo={getRoute("signin")}
                    linkMsg='%pages:password_reset_done.signin_link%' />
            } />

            {/* private users pages */}
            <Route path={getRoute("contact")} element={
                <RequireAuth>
                    <PageContact />
                </RequireAuth>
            } />
            <Route path={getRoute("onboard")} element={
                <RequireAuth>
                    <PageOnboard />
                </RequireAuth>
            } />
            <Route path={getRoute("club_creation")} element={
                <RequireAuth>
                    <PageClubCreation />
                </RequireAuth>
            } />
            <Route path={getRoute("club_search")} element={
                <RequireAuth>
                    <PageClubSearch />
                </RequireAuth>
            } />
            <Route path={getRoute("profil")} element={
                <RequireAuth>
                    <PageProfil />
                </RequireAuth>
            } />
            <Route path={getRoute("profil_notifications_email_reminder")} element={
                <RequireAuth>
                    <PageProfilNotificationsEmailReminder />
                </RequireAuth>
            } />
            <Route path={getRoute("my_sessions")} element={
                <RequireAuth>
                    <PageMySessions />
                </RequireAuth>
            } />
            <Route path={getRoute("package_user")} element={
                <RequireAuth>
                    <PagePackageUser />
                </RequireAuth>
            } />
            <Route path={getRoute("clubs")} element={
                <RequireAuth>
                    <PageClubs />
                </RequireAuth>
            } />
            <Route path={getRoute("club")} element={
                <RequireAuth>
                    <PageClub />
                </RequireAuth>
            } />
            <Route path={getRoute("session")} element={
                <RequireAuth>
                    <PageSession />
                </RequireAuth>
            } />


            {/* private admin pages */}
            <Route path={getRoute('admin_home')} element={
                <RequireAuth>
                    <PageAdminHome />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_club_home')} element={
                <RequireAuth>
                    <PageAdminClubHome />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_club_create')} element={
                <RequireAuth>
                    <PageAdminClubCreate />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_club_edit')} element={
                <RequireAuth>
                    <PageAdminClubEdit />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_company')} element={
                <RequireAuth>
                    <PageAdminCompany />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_rooms')} element={
                <RequireAuth>
                    <PageAdminRooms />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_room_create')} element={
                <RequireAuth>
                    <PageAdminRoomCreate />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_room_edit')} element={
                <RequireAuth>
                    <PageAdminRoomEdit />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_types')} element={
                <RequireAuth>
                    <PageAdminTypes />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_type_create')} element={
                <RequireAuth>
                    <PageAdminTypeCreate />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_type_edit')} element={
                <RequireAuth>
                    <PageAdminTypeEdit />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_plannings')} element={
                <RequireAuth>
                    <PageAdminPlannings />
                </RequireAuth>
            } />

            <Route path={getRoute('admin_members')} element={
                <RequireAuth>
                    <PageAdminMembers />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_member')} element={
                <RequireAuth>
                    <PageAdminMember />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_package_user')} element={
                <RequireAuth>
                    <PageAdminPackageUserEdit />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_member_moderate')} element={
                <RequireAuth>
                    <PageAdminMemberModerate />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_packages')} element={
                <RequireAuth>
                    <PageAdminPackages />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_package_create')} element={
                <RequireAuth>
                    <PageAdminPackageCreate />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_package_edit')} element={
                <RequireAuth>
                    <PageAdminPackageEdit />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_config')} element={
                <RequireAuth>
                    <PageAdminConfig />
                </RequireAuth>
            } />
            <Route path={getRoute('admin_absences')} element={
                <RequireAuth>
                    <PageAdminAbsences />
                </RequireAuth>
            } />

            <Route path={getRoute('error_404')} element={
                <Page404 />
            } />
            <Route path="*" element={
                <Page404 />
            } />

        </Routes>
    )
}

export default AppRoutes