import { gql } from "@apollo/client";

export const GET_PACKAGE_USER_BY_CLUB = gql`
    query Club($id: Int!) {
        Club(id: $id) {
            Member {
                PackageUserActive {
                    id
                    title
                    description
                    status
                    rules {
                        unlimited
                        sessions
                    }
                    date_start
                    date_end
                    sessions_counter
                }
            }
        }
    }
`

export const GET_PACKAGE_USER = gql`
    query PackageUser($id: Int!) {
        PackageUser(id: $id) {
            id
            status
            title
            description
            rules {
                unlimited
                sessions
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            date_start
            date_end
            sessions_counter
        }
    }
`

export const GET_PACKAGE_USER_ADMIN = gql`
    query PackageUser($id: Int!) {
        PackageUser(id: $id) {
            id
            workspace_id
            user_id
            status
            title
            description
            rules {
                unlimited
                sessions
                sessions_per_week
                sessions_per_month
                simultaneous_sessions
            }
            limitations {
                club_ids
                type_ids
                room_ids
            }
            amount
            tax_percentage
            taxed_amount
            payment_frequency
            date_start
            date_end
        }
        TypesOwned {
            id
            club_id
            name
            description
            color
        }
        ClubsOwned {
            id
            slug
            name
            address
            url
            picture
            Rooms {
                id
                name
            }
        }           
    }
`