import { gql } from "@apollo/client";

export const REGISTER_SESSION = gql`
    mutation RegisterSession($uid: String!) {
        RegisterSession(uid: $uid) {
            uid
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                status
            }
            Registrations {
                firstname
                lastname
                status
            }
        }
    }
`;

export const REGISTER_QUEUE_SESSION = gql`
    mutation RegisterQueueSession($uid: String!) {
        RegisterQueueSession(uid: $uid) {
            uid
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                status
            }
            Registrations {
                firstname
                lastname
                status
            }
        }
    }
`;

export const REGISTER_CANCEL_SESSION = gql`
    mutation RegisterCancelSession($uid: String!) {
        RegisterCancelSession(uid: $uid) {
            uid
            max_registrations
            counter_registrations
            counter_pending
            Registration {
                status
            }
            Registrations {
                firstname
                lastname
                status
            }
        }
    }
`;

export const REGISTRATION_PRESENT = gql`
    mutation RegistrationPresent($id: Int!, $present: Boolean) {
        RegistrationPresent(id: $id, present: $present) {
            id
            user_id
            session_id
            status
            reminded
            present
        }
    }
`;

export const ADMIN_REGISTER_CANCEL_SESSION = gql`
    mutation AdminRegisterCancelSession($registrationId: Int!) {
        AdminRegisterCancelSession(registration_id: $registrationId) {
            Registration {
                id
                status
            }
        }
    }
`;

export const ADMIN_REGISTER_VALID_SESSION = gql`
    mutation AdminRegisterValidSession($registrationId: Int!) {
        AdminRegisterValidSession(registration_id: $registrationId) {
            Registration {
                id
                status
            }
        }
    }
`;