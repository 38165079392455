import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { GET_SESSIONS } from "graphql/query/session";

import Page from 'structure/Page';
import PageClubPlanningHeader from './PageClubPlanningHeader'
import Planning from "components/planning/Planning";
import Loading from "components/loading/Loading";
import SessionModal from "modal/SessionModal/SessionModal";
import Modal from "modal/Modal";

import { ClubMemberContext } from "context/ClubMemberContext";

const PageClubPlanning = ({ club }) => {

    const { t } = useTranslation(["pages"]);

    const today = moment();
    const currentDateStart = today.format("YYYY-MM-DD");
    const currentDateEnd = today.add(14, "days").format("YYYY-MM-DD");

    const [memberStatus, setMemberStatus] = useState(club?.Member?.status ?? 'default')
    const [dateStart] = useState(currentDateStart);
    const [dateEnd] = useState(currentDateEnd);
    const [uid, setUid] = useState(null);
    const [modalIsActive, setModalIsActive] = useState(false);

    const { loading, error, data, refetch } = useQuery(GET_SESSIONS, {
        variables: {
            clubId: club.id,
            dateStart,
            dateEnd,
        },
    });

    const ClubMemberState = {
        memberStatus,
        setMemberStatus
    }

    if (loading) {
        return <Loading />;
    }

    const onClick = (session) => {
        setUid(session.uid);
        setModalIsActive(true);
    };

    const onModalClose = (hasBeenUpdated) => {
        hasBeenUpdated && refetch();
        setModalIsActive(false);
    }

    const getJoinMessage = (memberStatus) => {

        const sectionClass = memberStatus === 'pending' ? 'is-primary' : 'is-info'
        if (error || data.Sessions === null) {
            return (
                <section className={`hero ${sectionClass}`}>
                    <div className="hero-body">
                        <p className="title">
                            {t(`pages:club.planning.join.${memberStatus}_title`)}
                        </p>
                        <p className="subtitle">
                            {t(`pages:club.planning.join.${memberStatus}_subtitle`)}
                        </p>
                    </div>
                </section>
            )
        }
    }
    const getPagePlanning = () => {
        if (!error && data?.Sessions) {
            return (

                <Page metaTitle={club.name} isWide={true}>

                    <Modal open={modalIsActive} isModalContent={true}>
                        <SessionModal
                            uid={uid}
                            onClose={onModalClose}
                        />
                    </Modal>

                    <Planning
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        sessions={data.Sessions}
                        types={data.Types}
                        rooms={data.Rooms}
                        onClick={onClick}
                    />
                </Page>
            )
        }
    }

    return (
        <>
            <ClubMemberContext.Provider value={ClubMemberState}>
                <PageClubPlanningHeader club={club} />
                {getJoinMessage(memberStatus)}
                {getPagePlanning()}
            </ClubMemberContext.Provider>
        </>
    );
};

PageClubPlanning.propTypes = {
    club: PropTypes.object.isRequired,
};

export default PageClubPlanning;
