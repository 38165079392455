import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { useForm, Controller } from "react-hook-form";

import FormPanel from 'components/form/FormPanel'
import FormAlert from 'components/form/FormAlert'
import ControlText from 'components/form/ControlText'
import FormSubmit from 'components/form/FormSubmit'
import FormLinks from 'components/form/FormLinks'
import { catchFormErrors } from 'utils/error'
import { setSubmitButtonState } from 'utils/form'

// import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client'
import { PASSWORD_FORGOT } from 'graphql/mutation/password-forgot'

import { routes } from 'routes'
// import getSchema from './PasswordForgotForm.schema'

const PasswordForgotForm = () => {

    const { t } = useTranslation(['translation', 'errors', 'forms']);

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [message, setMessage] = useState('')

    // const schema = getSchema(t)

    const [passwordForgot] = useMutation(PASSWORD_FORGOT, {
        onCompleted() {
            setIsLoading(false)
            setIsSubmitted(true)
        },
        onError(error) {
            setIsLoading(false)
            setSubmitButtonState(setHasSubmitError)
            catchFormErrors({
                t,
                errorPath: 'password_forgot',
                defaultMessagePath: 'errors:messages.form_has_errors',
                error,
                setError,
                setMessage
            })
        }
    })

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError
    } = useForm({
        // resolver: yupResolver(schema)
    });

    const onSubmit = ({ email }) => {
        setIsLoading(true)
        setMessage('')
        clearErrors()

        passwordForgot({
            variables: {
                email
            },
            context: {
                isAnonymous: true
            }
        })
    }
    const onError = () => {
        setMessage(t('errors:messages.form_has_errors'))
        setSubmitButtonState(setHasSubmitError)
    }

    const form = () => (
        <FormPanel title="%forms:password_forgot.title%">
            <form onSubmit={handleSubmit(onSubmit, onError)}>

                <FormAlert>{message}</FormAlert>

                <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <ControlText
                            onChange={onChange}
                            icon={faEnvelope}
                            error={errors.email?.message}
                            type="email"
                            value={value}
                            placeholder={t('forms:password_forgot.fields.email.placeholder')}
                            autoFocus={true}
                        />
                    )}
                />

                <FormSubmit isLoading={isLoading} isSubmitted={isSubmitted} hasSubmitError={hasSubmitError}>{t('forms:global.btn_send')}</FormSubmit>

                <FormLinks
                    link1={routes.signin}
                    txt1="%forms:password_forgot.signin_link%"
                    link2={routes.signup}
                    txt2="%forms:password_forgot.signup_link%"
                />

            </form>
        </FormPanel>

    )

    return isSubmitted ? <Navigate to={routes.password_forgot_done} /> : form()
}

export default PasswordForgotForm