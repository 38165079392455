import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        title:
            yup.string()
                .required(t('errors:forms.package.fields.title.-100')),

        status:
            yup.string()
                .required(t('errors:forms.package.fields.status.-100')),

        payment_frequency:
            yup.string()
                .required(t('errors:forms.package.fields.payment_frequency.-100')),

        amount:
            yup.string()
                .required(t('errors:forms.package.fields.amount.-100')),

        tax_percentage:
            yup.string()
                .required(t('errors:forms.package.fields.tax_percentage.-100')),

        // is_default_on_join:
        //     yup.number().test('is boolean', 'Incorrect value', (value) => value === 0 || value === 1),

        // rule_unlimited:
        //     yup.boolean(),

        rule_sessions:
            yup.string()
                .when("rule_unlimited", {
                    is: false,
                    then: yup.string()
                        .required(t('errors:forms.package.fields.rule_sessions.-100')).nullable()
                })
    });
}

export default getSchema