import { useLayoutEffect, useState } from "react";

const EXTRA_LARGE_NUMBER_OF_COLUMNS = 7;
const LARGE_NUMBER_OF_COLUMNS = 5;
const MID_NUMBER_OF_COLUMNS = 3;
const SMALL_NUMBER_OF_COLUMNS = 1;

const EXTRA_LARGE_WIDTH = 1600;
const LARGE_WIDTH = 1024;
const SMALL_WIDTH = 769;

const RENDER_DELAY = 300;

export const usePlanningColumns = (ref) => {
    const [numberOfColumns, setNumberOfColumns] = useState(0);

    useLayoutEffect(() => {
        function updateNumberOfColumns() {
            const width = ref && ref.current ? ref.current.offsetWidth : 1024;
            if (width < SMALL_WIDTH) {
                setNumberOfColumns(SMALL_NUMBER_OF_COLUMNS);
            } else if (width >= SMALL_WIDTH && width < LARGE_WIDTH) {
                setNumberOfColumns(MID_NUMBER_OF_COLUMNS);
            } else if (width >= LARGE_WIDTH && width < EXTRA_LARGE_WIDTH) {
                setNumberOfColumns(LARGE_NUMBER_OF_COLUMNS);
            } else {
                setNumberOfColumns(EXTRA_LARGE_NUMBER_OF_COLUMNS);
            }
        }

        window.addEventListener("resize", updateNumberOfColumns);
        setTimeout(() => updateNumberOfColumns(), RENDER_DELAY);

        return () =>
            window.removeEventListener("resize", updateNumberOfColumns);
    }, []);

    return [numberOfColumns];
};
