import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types'

import { ClubMemberContext } from 'context/ClubMemberContext';

import PackageUserInfo from 'components/PackageUserInfo';
import ClubJoinButton from 'components/club/ClubJoinButton';

import { getRoute } from 'utils/route';

const PageClubPlanningHeader = ({ club }) => {

    const navigate = useNavigate();

    const { setMemberStatus } = useContext(ClubMemberContext);

    const onJoinAccept = () => {
        navigate(getRoute("clubs"))
    }
    const onJoinWait = () => {
        // navigate(getRoute("clubs"))
        setMemberStatus('pending');
    }

    return (
        <div className="page-club-header">
            <div className="columns">
                <div className="column">
                    <h4 className="is-size-3">{club.name}</h4>
                </div>
                <div className="column has-text-right has-text-left-mobile">
                    <ClubJoinButton
                        onAccept={() => onJoinAccept()}
                        onWait={() => onJoinWait()}
                        clubId={club.id}
                    />
                    <PackageUserInfo clubId={club.id} />
                </div>
            </div>
        </div>
    )
}

PageClubPlanningHeader.propTypes = {
    club: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        address: PropTypes.string,
        picture: PropTypes.string,
        Member: PropTypes.object
    })
}

export default PageClubPlanningHeader