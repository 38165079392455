import React from 'react'
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next'
import { Controller } from "react-hook-form";

import ControlText from "components/form/ControlText";

const ConfigFormBoxRegistration = ({ config, control, errors }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="columns">
                <div className="column">
                    <div className="field mb-3">
                        <label className="label is-medium">
                            {t("forms:config.fields.planning_allowed_registration.label")}
                        </label>
                        <div className="field has-addons">
                            <div className="control">
                                <Controller
                                    control={control}
                                    name="planning_allowed_registration"
                                    defaultValue={config ? config.planning_allowed_registration : ""}
                                    render={({ onChange, value }) => (
                                        <ControlText
                                            onChange={onChange}
                                            type="text"
                                            value={value}
                                            error={errors.planning_allowed_registration?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className="control">
                                <div className="button is-static is-medium">
                                    {t("forms:config.global.days")}
                                </div>
                            </div>
                        </div>
                        <p className="help">
                            {t("forms:config.fields.planning_allowed_registration.help")}
                        </p>
                    </div>

                </div>
                <div className="column">
                    <div className="field mb-3">
                        <label className="label is-medium">
                            {t("forms:config.fields.cancel_registration_hours_delay.label")}
                        </label>
                        <div className="field has-addons">
                            <div className="control">
                                <Controller
                                    control={control}
                                    name="cancel_registration_hours_delay"
                                    defaultValue={config ? config.cancel_registration_hours_delay : ""}
                                    render={({ onChange, value }) => (
                                        <ControlText
                                            onChange={onChange}
                                            type="text"
                                            value={value}
                                            error={errors.cancel_registration_hours_delay?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className="control">
                                <div className="button is-static is-medium">
                                    {t("forms:config.global.hours")}
                                </div>
                            </div>
                        </div>
                        <p className="help">
                            {t("forms:config.fields.cancel_registration_hours_delay.help")}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


ConfigFormBoxRegistration.propTypes = {
    config: PropTypes.object,
    control: PropTypes.any,
    errors: PropTypes.any,
}

export default ConfigFormBoxRegistration