import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { deleteTokenStorage } from '../utils/auth'
import { SIGNOUT } from 'graphql/mutation/signout'
import Loading from 'components/loading/Loading'
import { UserContext } from 'context/UserContext';
import { getRoute } from 'utils/route';

const PageSignout = () => {

    const navigate = useNavigate()
    const { setIsConnected, isConnected } = useContext(UserContext)

    const [signoutUser] = useMutation(SIGNOUT, {
        onCompleted() {
            deleteTokenStorage();
            setIsConnected(false)
        },
        onError() {
            deleteTokenStorage();
            setIsConnected(false)
        }
    });

    useEffect(() => {
        if (isConnected === false) {
            navigate(getRoute("home"))
        }
    }, [isConnected])

    useEffect(() => {
        signoutUser()
    }, [])

    return <Loading />
}

export default PageSignout