import * as yup from "yup";

const getSchema = (t) => {
  return yup.object().shape({
    room_id: yup.string().required(t("errors:forms.session.fields.room_id.-100")),
    type_id: yup.string().required(t("errors:forms.session.fields.type_id.-100")),

    max_registrations: yup.string().required(t("errors:forms.session.fields.max_registrations.-100")),

    session_date: yup.string().required(t("errors:forms.session.fields.session_date.-100")).nullable(),
    session_start_time: yup.string().required(t("errors:forms.session.fields.session_start_time.-100")),
    session_end_time: yup.string().required(t("errors:forms.session.fields.session_end_time.-100")),
  });
};

export default getSchema;
