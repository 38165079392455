import React from 'react';

import ContentCenter from 'structure/ContentCenter'
import PasswordForgotForm from 'forms/Password/PasswordForgotForm'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const PagePasswordForgot = () => {

    const { t } = useTranslation('pages')

    return (
        <>
            <Helmet>
                <title>{t("pages:password_forgot.meta.title")}</title>
            </Helmet>

            <ContentCenter>
                <PasswordForgotForm />
            </ContentCenter>
        </>
    )
}

export default PagePasswordForgot
