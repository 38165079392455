import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import Page from 'structure/Page'

import { ME } from 'graphql/query/me'
import { GET_TIMEZONES } from 'graphql/query/timezones'
import PageProfilContent from './PageProfilContent'

const PageProfil = () => {

    const { t } = useTranslation('pages')

    var { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME, {
        fetchPolicy: "network-only"
    })
    var { loading: loadingTimezones, error: errorTimezones, data: dataTimezones } = useQuery(GET_TIMEZONES)

    return (
        <Page title={t("pages:profil.title")} metaTitle={t("pages:profil.meta.title")}>

            {(loadingMe || loadingTimezones) && <Loading />}

            {errorMe && <LoadingError error={errorMe} />}
            {errorTimezones && <LoadingError error={errorTimezones} />}

            {(dataMe && dataTimezones) &&
                <PageProfilContent
                    me={dataMe.Me}
                    timezones={dataTimezones.Timezones}
                />
            }
        </Page>
    )
}

export default PageProfil