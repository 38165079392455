import React, { useState } from "react";
import moment from "moment";

import { useQuery, useMutation } from '@apollo/client'
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PageAdmin from "structure/PageAdmin";

import Back from "components/Back";
import Table from 'components/Table';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { MEMBER_ADMIN } from 'graphql/query/member'
import { ADD_PACKAGE_TO_USER, DELETE_PACKAGE_USER } from "graphql/mutation/package-user";

import { processData } from 'utils/table'
import { getRoute } from "utils/route";
import { getPackageUserStatusClass } from "utils/package";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import MemberCoachButton from "components/MemberCoachButton";

const PageAdminMember = () => {
    const { t } = useTranslation(["admin"]);

    const { club_id, member_id } = useParams();

    const [currentPackageId, setCurrentPackageId] = useState(null);
    const [flagAddPackage, setFlagAddPackage] = useState(false)



    const [addPackageToUser] = useMutation(ADD_PACKAGE_TO_USER, {
        onCompleted() {
            refetch()
        }
    });

    const [deletePackageUser] = useMutation(DELETE_PACKAGE_USER, {
        onCompleted() {
            refetch()
        }
    });

    const handleDeletePackageUser = (id) => {
        if (confirm(t("admin:member.package.confirm_delete_package"))) {
            deletePackageUser({ variables: { id } })
        }
    }

    const handleAddPackageToUser = (packageId, userId) => {
        if (packageId > 0) {
            if (confirm(t("admin:member.package.confirm_add_package"))) {
                addPackageToUser({
                    variables: {
                        packageId,
                        userId
                    }
                })
            }
        }
    }

    const { loading, error, data, refetch } = useQuery(MEMBER_ADMIN, {
        variables: {
            memberId: member_id,
            clubId: club_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Member === null) {
        return (
            <LoadingError error={{ message: "Member not found" }} />
        )
    }

    const member = data.Member;

    return (
        <PageAdmin metaTitle="Member" activeMenu="member">
            <Back to={getRoute("admin_members", { club_id })} />

            <h2 className="is-size-2">{member.User.firstname} {member.User.lastname}</h2>
            <div className="columns">
                <div className="column">
                    <div className="block">
                        <p>{member.User.email}</p>
                        <span className="tag tag-light">{moment(member.created_at).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
                <div className="column has-text-right">
                    <div className="block">
                        <MemberCoachButton
                            memberId={member.id}
                            isCoach={member.roles.includes('"coach"')}
                        />
                    </div>
                </div>
            </div>

            <hr />

            <div className="columns">
                <div className="column">
                    {!flagAddPackage &&
                        <button onClick={() => setFlagAddPackage(true)} className="button is-info">
                            {t("admin:member.package.toggle_add_package")}
                        </button>}

                    {flagAddPackage && <div className="field has-addons">
                        <div className="control">
                            <div className="select">
                                <select onChange={(e) => setCurrentPackageId(e.currentTarget.value)}>
                                    <option>{t("admin:member.package.select_package")}</option>
                                    {data.Club.Packages.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.title}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="control">
                            <button onClick={() => handleAddPackageToUser(currentPackageId, data.Member.user_id)} type="button" className="button is-info">
                                {t("admin:member.package.add_package")}
                            </button>
                        </div>
                    </div>}
                </div>
                <div className="column has-text-right has-text-left-mobile">
                    <button
                        onClick={() => refetch()}
                        type="button"
                        className="button is-light is-small">Refresh</button>
                </div>
            </div>

            <div className="clearfix"></div>

            <Table
                columns={[
                    {
                        title: t("admin:member.package.table.columns.title"),
                        render: rowData => {
                            return (
                                <Link
                                    to={getRoute("admin_package_user", { club_id, member_id, package_user_id: rowData.id })}
                                >
                                    {rowData.title}
                                </Link>
                            )
                        }
                    },
                    {
                        title: t("admin:member.package.table.columns.dates"),
                        render: rowData => {
                            return (
                                <>
                                    {t("admin:member.package.table.columns.date_from")} {rowData.date_start} {t("admin:member.package.table.columns.date_to")} {rowData.date_end}
                                </>
                            )
                        }
                    },
                    {
                        title: t("admin:member.package.table.columns.payment_frequency"),
                        render: rowData => {
                            return (
                                <>
                                    {t("admin:member.package.table.payment_frequency." + rowData.payment_frequency)}
                                </>
                            )
                        }
                    },
                    {
                        title: t("admin:member.package.table.columns.taxed_amount"),
                        render: rowData =>
                            <>{rowData.taxed_amount}{t("admin:member.package.table.columns.taxed_amount_currency")}</>
                    },
                    {
                        title: t("admin:member.package.table.columns.status"),
                        render: rowData => {
                            return <span className={"tag " + getPackageUserStatusClass(rowData.status)}>{t("admin:member.package.table.columns.status_" + rowData.status)}</span>
                        }
                    },
                    {
                        title: t("admin:member.package.table.columns.delete"),
                        render: rowData =>
                            <button type="button" className="button is-danger is-small" onClick={() => handleDeletePackageUser(rowData.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                    }
                ]}
                data={processData(member.PackagesUser)}
            />
        </PageAdmin>
    );
};

export default PageAdminMember;
