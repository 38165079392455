import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { ThemeContext } from 'context/ThemeContext'

// Creates a portal outside the DOM hierarchy
function Portal({ children }) {
    const modalRoot = document.getElementById("root-modal");
    const element = document.createElement("div");
    const { theme } = useContext(ThemeContext)
    element.classList.add("theme--" + theme)

    useEffect(() => {
        modalRoot.appendChild(element);

        // cleanup method to remove the appended child
        return function cleanup() {
            modalRoot.removeChild(element);
        };
    }), [modalRoot, element];

    return createPortal(children, element);
}


// A modal component which will be used by other components / pages
function Modal({ children, open, isModalContent }) {
    const getModalContent = (children, isModalContent) => {
        if (isModalContent) {
            return children
        }

        return (        
            <section className="modal-card-body">
                {children}
            </section>
        )
    }

    return (
        <Portal>
            {open && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className={isModalContent ? "modal-content" : "modal-card"} onClick={event => event.stopPropagation()}>
                        {getModalContent(children, isModalContent)}
                    </div>
                </div>
            )}
        </Portal>
    );
}

Modal.defaultValues = {
    isModalContent: true
}

Modal.propTypes = {
    children: PropTypes.any,
    open: PropTypes.any,
    isModalContent: PropTypes.bool
}
export default Modal;