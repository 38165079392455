import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { getRoute } from "utils/route";

import ClubForm from "forms/Club/ClubForm";
import PageAdmin from "structure/PageAdmin";
import Back from 'components/Back';
import Loading from "components/loading/Loading";
import LoadingError from "components/loading/LoadingError";

import { GET_CLUB } from "graphql/query/club";

const PageAdminClubEdit = () => {
  const { t } = useTranslation(["admin"]);
  const { club_id } = useParams();

  const { loading, error, data } = useQuery(GET_CLUB, {
    variables: {
      id: club_id,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <LoadingError error={{ message: "Error while loading" }} />;
  }

  return (
    <PageAdmin metaTitle={t("admin:club.edit.meta.title")} activeMenu="club">
      <Back to={getRoute("admin_club_home", { club_id })} />
      <ClubForm club={data.Club} />
    </PageAdmin>
  );
};

export default PageAdminClubEdit;
