import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getRoute } from "utils/route";
import { GET_PACKAGE_USER_BY_CLUB } from "graphql/query/package-user";

const PackageUserInfo = ({ clubId }) => {

    const { loading, data, error } = useQuery(GET_PACKAGE_USER_BY_CLUB, {
        variables: {
            id: clubId
        }
    })

    if (loading || error) {
        return null;
    }

    const PackageUser = data?.Club?.Member?.PackageUserActive
    if (!PackageUser) {
        return null;
    }

    return (
        <>
            <Link to={getRoute("package_user", { package_user_id: PackageUser.id})} className="tag is-info">
                {PackageUser.title}
            </Link>
            {!PackageUser.rules.unlimited && <Link to={getRoute("package_user", { package_user_id: PackageUser.id})} className="tag is-light">Sessions: {PackageUser.sessions_counter} / {PackageUser.rules.sessions}</Link>}
        </>
    )
}

PackageUserInfo.propTypes = {
    clubId: PropTypes.number
}

export default PackageUserInfo;