import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { matchPath } from "react-router";
import { useLazyQuery } from "@apollo/client";

import { routesExclusions } from "routes";

// Structure
import Header from "structure/Header";
import Footer from "structure/Footer";

import AppRoutes from "./AppRoutes";

import { setLang } from "utils/lang";
import { setCssTheme } from "utils/theme";
import { hasAccessToken } from "utils/auth";

import { UserContext } from "context/UserContext";
import { ThemeContext } from "context/ThemeContext";

import { ME } from "graphql/query/me";

import { getDetectedTheme } from "utils/theme";

// CSS
import "bulma/css/bulma.css";
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";
import "react-datepicker/dist/react-datepicker.css";
import "styles/app.css";
import "styles/footer.css";
import "styles/helpers.css";
import "styles/loading.css";
import "styles/button-submit.css";
import "styles/form.css";
import "styles/facebook.css";
import "styles/user-profil.css";
import "styles/session.css";
import "styles/club.css";
import "styles/room.css";
import "styles/message-popin.css";
import "styles/planning.css";
import "styles/switch.css";

import "styles/mobile.css";

const memorizeLastRequiredPage = (shouldMemorize) => {
    if (shouldMemorize) {
        const url = window.location.pathname;

        const hasExclusions = routesExclusions.filter((item) =>
            matchPath(
                {
                    path: item,
                },
                url
            )
        );
        if (hasExclusions.length <= 0) {
            localStorage.setItem(
                "last-required-page",
                window.location.pathname
            );
        }
    }
};

const helmetContext = {};

const App = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [picture, setPicture] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [hasWorkspace, setHasWorkspace] = useState(false);
    const [clubCount, setClubCount] = useState(0);

    const userState = {
        isConnected,
        setIsConnected,
        picture,
        setPicture,
        firstname,
        setFirstname,
        hasWorkspace,
        setHasWorkspace,
        clubCount,
        setClubCount,
    };

    const [theme, setTheme] = useState(getDetectedTheme());
    const themeState = {
        theme,
        setTheme,
    };

    useEffect(() => {
        const currentTheme = getDetectedTheme()
        setCssTheme(currentTheme);
        setTheme(currentTheme)
    }, []);

    const [getMe] = useLazyQuery(ME, {
        context: {
            isTest: false,
        },
        onCompleted: ({ Me }) => {
            setIsConnected(true);
            setPicture(Me.picture);
            setFirstname(Me.firstname);
            setHasWorkspace(Me.has_workspace);
            setClubCount(Me.club_count);

            if (Me.lang) {
                setLang(Me.lang);
            }
            if (Me.theme) {
                const currentTheme = Me.theme == "auto" ? getDetectedTheme() : Me.theme;
                setCssTheme(currentTheme);
                setTheme(currentTheme);
            }
        },
    });
    // user is not connected, last-required-page is memorized for automatic redirection after authentication
    memorizeLastRequiredPage(!hasAccessToken());

    //
    useEffect(() => {
        if (hasAccessToken()) {
            getMe();
        }
    }, []);

    return (
        <ThemeContext.Provider value={themeState}>
            <div className={`theme--${theme}`}>
                <HelmetProvider context={helmetContext}>
                    <Router>
                        <UserContext.Provider value={userState}>
                            <Header />
                            <AppRoutes />
                            <Footer />
                        </UserContext.Provider>
                    </Router>
                </HelmetProvider>
            </div>
        </ThemeContext.Provider>
    );
};

export default App;
