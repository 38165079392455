import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import config from 'config/app'
import { ME_UPDATE_PICTURE, ME_REMOVE_PICTURE } from 'graphql/mutation/me-picture'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import FormAlert from 'components/form/FormAlert'
import { UserContext } from 'context/UserContext';

const UserPicture = (props) => {

    const [currentPicture, setCurrentPicture] = useState(props.picture)
    const [dropdownActive, setDropdownActive] = useState(false)
    const [message, setMessage] = useState('')
    const { setPicture } = useContext(UserContext)

    const fileInputRef = React.createRef()

    const { t } = useTranslation();

    // remove mutation
    const [removeUserPicture] = useMutation(ME_REMOVE_PICTURE, {
        onCompleted({ MeRemovePicture }) {
            setCurrentPicture(MeRemovePicture);
            setPicture(MeRemovePicture);
        }
    })

    // update mutation
    const [updateUserPicture] = useMutation(ME_UPDATE_PICTURE, {
        context: {
            hasUpload: true
        },
        onCompleted({ MeUpdatePicture }) {
            setCurrentPicture(MeUpdatePicture);
            setPicture(MeUpdatePicture)
        }
    });


    const handlePictureRemove = () => {
        setMessage('')
        setDropdownActive(false)
        removeUserPicture()
    }

    const handlePictureChange = (e) => {
        setMessage('')
        setDropdownActive(false)
        updateUserPicture({
            variables: {
                picture: e.target.files[0]
            }
        })
    }

    return (
        <>
            <div className="block ">
                <div className="field">
                    <figure id="user-profil-picture" className="image is-128x128">

                        <input ref={fileInputRef} className="file-input" type="file" name="resume" onChange={(e) => { handlePictureChange(e) }} />

                        <img
                            className="is-rounded user-picture"
                            src={config.api_url + '/' + currentPicture}
                        />

                        <div id="dropdown-user-profil-picture" className={'dropdown' + (dropdownActive ? ' is-active' : '')}>
                            <div className="dropdown-trigger" onClick={() => setDropdownActive(!dropdownActive)}>
                                <span className="tag is-info">
                                    <FontAwesomeIcon size="xs" icon={faPen} />
                                </span>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a className="dropdown-item" onClick={() => fileInputRef.current.click()} >
                                        {t('forms:global.picture.menu.edit')}
                                    </a>
                                    <a className="dropdown-item" onClick={() => handlePictureRemove()} >
                                        {t('forms:global.picture.menu.remove')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>

                <FormAlert>{message}</FormAlert>
            </div>
        </>

    )
}

UserPicture.propTypes = {
    picture: PropTypes.string
}

export default UserPicture