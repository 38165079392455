import { getDayOfWeekShort, getMonthOfYear } from "utils/date";

export const getSessionDate = (t, sessionStartDatetime) => {
    const startTimestamp = Date.parse(sessionStartDatetime);
    const startDatetime = new Date(startTimestamp);

    const sessionDayOfWeek = getDayOfWeekShort(t, startDatetime.getDay());
    const sessionDay = startDatetime.getDate();
    const sessionMonth = getMonthOfYear(t, startDatetime.getMonth());

    return sessionDayOfWeek + " " + sessionDay + " " + sessionMonth;
};

export const getSessionTime = (sessionStartDatetime, sessionEndDatetime) => {
    const startTimestamp = Date.parse(sessionStartDatetime);
    const startDatetime = new Date(startTimestamp);

    const endTimestamp = Date.parse(sessionEndDatetime);
    const endDatetime = new Date(endTimestamp);

    const sessionStartTime =
        String(startDatetime.getHours()).padStart(2, "0") +
        ":" +
        String(startDatetime.getMinutes()).padStart(2, "0");

    const sessionEndTime =
        String(endDatetime.getHours()).padStart(2, "0") +
        ":" +
        String(endDatetime.getMinutes()).padStart(2, "0");

    return sessionStartTime + "-" + sessionEndTime;
};
