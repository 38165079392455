import React, { useEffect, useState } from "react";
import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'
import { useTranslation } from "react-i18next";

import { MEMBER_ACCEPT, MEMBER_REFUSE } from 'graphql/mutation/member'
import Page from "structure/Page";

const STATUS_ACCEPT = 'accept';
const STATUS_REFUSE = 'refuse';

const PageAdminMemberModerate = () => {

    const { t } = useTranslation(["translation", "admin"]);

    const { member_id, status } = useParams()
    const [message, setMessage] = useState('Chargement')
    const [messageClass, setMessageClass] = useState('has-background-grey')

    const [acceptMember] = useMutation(MEMBER_ACCEPT, {
        onCompleted() {
            setMessage(t("admin:member.moderate.accept_success"))
            setMessageClass('has-background-success')
        },
        onError(error) {
            setMessageError(error)
        }
    });
    const [refuseMember] = useMutation(MEMBER_REFUSE, {
        onCompleted() {
            setMessage(t("admin:member.moderate.refuse_success"))
            setMessageClass('has-background-info')
        },
        onError(error) {
            setMessageError(error)
        }
    });
    const setMessageError = (error) => {
        const errorMessage = error.message ? (" (" + error.message + ")") : ""
        setMessage(t("admin:member.moderate.error") + errorMessage)
        setMessageClass('has-background-danger')
    }

    useEffect(() => {
        const variables = { id: member_id }
        if (status == STATUS_ACCEPT) {
            acceptMember({ variables })
        } else if (status == STATUS_REFUSE) {
            refuseMember({ variables })
        }
    }, [])

    return (
        <>
            <Page>
                <div className={"is-size-5 box " + messageClass}>
                    {message}
                </div>
            </Page>
        </>
    );
};

export default PageAdminMemberModerate;
