import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NewMemberModalFind = ({
  findEmail,
  setFindEmail,
  memberFind,
  memberFindError,
  loading,
}) => {

  const { t } = useTranslation(["admin", "translation"]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        memberFind();
        return false;
      }}
    >
      <div className="field has-addons is-horizontal">
        <div className="control">
          <input
            value={findEmail}
            className="input"
            type="text"
            placeholder={t("admin:member.modal.find.email")}
            onChange={(e) => setFindEmail(e.target.value)}
          />
        </div>
        <div className="control">
          <button
            type="submit"
            className={"button is-info " + (loading ? "is-loading" : "")}
            disabled={findEmail === ""}
          >
            {t("translation:global.search")}
          </button>
        </div>
      </div>
      {memberFindError && <p className="has-text-danger">{memberFindError}</p>}
    </form>
  );
};
NewMemberModalFind.propTypes = {
  findEmail: PropTypes.string,
  setFindEmail: PropTypes.func,
  loading: PropTypes.bool,
  memberFind: PropTypes.func,
  memberFindError: PropTypes.string,
};

export default NewMemberModalFind;
