import { useState, useLayoutEffect } from "react";

export const usePlanningBodyHeight = (planningHeaderRef, planningBodyRef) => {
  const [planningBodyHeight, setPlanningBodyHeight] = useState("auto");

  useLayoutEffect(() => {
    function updatePlanningBodyHeight() {
      if (planningHeaderRef.current && planningBodyRef.current) {
        const planningHeaderHeight = planningHeaderRef.current.offsetHeight;
        const { offsetTop } = planningBodyRef.current;
        const windowHeight = window.innerHeight;
        const finalHeight = windowHeight - offsetTop - planningHeaderHeight

        setPlanningBodyHeight(`${finalHeight}px`);
      }
    }

    window.addEventListener("resize", updatePlanningBodyHeight);
    setTimeout(() => updatePlanningBodyHeight(), 300);

    return () => window.removeEventListener("resize", updatePlanningBodyHeight);
  }, []);

  return [planningBodyHeight];
};
