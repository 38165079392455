import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { REGISTRATION_STATUS_CANCELLED } from "components/session/const";
import { REGISTRATION_PRESENT } from "graphql/mutation/register";

const SessionModalAttendee = ({ showPresentButtons, registration }) => {
    if (registration.status === REGISTRATION_STATUS_CANCELLED) {
        return null;
    }

    const [updateRegistrationPresent] = useMutation(REGISTRATION_PRESENT, {
        onCompleted({ RegistrationPresent: data }) {
            setIsLoading(false);
            setIsPresent(data.present);
        },
    });

    const { t } = useTranslation();

    const [isPresent, setIsPresent] = useState(registration.present);
    const [isLoading, setIsLoading] = useState(false);

    const togglePresent = (id, present) => {
        setIsLoading(true);
        updateRegistrationPresent({
            variables: {
                id,
                present: present === isPresent ? null : present,
            },
        });
    };

    return (
        <>
            <li className="mb-2">
                <div className="columns">
                    <div className="column">
                        <FontAwesomeIcon
                            className="mr-2"
                            icon={faUser}
                            style={{ color: "#bbb" }}
                        />
                        {registration.firstname} {registration.lastname}{" "}
                        {t(`session_modal.status.${registration.status}`)}
                    </div>
                    {showPresentButtons && (
                        <div className="column has-text-right">
                            <button
                                type="button"
                                className={
                                    "button is-small is-rounded" +
                                    (isPresent === true ? " is-primary" : "") +
                                    (isLoading === true ? " is-loading" : "")
                                }
                                onClick={() =>
                                    togglePresent(registration.id, true)
                                }
                            >
                                Present
                            </button>
                            <button
                                type="button"
                                className={
                                    "button is-small is-rounded" +
                                    (isPresent === false ? " is-warning" : "") +
                                    (isLoading === true ? " is-loading" : "")
                                }
                                onClick={() =>
                                    togglePresent(registration.id, false)
                                }
                            >
                                Absent
                            </button>
                        </div>
                    )}
                </div>
            </li>{" "}
        </>
    );
};

SessionModalAttendee.propTypes = {
    showPresentButtons: PropTypes.bool,
    registration: PropTypes.object,
};

export default SessionModalAttendee;
