import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import ConfigForm from 'forms/Config/ConfigForm'
import PageAdmin from 'structure/PageAdmin'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { GET_CONFIG } from 'graphql/query/config'

const PageAdminConfig = () => {

    const { club_id } = useParams()

    const { loading, error, data } = useQuery(GET_CONFIG, {
        fetchPolicy: "network-only",
        variables: {
            clubId: club_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Config === null) {
        return (
            <LoadingError error={{ message: "Error while loading configuration" }} />
        )
    }

    return (
        <PageAdmin title={data.Club.name} activeMenu="config" isWide={true}>
            <ConfigForm clubId={club_id} config={JSON.parse(data.Config.config)} />
        </PageAdmin>
    )
}
export default PageAdminConfig