import { gql } from "@apollo/client";

export const CREATE_SESSION = gql`
  mutation CreateSession(
    $roomId: Int!
    $typeId: Int!
    $userId: Int!
    $status: String!
    $message: String
    $maxRegistrations: Int!
    $startDatetime: DatetimeType!
    $endDatetime: DatetimeType!
  ) {
    CreateSession(
      room_id: $roomId
      type_id: $typeId
      user_id: $userId
      status: $status
      message: $message
      max_registrations: $maxRegistrations
      start_datetime: $startDatetime
      end_datetime: $endDatetime
    ) {
      id
      uid
      room_id
      type_id
      user_id
      status
      message
      max_registrations
      counter_registrations
      start_datetime
      end_datetime
    }
  }
`;

export const SAVE_SESSION = gql`
  mutation SaveSession(
    $uid: String!
    $roomId: Int!
    $typeId: Int!
    $userId: Int
    $status: String!
    $message: String!
    $maxRegistrations: Int!
    $startDatetime: DatetimeType!
    $endDatetime: DatetimeType!
  ) {
    SaveSession(
      uid: $uid
      room_id: $roomId
      type_id: $typeId
      user_id: $userId
      status: $status
      message: $message
      max_registrations: $maxRegistrations
      start_datetime: $startDatetime
      end_datetime: $endDatetime
    ) {
      message
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation DeleteSession($uid: String!) {
    DeleteSession(uid: $uid)
  }
`;
