import React from 'react'

import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import Back from 'components/Back'

import PageAdmin from 'structure/PageAdmin'
import TypeForm from 'forms/Type/TypeForm'

import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { GET_TYPE } from 'graphql/query/type'

import { getRoute } from 'utils/route'

const PageAdminTypeEdit = () => {

    const { type_id, club_id } = useParams()

    const { loading, error, data } = useQuery(GET_TYPE, {
        variables: {
            id: type_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <LoadingError error={{ message: "Error while loading" }} />
        )
    }

    return (
        <PageAdmin activeMenu="type">
            <Back to={getRoute("admin_types", { club_id })} />
            <TypeForm
                type={data.Type}
                clubId={parseInt(club_id)}
            />
        </PageAdmin>
    )
}

export default PageAdminTypeEdit