import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";

import { setSubmitButtonState } from "utils/form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormAlert from "components/form/FormAlert";
import ControlText from "components/form/ControlText";
import ControlTextarea from "components/form/ControlTextarea";
import FormSubmit from "components/form/FormSubmit";

import { catchFormErrors } from "utils/error";

import { CREATE_COMPANY, UPDATE_COMPANY } from "graphql/mutation/company"

import getSchema from "./CompanyForm.schema";

const CompanyForm = ({ company }) => {
    const { t } = useTranslation(["translation", "errors", "forms"]);

    const { club_id: clubId } = useParams()

    const [currentCompany, setCurrentCompany] = useState(company);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [message, setMessage] = useState("");

    const schema = getSchema(t);

    const catchError = (error) => {
        setIsLoading(false);
        setSubmitButtonState(setHasSubmitError);
        catchFormErrors({
            t,
            errorPath: "club",
            defaultMessagePath: "errors:messages.form_has_errors",
            error,
            setError,
            setMessage,
        });
    }

    const [createCompany] = useMutation(CREATE_COMPANY, {
        onCompleted(data) {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
            setCurrentCompany(data.CreateCompany);
        },
        onError(error) {
            catchError(error)
        },
    });

    const [updateCompany] = useMutation(UPDATE_COMPANY, {
        onCompleted() {
            setIsLoading(false);
            setSubmitButtonState(setIsSubmitted);
        },
        onError(error) {
            catchError(error)
        },
    });

    const { handleSubmit, control, errors, clearErrors, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = ({
        name,
        address,
        zip_code: zipCode,
        city,
        siret,
        legal_form: legalForm,
        capital,
        rcs,
        tva_intra: tvaIntra,
        city_registry: cityRegistry
    }) => {

        setIsLoading(true);
        setMessage("");
        clearErrors();

        if (currentCompany) {
            updateCompany({
                variables: {
                    id: currentCompany.id,
                    name,
                    address,
                    zipCode,
                    city,
                    siret,
                    legalForm,
                    capital,
                    rcs,
                    tvaIntra,
                    cityRegistry
                },
            });
        } else {
            createCompany({
                variables: {
                    clubId,
                    name,
                    address,
                    zipCode,
                    city,
                    siret,
                    legalForm,
                    capital,
                    rcs,
                    tvaIntra,
                    cityRegistry
                },
            });
        }
    };

    const onError = () => {
        setMessage(t("errors:messages.form_has_errors"));
        setSubmitButtonState(setHasSubmitError);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <div className="notification is-info">
                {t("forms:company.info")}
            </div>

            <FormAlert>{message}</FormAlert>

            <Controller
                control={control}
                name="name"
                defaultValue={currentCompany?.name ?? ""}
                render={({ onChange, value }) => (
                    <ControlText
                        onChange={onChange}
                        type="text"
                        value={value}
                        error={errors.name?.message}
                        label={t("forms:company.fields.name.label")}
                    />
                )}
            />

            <Controller
                control={control}
                name="address"
                defaultValue={currentCompany?.address ?? ""}
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        value={value}
                        error={errors.address?.message}
                        label={t("forms:company.fields.address.label")}
                    />
                )}
            />

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="zip_code"
                        defaultValue={currentCompany?.zip_code ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.zip_code?.message}
                                placeholder={t("forms:company.fields.zip_code.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="city"
                        defaultValue={currentCompany?.city ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.city?.message}
                                placeholder={t("forms:company.fields.city.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="siret"
                        defaultValue={currentCompany?.siret ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.siret?.message}
                                label={t("forms:company.fields.siret.label")}
                                placeholder={t("forms:company.fields.siret.placeholder")}
                            />
                        )}
                    />

                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="tva_intra"
                        defaultValue={currentCompany?.tva_intra ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.tva_intra?.message}
                                label={t("forms:company.fields.tva_intra.label")}
                                placeholder={t("forms:company.fields.tva_intra.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="legal_form"
                        defaultValue={currentCompany?.legal_form ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.legal_form?.message}
                                label={t("forms:company.fields.legal_form.label")}
                                placeholder={t("forms:company.fields.legal_form.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="capital"
                        defaultValue={currentCompany?.capital ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.capital?.message}
                                label={t("forms:company.fields.capital.label")}
                                placeholder={t("forms:company.fields.capital.placeholder")}
                                rightAddons={t("forms:company.fields.capital.rightAddons")}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Controller
                        control={control}
                        name="rcs"
                        defaultValue={currentCompany?.rcs ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.rcs?.message}
                                label={t("forms:company.fields.rcs.label")}
                                placeholder={t("forms:company.fields.rcs.placeholder")}
                                help={t("forms:company.fields.rcs.help")}
                            />
                        )}
                    />
                </div>
                <div className="column">
                    <Controller
                        control={control}
                        name="city_registry"
                        defaultValue={currentCompany?.city_registry ?? ""}
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                type="text"
                                value={value}
                                error={errors.city_registry?.message}
                                label={t("forms:company.fields.city_registry.label")}
                                placeholder={t("forms:company.fields.city_registry.placeholder")}
                            />
                        )}
                    />
                </div>
            </div>

            <FormSubmit
                isLoading={isLoading}
                isSubmitted={isSubmitted}
                hasSubmitError={hasSubmitError}
            >
                {t("forms:global.btn_save")}
            </FormSubmit>

        </form>
    )
}

CompanyForm.propTypes = {
    company: PropTypes.object
}

export default CompanyForm