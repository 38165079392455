import React from 'react'
import { useQuery } from '@apollo/client'
import { Navigate } from 'react-router';
import Page from 'structure/Page';
import ClubCard from 'components/club/ClubCard'
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import { GET_CLUBS_USER } from 'graphql/query/club'
import { useTranslation } from 'react-i18next'
import { getRoute } from 'utils/route';

const PageClubs = () => {

    const { t } = useTranslation(['pages'])
    const { loading, error, data } = useQuery(GET_CLUBS_USER)

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <LoadingError error={error} />
        )
    }

    // only one club, redirect to club
    if (data.ClubsUser.length == 1) {
        return <Navigate to={getRoute('club', { slug: data.ClubsUser[0].slug})} />
    }
    
    return (
        <Page metaTitle={t("pages:clubs.meta.title")} title={t("pages:clubs.meta.title")}>
            {data.ClubsUser.map((club, index) => (
                <ClubCard key={index} club={club} />
            ))}
            <div className="is-clearfix"></div>
        </Page >
    )
}

export default PageClubs;