import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { useTranslation } from "react-i18next";

import { getRoute } from 'utils/route'

import PageAdmin from 'structure/PageAdmin'

import Back from 'components/Back';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'
import PackageForm from 'forms/Package/PackageForm'

import { GET_PACKAGE_ADMIN } from 'graphql/query/package'

import { intOrNull } from 'utils/str'

const PageAdminPackageEdit = () => {

    const { t } = useTranslation(["admin"]);
    const { club_id, package_id } = useParams()

    const { loading, error, data } = useQuery(GET_PACKAGE_ADMIN, {
        fetchPolicy: "network-only",
        variables: {
            id: package_id
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Package === null) {
        return (
            <LoadingError error={{ message: "Package not found" }} />
        )
    }
    return (
        <PageAdmin metaTitle={t("admin:package.meta.title")} activeMenu="package">
            <Back to={getRoute("admin_packages", { club_id })} />

            <PackageForm
                packageData={data.Package}
                clubData={data.ClubsOwned}
                currentClubId={intOrNull(club_id)}
            />
        </PageAdmin>
    )
}

export default PageAdminPackageEdit