import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types'

const PlanningNav = ({ gotoPrevious, gotoNext }) => (
  <>
    {gotoPrevious && <div className="planning-goto-previous has-text-primary"
      onClick={() => gotoPrevious()}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>}

    {gotoNext && <div className="planning-goto-next has-text-primary"
      onClick={() => gotoNext()}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>}
  </>
);

PlanningNav.propTypes = {
    gotoPrevious: PropTypes.func,
    gotoNext: PropTypes.func,
}
export default PlanningNav;
