import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client'
import { ME_DISABLE_EMAIL_REMINDER } from 'graphql/mutation/me';

import Page from 'structure/Page'


const PageProfilNotificationsEmailReminder = () => {
    const { status } = useParams()
    const { t } = useTranslation(['pages']);
    const [pageBody, setPageBody] = useState(t('pages:notifications_email_reminder.body.loading'))

    const [disableEmailReminder] = useMutation(ME_DISABLE_EMAIL_REMINDER, {
        onCompleted() {
            if (status === 'disable') {
                setPageBody(t('pages:notifications_email_reminder.body.success.disabled'))
            } else {
                setPageBody(t('pages:notifications_email_reminder.body.success.enabled'))
            }
        }, onError() {
            setPageBody(t('pages:notifications_email_reminder.body.failed'))
        }
    })

    useEffect(() => {
        const disable = (status === 'disable');
        disableEmailReminder({
            variables: {
                disable
            }
        })
    }, []);

    return (
        <Page
            title={t("pages:notifications_email_reminder.title")}
            metaTitle={t("pages:notifications_email_reminder.meta.title")}
        >
            <article className="message is-info">
                <div className="message-body">
                    <h4 className="is-size-4">{pageBody}</h4>
                </div>
            </article>
        </Page>
    )
}

export default PageProfilNotificationsEmailReminder;