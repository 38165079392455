import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_CLUBS } from 'graphql/query/club'

import SearchClubResultItem from './SearchClubResultItem'

const SearchClubsResults = ({ query, timestamp }) => {

    if (!query || query == "") {
        return null;
    }
    
    const { t } = useTranslation(["components"])

    const [results, setResults] = useState(null)

    const [searchClubs] = useLazyQuery(SEARCH_CLUBS, {
        fetchPolicy: "no-cache",
        onCompleted({ SearchClubs }) {
            setResults(SearchClubs)
        }
    });

    useEffect(() => {
        searchClubs({ variables: { q: query } })
    }, [query, timestamp])

    if (!results) {
        return null;
    }

    if (results.length <= 0) {
        return <div className="notification is-info is-light has-text-centered mt-4">{t("search_club.no_results")}</div>
    }

    return (
        <section className="mt-5">
            {results.map((club, key) => <SearchClubResultItem key={key} club={club} />)}
        </section>
    )
}
SearchClubsResults.propTypes = {
    query: PropTypes.string,
    timestamp: PropTypes.any
}


export default SearchClubsResults;