import React from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'


import { Link } from 'react-router-dom'

import PageAdmin from 'structure/PageAdmin'

import Table from 'components/Table';
import Loading from 'components/loading/Loading'
import LoadingError from 'components/loading/LoadingError'

import { getRoute } from 'utils/route'
import { processData } from 'utils/table'

import { GET_TYPES } from 'graphql/query/type'
import { DELETE_TYPE } from 'graphql/mutation/type'

const PageAdminTypes = () => {

    const { club_id } = useParams()

    const { t } = useTranslation(['admin'])

    const [deleteType] = useMutation(DELETE_TYPE)

    const { loading, error, data } = useQuery(GET_TYPES, {
        variables: {
            clubId: parseInt(club_id)
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error || data.Types === null) {
        return (
            <LoadingError error={{ message: "Club not found" }} />
        )
    }
    return (
        <PageAdmin title={data.Club.name} activeMenu="type" isWide={true}>
            <Link to={getRoute("admin_type_create", { club_id })} className={'button is-primary mb-3'}>
                {t("admin:type.btn_create")}
            </Link>

            <Table
                options={{
                    actionsColumnIndex: -1
                }}

                actions={[
                    rowData => ({
                        icon: 'delete',
                        onClick: () => {
                            if (confirm(t("admin:type.confirm_delete"))) {
                                deleteType({
                                    variables: {
                                        id: rowData.id
                                    },
                                    update(cache) {
                                        const id = cache.identify({ id: rowData.id, __typename: 'TypeType' });
                                        cache.evict({ id })
                                        cache.gc()
                                    }
                                })
                            }
                        }
                    })
                ]}
                columns={[
                    {
                        title: t("admin:type.table.columns.name"), field: "name", render: rowData =>
                            <Link to={getRoute("admin_type_edit", { club_id, type_id: rowData.id })}>{rowData.name}</Link>
                    },
                    {
                        title: t("admin:type.table.columns.color"), field: "color", render: rowData =>
                            <span className="tag" style={{ backgroundColor: rowData.color, width: "50px" }}></span>
                    },
                    {
                        title: t("admin:type.table.columns.position"), field: "position"
                    }
                ]}
                data={processData(data.Types)}
            />
        </PageAdmin>
    )
}

export default PageAdminTypes