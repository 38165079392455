import React from 'react'
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next'
import { Controller } from "react-hook-form";
import ControlText from "components/form/ControlText";
import Switch from "components/form/Switch";

const ConfigFormBoxPlanningForm = ({ config, control, errors }) => {
    const { t } = useTranslation();

    return (
        <div className="columns">
            <div className="column">
                <div className="field mb-6">
                    <label className="label is-medium">
                        {t("forms:config.fields.default_max_registrations.label")}
                    </label>
                    <div className="field has-addons">
                        <div className="control">
                            <Controller
                                control={control}
                                name="default_max_registrations"
                                defaultValue={config ? config.default_max_registrations : ""}
                                render={({ onChange, value }) => (
                                    <ControlText
                                        onChange={onChange}
                                        type="text"
                                        value={value}
                                        error={errors.default_max_registrations?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className="control">
                            <div className="button is-static is-medium">
                                {t("forms:config.global.attendees")}
                            </div>
                        </div>
                    </div>
                    <p className="help">
                        {t("forms:config.fields.default_max_registrations.help")}
                    </p>
                </div>
            </div>

            <div className="column">
                <div className="field">
                <label className="label is-medium">{t("forms:config.fields.show_coach.label")}</label>
                    <div className="control">
                        <Controller
                            control={control}
                            name="show_coach"
                            defaultValue={config?.show_coach ?? true}
                            render={({ onChange, value }) => (
                                <Switch
                                    onChange={onChange}
                                    name="show_coach"
                                    size="medium"
                                    checked={value}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

ConfigFormBoxPlanningForm.propTypes = {
    config: PropTypes.object,
    control: PropTypes.any,
    errors: PropTypes.any,
}

export default ConfigFormBoxPlanningForm